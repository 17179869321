import styled from "styled-components";

export const ProductCtn = styled.div`
  width: 100%;
`;

export const ProductListWrapper = styled.div`
  width: 55%;
  margin: 0px auto;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 1024px) {
    width: 90%;
    overflow-x: auto;
    display: flex;
    gap: 16px;
    padding: 16px;
    margin-bottom: 0px;
    scrollbar-width: none;
  }
`;
export const ProductListIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  max-width: 68px;
`;
export const ProductListIcon = styled.img`
  height: 68px;
  width: 68px;
  margin-top: 30px;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 1024px) {
    width: 48px;
    height: 48px;
    margin-top: 0px;
  }
`;
export const ProductListTitle = styled.div`
  font-family: Mulish;
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 17px;
  text-align: center;
  color: ${(props) => (props.isActive ? "#333333" : "#999999")};

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    font-weight: 800;
    line-height: 14px;
    letter-spacing: 0.02em;
  }
`;
export const ProductTopCtn = styled.div`
  position: relative;
  background: url(${(props) => (props.bgImg ? props.bgImg : "")});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #ffffff;
  height: 450px;
  min-height: 450px;
  max-height: 450px;
  transition: background 2.5s ease;

  @media screen and (max-width: 1024px) {
    height: 400px;
    min-height: 400px;
    max-height: 400px;
  }
`;
export const ProductDescriptionCtn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const ProductDescriptionWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 10%;
  width: 44%;
  align-items: baseline;

  @media screen and (max-width: 1024px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    left: 0px;
    padding: 0px 20px;
    top: 20px;
    gap: 16px;
  }
`;
export const ProductDescriptionHeader = styled.div`
  font-family: Mulish;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: left;
  color: ${props => props.textColor ? props.textColor : '#000000'};

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
`;
export const ProductDescriptionContent = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: ${props => props.textColor ? props.textColor : '#000000'};

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
`;
export const ProductDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const ProductDescriptionButton = styled.button`
  color: #333333;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  border-radius: 100px;
  background: #FAF000;
  border: none;
  padding: ${props => props.padding || "12px 32px"};
  cursor: pointer;

  &:hover {
      background-color: #ece30c;
  }

  @media screen and (max-width: 1024px) {
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 32px;
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    text-align: left;
    height: 40px;
  }
`
export const ProductDescriptionBtn = styled.button`
  color: #333333;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border-radius: 50%;
  background: #FAF000;
  border: none;
  padding: ${props => props.padding || "12px 32px"};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
      background-color: #ece30c;
  }

  @media screen and (max-width: 1024px) {
    width: 40px;
    height: 40px;
  }
`
export const HeaderImage = styled.img`
  height: 24px;
  width: '';

  @media screen and (max-width: 1024px) {
    height: 16px;
  }
`
export const FormBtmMob = styled.div`
  padding: 12px 24px 12px 24px;
  gap: 8px;
  border-radius: 32px;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  background: ${props => props.bgColor ? props.bgColor : '#ffffff'};
  width: 80%;
  color: #ffffff;
  margin: auto;
`