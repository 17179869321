import styled from "styled-components";

export const TravelFormsWarpper = styled.div`
  @media screen and (min-width: 1024px) {
    position: relative;
    background: url("https://storage.googleapis.com/pp_img/tap-travel-website/common/Bottom-Vector.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #f5f5f5;
    background-position: bottom;
  }

  background: #f5f5f5;
`;
export const TravelFormCtn = styled.div`
  width: 100%;
`;
export const TravelInsuredDetailsCtn = styled.div`
  width: 65%;
  display: grid;
  grid-template-columns: 65% 32%;
  gap: 3%;
  margin: 40px auto;

  @media screen and (max-width: 1024px) {
    width: 92%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 16px auto;
  }
`;
export const HolderFormWrapper = styled.div`
  padding: 48px;
  border-radius: 32px;
  background: #ffffff;
  margin: 0px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1024px) {
    padding: 10px 0px 0px 0px;
    gap: 8px;
    background: none;
  }
`;
export const HolderTitle = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #b43cfa;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const HolderTitle1 = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #b43cfa;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const InsuredFormContainer = styled.div`
  display: block;

  @media screen and (max-width: 1024px) {
    display: ${props => props.isShow ? 'block' : 'none'}
  }
`
export const Title = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #333333;
  margin: 16px 0px 0px 0px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
export const FormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
export const InsuredFormWrapper = styled.div`
  padding: 48px;
  border-radius: 32px;
  background: #ffffff;
  margin: 0px 0px 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1024px) {
    padding: 10px 0px 0px 0px;
    gap: 16px;
    background: none;
    min-height: 70vh;
  }
`;
export const BenefitDetailWrapper = styled.div`
  padding: 48px;
  border-radius: 32px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;

  @media screen and (max-width: 1024px) {
    padding: 0px;
    gap: 16px;
  }
`;
export const BenefitDetailsCtn = styled.div`
  display: grid;
  grid-template-columns: 25% 73%;
  gap: 2%;
  word-wrap: break-word;
`;
export const FlexColumnCtn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Benefitdescription = styled.div`
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #333333;
`;
export const BenefiitTitle = styled.div`
  font-family: Mulish;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #999999;
`;
export const BenefitDetailSubmitBtn = styled.div`
  font-family: Mulish;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  border: 2px solid #b43cfa;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #b43cfa;
  margin-top: 10px;
  cursor: pointer;
  transition: background 0.5s ease-out;

  &:hover {
    background: #b43cfa;
    color: #ffffff;
  }
  @media screen and (max-width: 1024px) {
    padding: 8px 16px 8px 16px;
  }
`;
export const HeaderText = styled.p`
  font-size: 18px;
  font-family: Mulish;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  margin-top: 32px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
    padding: 0px 40px;
    margin-top: 16px;
  }
`;
export const SecondHeaderText = styled.p`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    line-height: 14px;
    padding: 0px 40px;
  }
`;
export const BounceLoaderCtn = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const PackageDetailsDropdownWrapper = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }
`
export const GridBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
    margin-bottom: 16px;
  }
`
export const FormHeader = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }
`
export const GroupInsuredFormCtn = styled.div`
  display: block;

  @media screen and (max-width: 1024px) {
    display: ${props => props.isShow ? 'block' : 'none'}
  }
`
export const FooterRightCtn = styled.div`
  width: 34%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`