import React, { useEffect, useState } from "react";
import { getWindowDimensions } from "../../constants/travel";
import TravelForms from './travelform'
import TravelFormsMobile from './travelformMobile'

const TravelForm = () => {
  const [isMobile, setIsMobileView] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobileView(window.innerWidth <= 1024);
    });
  },[isMobile])

  return (
    isMobile ? <TravelFormsMobile /> : <TravelForms />
  )
}

export default TravelForm