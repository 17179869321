import React from "react";
import {
  BlueText,
  FooterCtn,
  FooterDescription,
  FooterWrapper,
  GreyText,
  SubmitBtn,
} from "./elements";

const TravelFooter = ({
  onSubmit = () => {},
  btnText = "Selanjutnya",
  premium = "",
  disabled = false,
  leftContent = () => {},
  rightContent = () => {},
  showPremium = true,
  loading = false,
  leftText = "Premi Dasar",
  showSubmitBtn = true,
}) => {
  return (
    <FooterCtn>
      <FooterWrapper>
        {showPremium ? (
          <FooterDescription>
            <GreyText color="#333333">{leftText} :&nbsp;</GreyText>
            <BlueText textSize="16px" color="#B43CFA">
              {premium} &nbsp;
            </BlueText>
          </FooterDescription>
        ) : (
          leftContent()
        )}
        {showSubmitBtn ? (
          <SubmitBtn
            id="submit-btn"
            loading={loading}
            disabled={disabled}
            onClick={() => onSubmit()}
            type="secondary"
          >
            {btnText}
          </SubmitBtn>
        ) : (
          rightContent()
        )}
      </FooterWrapper>
    </FooterCtn>
  );
};

export default TravelFooter;
