import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import {
  OtpActionSectionContainer,
  OtpActionSectionInputContainer,
  OtpActionSectionButtonContainer,
  OtpActionSectionResend,
  OtpActionDescription,
  ConfirmOTPText,
  DesciptionText,
  PhoneNumberText,
  OtpActionSectionButtonContainer2,
  SubmitBtnOTP,
  OtpImage,
} from "./elements";
import { useCountdown, useSendOTP, useVerifyOTP } from "../../lib/hooks";
import OTPInput from "../OTPInput";
import { ImageUrl } from "../../Images";
import LargeButton from "../LargeButton";

const OtpActionSection = ({
  otpInfo,
  onGenerateOpId,
  onVerifyOTPSuccess,
  onResetOtpForm,
}) => {
  const [otpValue, setOTPValue] = useState('');
  const [valueDict, setValueDict] = useState({});
  const [isError, setIsError] = useState(false);
  const [operationId, setOperationId] = useState('')
  const {
    isLoading: isVerifyLoading,
    isInvalid: isVerifyInvalid,
    handleVerify,
  } = useVerifyOTP({
    otpInfo,
    onSuccess: onVerifyOTPSuccess,
    onFailure: (e) => {
      console.log(e);
      setIsError(true);
    },
  });
  const { isLoading: isSendLoading, handleSend } = useSendOTP({
    onSuccess: ({operationId}) => {
        setOperationId(operationId)
    },
    onFailure: (e) => console.error(e),
  });
  const [countDown, resetCountdown] = useCountdown();

  useEffect(() => {
    handleSend(otpInfo.phoneNo);
  }, []);

  const resetInputField = () => {
    setValueDict({});
    setIsError(false);
    setOTPValue("");
  };

  const handleResendOTP = () => {
    if (countDown > 0) return;
    handleSend(otpInfo.phoneNo);
    resetCountdown();
    resetInputField();
  };

  const handleOTPSubmit = () => {
    if (otpValue && otpValue.length === 6) {
      handleVerify({
        otp: otpValue,
        operationId: operationId,
      });
    }
  };

  const handleResetForm = () => {
    onResetOtpForm();
  };

  return (
    <OtpActionSectionContainer>
      <OtpActionDescription>
        <OtpImage src={ImageUrl.BlueMessage}/>
        <ConfirmOTPText>Konfirmasi OTP</ConfirmOTPText>
        <DesciptionText>
          Masukkan nomor OTP yang terkirim ke nomor ponsel
        </DesciptionText>
        <PhoneNumberText>+{otpInfo.phoneNo}</PhoneNumberText>
      </OtpActionDescription>
      <OtpActionSectionInputContainer>
        <OTPInput
          id="otp-form"
          valueDict={valueDict}
          setValueDict={setValueDict}
          onSubmit={handleOTPSubmit}
          isError={isError}
          setIsError={setIsError}
          setOTPValue={setOTPValue}
        />
      </OtpActionSectionInputContainer>
      <OtpActionSectionResend onClick={handleResendOTP}>
        {countDown > 0 ? (
          <div>{`Kirim ulang OTP dalam ${countDown}s`}</div>
        ) : (
            <OtpActionSectionButtonContainer2>
            <LargeButton
                id="send-otp-btn"
                loading={isVerifyLoading || isSendLoading}
                disabled={countDown > 0}
                onClick={handleResendOTP}
                type="blue"
            >
                Kirim ulang OTP
            </LargeButton>
          </OtpActionSectionButtonContainer2>
        )}
      </OtpActionSectionResend>
      <OtpActionSectionButtonContainer>
        <SubmitBtnOTP
          id="send-otp-btn"
          loading={isVerifyLoading || isSendLoading}
          disabled={otpValue.length < 6}
          onClick={handleOTPSubmit}
          type="secondary"
        >
          Kirim
        </SubmitBtnOTP>
      </OtpActionSectionButtonContainer>
    </OtpActionSectionContainer>
  );
};

OtpActionSection.defaultProps = {
  onGenerateOpId: () => {},
  onVerifyOTPSuccess: () => {},
  onResetOtpForm: () => {},
  otpInfo: {},
};

OtpActionSection.propTypes = {
  onGenerateOpId: PropTypes.func,
  onVerifyOTPSuccess: PropTypes.func,
  onResetOtpForm: PropTypes.func,
  otpInfo: PropTypes.shape({
    phoneNo: PropTypes.string,
    operationId: PropTypes.string,
  }),
};

export default OtpActionSection;
