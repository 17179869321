import React from "react";
import { PackageDetailsDropdownWrapper } from "../elements";
import Collapsible from "../../../components/Collapsible";
import BenefitDetails from "./BenefitDetails";

const style = {
    borderRadius: '0px',
    fontSize: '12px',
    childPadding: '0px 16px 16px 16px',
    haveBorder: true,
    dropdownIcon: true
  }

const PackageDetailsDropdown = ({data = {}, packageListData = {}}) => {

    return <PackageDetailsDropdownWrapper>
        <Collapsible id='package-detail' key='package-detail' title='Ringkasan Asuransi' containerStyle={style}>
            <BenefitDetails draftData={data} pkgListData={packageListData} />
        </Collapsible>
    </PackageDetailsDropdownWrapper>
}

export default PackageDetailsDropdown