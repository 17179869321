import styled from "styled-components";

export const CriteriaWrapper = styled.div`
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media screen and (max-width: 1024px) {
        gap: 12px;
    }
`
export const FormItemWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 32% 32% 33%;
    gap: 1%;
    margin-bottom: 35px;

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        gap: 8px;
    }
`
export const FormItemWrapper2 = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 32% 32% 33%;
    gap: 1%;
    margin-bottom: 35px;

    @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        gap: 8px;
    }
`
export const FormItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
export const Title = styled.div`
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    height: 34px;
    display: flex;
    align-items: center;
`
export const OptionsWrapper = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    @media screen and (max-width: 1024px) {
        margin-top: 12px;
    }
`
export const OptionsListWrapper = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media screen and (max-width: 1024px) {
        margin-top: 12px;
    }
`
export const OptionsListWrapperperson = styled.div`
    margin-top: 20px;
    width: 100%;
    @media screen and (max-width: 1024px) {
        margin-top: 12px;
    }
`
export const PersonInfo = styled.div`
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`
export const Bluetext = styled.span`
    // color: #0078FA;
`
export const SubmitBtn = styled.div`
    padding: 14px 16px;
    border-radius: 16px;
    background: ${props => props.bgColor ? props.bgColor : '#B43CFA'};
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${props => props.fontColor ? props.fontColor : '#FFFFFF'};
    border: 2px solid ${props => props.bgColor ? props.bgColor : '#B43CFA'};
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        width: 90%;
    }
`
export const FooterCtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 92%;
    margin: 16px auto;
    position: fixed;
    bottom: 0px;
  }
`;
export const ProductTypeTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const TripInformationTitle = styled.div`
  font-weight: 800;
`
export const TravelTripDetailsPara = styled.div``
export const TripInformationCtn = styled.div`
  display: grid;
  gap: 8px;
  border-radius: 8px;
  opacity: 0px;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  margin: 14px;  
  font-weight: 600;
`
export const TooltipCtn = styled.div`
    @media screen and (max-width: 1024px) {
        display: none;
    }
`