import styled from "styled-components";


export const ScrollButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 28px;
  height: 124px;
  background-color: #333333;
  color: white;
  border: none;
  border-radius: 16px;
  font-size: 24px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  cursor: pointer;
  transition: opacity 0.4s, visibility 0.4s;
  z-index: 1000;

  &:hover {
    background-color: #333;
  }
`;