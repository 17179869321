import React, { useState } from "react";
import {
  UserDetailsWrapper,
  UserDetailCtn,
  DetailsWrapper,
  DetailsCtn,
  DetailsDescription,
  GreyText,
  Description,
  EditBtn,
  UserDetailsctn,
  EditPackageDetailsCtn,
  EditBtnMobile,
  SubmitBtn,
} from "../elements";
import { ImageUrl } from "../../../Images";
import Modal from "../../../components/Modal";
import EditPackageDetails from "./EditForm";
import { format } from "date-fns";
import {
  GetProtectionType,
  GetTripType,
} from "../../../lib/travel/benefitDetailsCardData";
import Collapsible from "../../../components/CollapsibleV2";
import { getWindowDimensions } from "../../../constants/travel";

const style = {
  borderRadius: "0px",
  fontSize: "12px",
  childPadding: "0px 16px 16px 16px",
  haveBorder: true,
  dropdownIcon: true,
};

const UserDetails = ({ data = {} }) => {
  const [showModal, setShowModal] = useState(false);

  const _renderIamge = (detail) => {
    return (
      <img src={detail.iconUrl} width="40px" height="40px" alt={detail.title} />
    );
  };

  const getDestinations = (destination) => {
    const countryList = destination.split(",");
    if (countryList.length <= 3) {
      return countryList.toString();
    } else {
      const ncountry = countryList.length - 3;
      const firstCountry = countryList[0];
      const secondCountry = countryList[1];
      const thirdCountry = countryList[2];
      return `${firstCountry}, ${secondCountry}, ${thirdCountry}${
        ncountry ? `, +${ncountry}` : ""
      }`;
    }
  };

  const getTitle = () => {
    return (
      <UserDetailCtn>
        <DetailsWrapper>
          <DetailsCtn>
            {_renderIamge(data.trip_start_from)}
            <DetailsDescription>
              <GreyText>{data.trip_start_from.title}</GreyText>
              <Description>{data.trip_start_from.description}</Description>
            </DetailsDescription>
          </DetailsCtn>
          <DetailsCtn>
            {_renderIamge(data.destinations)}
            <DetailsDescription>
              <GreyText>{data.destinations.title}</GreyText>
              <Description>
                {getDestinations(data.destinations.description)}
              </Description>
            </DetailsDescription>
          </DetailsCtn>
          <DetailsCtn>
            {_renderIamge(data.trip_start_date)}
            <DetailsDescription>
              <GreyText>{data.trip_start_date.title}</GreyText>
              <Description>
                {format(data.trip_start_date.description, "dd/MM/yyyy")}
              </Description>
            </DetailsDescription>
          </DetailsCtn>
          <DetailsCtn>
            {_renderIamge(data.trip_end_date)}
            <DetailsDescription>
              <GreyText>{data.trip_end_date.title}</GreyText>
              <Description>
                {format(data.trip_end_date.description, "dd/MM/yyyy")}
              </Description>
            </DetailsDescription>
          </DetailsCtn>
        </DetailsWrapper>
      </UserDetailCtn>
    );
  };

  return getWindowDimensions().width < 1024 ? (
    <>
      <Collapsible
        id="package-detail"
        key="package-detail"
        title={getTitle()}
        containerStyle={style}
      >
        <UserDetailCtn>
          <DetailsWrapper>
            <DetailsCtn>
              {_renderIamge(data.trip_type)}
              <DetailsDescription>
                <GreyText>{data.trip_type.title}</GreyText>
                <Description>
                  {GetTripType[data.trip_type.description] || "-"}
                </Description>
              </DetailsDescription>
            </DetailsCtn>
            <DetailsCtn>
              {_renderIamge(data.product_type)}
              <DetailsDescription>
                <GreyText>{data.product_type.title}</GreyText>
                <Description>
                  {GetProtectionType[data.product_type.description] || "-"}
                </Description>
              </DetailsDescription>
            </DetailsCtn>
            <DetailsCtn>
              {_renderIamge(data.no_of_insured_persons)}
              <DetailsDescription>
                <GreyText>{data.no_of_insured_persons.title}</GreyText>
                <Description>
                  {data.no_of_insured_persons.description} Orang
                </Description>
              </DetailsDescription>
            </DetailsCtn>
            <DetailsCtn>
              {_renderIamge(data.is_schengen)}
              <DetailsDescription>
                <GreyText>{data.is_schengen.title}</GreyText>
                <Description>
                  {data.is_schengen.description === "true" ? "Ya" : "Tidak"}
                </Description>
              </DetailsDescription>
            </DetailsCtn>
          </DetailsWrapper>
          <EditBtnMobile>
            <SubmitBtn
              id="send-otp-btn"
              onClick={() => setShowModal(true)}
              type="blue"
            >
              Ubah Pencarian
            </SubmitBtn>
          </EditBtnMobile>
        </UserDetailCtn>
      </Collapsible>
      <Modal
        title={"Detail Perjalanan"}
        show={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="75%"
      >
        <EditPackageDetailsCtn>
          <EditPackageDetails setShowModal={setShowModal} data={data} />
        </EditPackageDetailsCtn>
      </Modal>
    </>
  ) : (
    <UserDetailsctn>
      <UserDetailsWrapper>
        <UserDetailCtn>
          <DetailsWrapper>
            <DetailsCtn>
              {_renderIamge(data.trip_type)}
              <DetailsDescription>
                <GreyText>{data.trip_type.title}</GreyText>
                <Description>
                  {GetTripType[data.trip_type.description] || "-"}
                </Description>
              </DetailsDescription>
            </DetailsCtn>
            <DetailsCtn>
              {_renderIamge(data.product_type)}
              <DetailsDescription>
                <GreyText>{data.product_type.title}</GreyText>
                <Description>
                  {GetProtectionType[data.product_type.description] || "-"}
                </Description>
              </DetailsDescription>
            </DetailsCtn>
            <DetailsCtn>
              {_renderIamge(data.trip_start_from)}
              <DetailsDescription>
                <GreyText>{data.trip_start_from.title}</GreyText>
                <Description>{data.trip_start_from.description}</Description>
              </DetailsDescription>
            </DetailsCtn>
            <DetailsCtn>
              {_renderIamge(data.destinations)}
              <DetailsDescription>
                <GreyText>{data.destinations.title}</GreyText>
                <Description>
                  {getDestinations(data.destinations.description)}
                </Description>
              </DetailsDescription>
            </DetailsCtn>
            <DetailsCtn>
              {_renderIamge(data.trip_start_date)}
              <DetailsDescription>
                <GreyText>{data.trip_start_date.title}</GreyText>
                <Description>
                  {format(data.trip_start_date.description, "dd/MM/yyyy")}
                </Description>
              </DetailsDescription>
            </DetailsCtn>
            <DetailsCtn>
              {_renderIamge(data.trip_end_date)}
              <DetailsDescription>
                <GreyText>{data.trip_end_date.title}</GreyText>
                <Description>
                  {format(data.trip_end_date.description, "dd/MM/yyyy")}
                </Description>
              </DetailsDescription>
            </DetailsCtn>
            <DetailsCtn>
              {_renderIamge(data.no_of_insured_persons)}
              <DetailsDescription>
                <GreyText>{data.no_of_insured_persons.title}</GreyText>
                <Description>
                  {data.no_of_insured_persons.description} Orang
                </Description>
              </DetailsDescription>
            </DetailsCtn>
            <DetailsCtn>
              {_renderIamge(data.is_schengen)}
              <DetailsDescription>
                <GreyText>{data.is_schengen.title}</GreyText>
                <Description>
                  {data.is_schengen.description === "true" ? "Ya" : "Tidak"}
                </Description>
              </DetailsDescription>
            </DetailsCtn>
          </DetailsWrapper>
          <EditBtn onClick={() => setShowModal(true)}>
            <img
              src={ImageUrl.EditBlue}
              width="16px"
              height="16px"
              alt="edit-icon"
            />
          </EditBtn>
        </UserDetailCtn>
      </UserDetailsWrapper>
      <Modal
        title={"Detail Perjalanan"}
        show={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="75%"
      >
        <EditPackageDetailsCtn>
          <EditPackageDetails setShowModal={setShowModal} data={data} />
        </EditPackageDetailsCtn>
      </Modal>
    </UserDetailsctn>
  );
};

export default UserDetails;
