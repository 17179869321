const GCP_URL = 'https://storage.googleapis.com/pp_img/tap-travel-website'

export const ImageUrl = {
    
  // Tap-Travel Sales Icons
  Tag: `${GCP_URL}/common/tag.png`,
  Rocket: `${GCP_URL}/common/rocket.png`,
  Location: `${GCP_URL}/common/location.png`,
  Date: `${GCP_URL}/common/date.png`,
  File: `${GCP_URL}/common/file.png`,
  Profile: `${GCP_URL}/common/profile.png`,
  Calendar: `${GCP_URL}/common/calendar.png`,
  Calendar2: `${GCP_URL}/common/calendar2.png`,
  Error: `${GCP_URL}/common/form-item-error-icon.svg`,
  SendArrow: `${GCP_URL}/common/send-icon.svg`,
  UpDownArorw: `${GCP_URL}/common/Arrow-Selection.png`,
  Search: `${GCP_URL}/common/Search.png`,
  Edit: `${GCP_URL}/common/edit-icon.png`,
  TAP: `${GCP_URL}/common/TAP-logo.png`,
  BlueCheck: `${GCP_URL}/common/blue-check.png`,
  EditBlue: `${GCP_URL}/common/edit-blue.png`,
  EditBlack: `${GCP_URL}/common/edit2-icon.png`,
  Calendar3: `${GCP_URL}/common/Calendar.png`,
  Auto_Inactive: `${GCP_URL}/common/auto-inactive.png`,
  Travel_Inactive: `${GCP_URL}/common/travel-inactive.png`,
  Safe_Inactive: `${GCP_URL}/common/safe-inactive.png`,
  Device_Inactive: `${GCP_URL}/common/device-inactive.png`,
  Property_Inactive: `${GCP_URL}/common/property-inactive.png`,
  Warranty_Inactive: `${GCP_URL}/common/warranty-inactive.png`,
  Other_Inactive: `${GCP_URL}/common/other-inactive.png`,
  Auto_active: `${GCP_URL}/common/auto-active.png`,
  Travel_active: `${GCP_URL}/common/travel-active.png`,
  Safe_active: `${GCP_URL}/common/safe-active.png`,
  Device_active: `${GCP_URL}/common/device-active.png`,
  Property_active: `${GCP_URL}/common/property-active.png`,
  Warranty_active: `${GCP_URL}/common/warranty-active.png`,
  Other_active: `${GCP_URL}/common/other-active.png`,
  Check: `${GCP_URL}/common/check-line.png`,
  ArrowRedUp: `${GCP_URL}/common/arrowUp.png`,
  BlueMessage: `${GCP_URL}/common/message-blue.svg`,
  BlueCheck2: `${GCP_URL}/common/blue-check-line.svg`,
  BlueInfo: `${GCP_URL}/common/blue-info.svg`,
  TravelIcon: `${GCP_URL}/common/travel-icon.svg`,
  AutoIcon: `${GCP_URL}/common/auto-icon.svg`,
  DeviceIcon: `${GCP_URL}/common/device-icon.svg`,
  WarrantyIcon: `${GCP_URL}/common/warranty-icon.svg`,
  PropertyIcon: `${GCP_URL}/common/property-icon.svg`,
  SafeIcon: `${GCP_URL}/common/safe-icon.svg`,
  OthersIcon: `${GCP_URL}/common/others-logo.svg`,
  RightArrow: `${GCP_URL}/common/arrow-right.svg`,
  DownArrow: `${GCP_URL}/common/arrow-down.svg`,
  UpArrow: `${GCP_URL}/common/up-arrow.svg`,
  WhiteCheck: `${GCP_URL}/common/white-check.svg`,
  MenuIcon: `${GCP_URL}/common/menu-icon.svg`,
  PhoneGrey: `${GCP_URL}/common/phone-grey.svg`,
  SMSGrey: `${GCP_URL}/common/sms-grey.svg`,
  SMS: `${GCP_URL}/common/sms.svg`,
  Whatsapp: `${GCP_URL}/common/whatsapp.svg`,
  Pin: `${GCP_URL}/common/pin-grey.svg`,
  Time: `${GCP_URL}/common/time-grey.svg`,
  CheckGrey: `${GCP_URL}/common/check-grey.svg`,
  Upload: `${GCP_URL}/common/Upload.svg`,
  Siren: `${GCP_URL}/common/Siren.svg`,
  SentBlack: `${GCP_URL}/common/sent-balck.svg`,
  BackBtnPurple: `${GCP_URL}/common/back-btn-purple.svg`,
  ArrowRedDown: `${GCP_URL}/common/arrowDown.png`,
  BlueArrowDown: `${GCP_URL}/common/arrow-blue-button.png`,
  BlueArrowUp: `${GCP_URL}/common/blue-arrow.svg`,
  CrossBlue: `${GCP_URL}/common/CrossBlue.png`,
  Bag: `${GCP_URL}/common/bag-icon.png`,
  Building: `${GCP_URL}/common/building-icon.png`,
  DOB: `${GCP_URL}/common/dob-icon.png`,
  Globe: `${GCP_URL}/common/globe-icon.png`,
  Home: `${GCP_URL}/common/home-icon.png`,
  Link: `${GCP_URL}/common/link-icon.png`,
  Mail: `${GCP_URL}/common/mail-icon.png`,
  Sheild: `${GCP_URL}/common/sheild-grey-icon.png`,
  Star: `${GCP_URL}/common/star-icon.png`,
  Telephone: `${GCP_URL}/common/telephone-icon.png`,
  Notes: `${GCP_URL}/common/notes-icon.png`,
  FAQ: `${GCP_URL}/common/faq-icon.png`,
  Cross: `${GCP_URL}/common/cross-icon.png`,
  CustomerSupport: `${GCP_URL}/common/customer-support-form.png`,
  WhistleBlowingForm: `${GCP_URL}/common/WhistleBlowingForm-Banner.png`,
  WhistleBlowingForm2: `${GCP_URL}/common/whistle-blow2.png`,
  PageNotFound: `${GCP_URL}/common/PageNotFound.png`,
  DownloadWhite: `${GCP_URL}/common/download-white2.png`,
  DownloadBlack: `${GCP_URL}/common/download-black.png`,
  WhiteCircle: `${GCP_URL}/common/circle-white.png`,
  EWLogo: `${GCP_URL}/common/ew-logo.png`,
  DeviceLogo: `${GCP_URL}/common/device-logo.png`,
  SafeLogo: `${GCP_URL}/common/safe-logo.png`,
  SheildDark: `${GCP_URL}/common/sheild.png`,
  BagDark: `${GCP_URL}/common/bag.png`,


  // Home-Page Banner
  AutoBanner: `${GCP_URL}/Homepage/auto-banner.png`,
  DeviceBanner: `${GCP_URL}/Homepage/device-banner.jpeg`,
  SafeBanner: `${GCP_URL}/Homepage/safe-banner.jpeg`,
  TravelBanner: `${GCP_URL}/Homepage/travel-banner.jpeg`,
  WarrantyBanner: `${GCP_URL}/Homepage/warranty-banner.jpeg`,
  PropertyBanner: `${GCP_URL}/Homepage/property-banner.jpeg`,
  OthersBanner: `${GCP_URL}/Homepage/others-banner.jpeg`,
  AutoBannerMob: `${GCP_URL}/common/Image-Auto.png`,
  DeviceBannerMob: `${GCP_URL}/common/Image-Device.png`,
  SafeBannerMob: `${GCP_URL}/common/Image-Safe.png`,
  TravelBannerMob: `${GCP_URL}/common/Image-Travel.png`,
  WarrantyBannerMob: `${GCP_URL}/common/Image-Warranty.png`,
  PropertyBannerMob: `${GCP_URL}/common/Image-Property.png`,
  OthersBannerMob: `${GCP_URL}/common/Image-Others.png`,


  //  Payment Icons
  PhoneIA: `${GCP_URL}/Payment/smartphone-inactive.svg`,
  GopayIA: `${GCP_URL}/Payment/GoPay-inactive.svg`,
  QRIA: `${GCP_URL}/Payment/QR-code-inactive.svg`,
  DebitIA: `${GCP_URL}/Payment/debit-card-inactive.svg`,
  SuccessIcon: `${GCP_URL}/Payment/payment-success-icon.png`,
  PaymentPending: `${GCP_URL}/Payment/payment-pending.png`,
  PaymentExpired: `${GCP_URL}/Payment/payment-expired.png`,
  PaymentExpiredMobile: `${GCP_URL}/common/pay-expired-mob.png`,
  PaymentSucessMobile: `${GCP_URL}/common/pay-success-mob.png`,
  PaymentPendingMobile: `${GCP_URL}/common/pay-pending-mob.png`,
  PaymentUnpaidMobile: `${GCP_URL}/common/pay-unpaid-mob.png`,
  SheildBlue: `${GCP_URL}/Payment/sheild-blue.svg`,
  BagBlue: `${GCP_URL}/Payment/bag-blue.svg`,
  CalendarBlue: `${GCP_URL}/Payment/star-calendar.svg`,
  NotesBlue: `${GCP_URL}/Payment/notes.svg`,
  GooglePlay: `${GCP_URL}/Payment/google-play.png`,
  AppStore: `${GCP_URL}/Payment/app-store.png`,
  PaySuccessfull: `${GCP_URL}/Payment/payment-successfull.jpeg`,
  PayPending: `${GCP_URL}/Payment/payment-pending.jpeg`,
  PayExpired: `${GCP_URL}/Payment/payment-expired.jpeg`,
  PayUnpaid: `${GCP_URL}/Payment/payment-unpaid.jpg`,
  PayUnpaidMid: `${GCP_URL}/Payment/payment-unpaid-mid.png`,

}