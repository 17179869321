import styled from "styled-components";
import { imagesLink } from "./constants";

export const BannerContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    
`

export const BannerSubTitle = styled.div`
  @media only screen and (max-width: 480px) {
    img {
      width: 40px;
      margin-bottom: 16px;
    }
  }
`
export const BannerTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 56px;
  font-style: italic;
  font-weight: 800;
  line-height: 56px;
  text-transform: capitalize;

  @media only screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const HeadingSubtitle = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  @media only screen and (max-width: 480px) {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }
`

export const BannerContent = styled.div`
  background-image: url(${imagesLink.roadAssistanceBannerBackdrop});
  width: 80vw;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: top;

  @media only screen and (max-width: 480px) {
    background-image: none;
  }
`
export const BannerButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 40px;

`
export const BannerForegroundImageContainer = styled.div``
export const BannerForegroundImage = styled.img`
    max-width: 100vw;

    @media only screen and (max-width: 480px) {
      width: 360px;
    }
`
export const HighWaySosContainer = styled.button`
    background: #FAF000;
    color: #000;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    border: none;
    padding: 16px 36px;
    border-radius: 36px;

`
export const GoDownArrowContainer = styled.button`
    background: #FAF000;
    border: none;
    padding: 12px 16px;
    border-radius: 50%;
`

export const BodyContainer = styled.div`
  font-family: "Mulish", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(217,217,217);
  background: -moz-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
  background: -webkit-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
  background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9d9d9",endColorstr="#ffffff",GradientType=1);


  @media only screen and (max-width: 480px) {
       max-width: 100vw;
     }

`

export const Body1Container = styled.div`
    padding: 80px 80px 0px 80px;
    flex-wrap: wrap;
    max-width: 1200px;
    @media only screen and (max-width: 480px) {
      padding: 20px 0px;
      width: 100vw;
     }
`

export const Body1Heading = styled.div`
  h1 {
      color: #000;
      text-align: center;
      font-family: Mulish;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 56px;
      max-width: 80%;
  }
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  word-break: break-word;

  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 16px;
      line-height: 24px;
      max-width: 90%;
    }
  }
`
export const Body1Content = styled.div`
    display: flex;
    width: 100%;
    @media only screen and (max-width: 480px) {
      padding: 0px;
      width: 100vw;
     }

    @media only screen and (max-width: 480px) {
      flex-direction: column;
    }

`
export const Body1SideImageContainer = styled.div`
  @media only screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
  }
`
export const Body1SideImage = styled.img`
      @media only screen and (max-width: 480px) {
       width: 100vw;
     }

     @media only screen and (max-width: 480px) {
      margin-top: 16px;
      width: 80vw;
     }
`

export const Body1InfoContainer = styled.div``

export const Body1InfoContainerOrderedList = styled.ol`
  counter-reset: item;
  list-style-type: none;
`
export const Body1UnorderedListContainer = styled.ul`
  list-style: none;
`

export const Body1ContainerList = styled.li`
   counter-increment: item;
   margin-bottom: 8px;
   color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  width: 100%;
  &::before {
    content: "0" counter(item);
    margin-right: 10px; 
    color: #000;
    font-family: Mulish;
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px; /* 320% */
    top: -4px;
    position: relative;
  }
`
export const Body1UnorderedList = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  line-height: 24px;
  margin-bottom: 12px;
  &::before{
    content: url(${imagesLink.yellowLine});
    position: relative;
    top: -4px;
    right: 8px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;

    &::before {
      top: 0px;
      right: 4px;
    }
  }
`

export const Body1ContainerListMobile = styled.div `
`

export const Body1ContainerMobileItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;

  @media only screen and (max-width: 480px) {
    .body1SliderHead {
      font-size: 14px;
      line-height: 18px;
      font-weight: 800;
      max-width: 80%;
      text-align: center;
    }
    .body1SliderText {
      text-align: center;
      font-size: 12px;
      line-height: 20px;
      font-weight: 600;
      max-width: 80%;
      margin-bottom: 8px;
    }
  }
`

export const GreenShield = styled.img`
  width: 32px;
`



export const Body2Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    padding:  80px 24px;

    @media only screen and (max-width: 480px) {
      padding: 40px 0px;
    }
    
`

export const Body2HeadingText = styled.div`
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;

  .text-italic {
    font-style: italic;
  }

  @media only screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 24px;
    min-width: 90vw;
  }
`
export const Body2ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-around;
`
export const Body2ImageContainer = styled.div`
  border: ${props => props.active ? '8px solid #48B872': ''};
  border-radius: 50%;
  padding: 20px;
  opacity: ${props => props.active ? '100%' : '40%'};
  cursor: pointer;
  transition: opacity 0.5s ease;
  
  &:hover {
    opacity: 100%;
  }

  @media only screen and (max-width: 480px) {
    max-width: 104px;  
  }
`
export const Body2ImageHolder = styled.img`
`
export const Body2OrderedListContainer = styled.ol`
    counter-reset: item;
    list-style-type: none;
    max-width: 70%;
    min-width: 70%;
`
export const Body2OrderedList = styled.li`
  counter-increment: item;
  margin-bottom: 8px;
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  padding: 15px 6px;
  border-top: 2px solid #48B872;
  &::before{
    content: "0" counter(item);
    margin-right: 10px; 
    color: #000;
    font-family: Mulish;
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px; /* 320% */
    top: -4px;
    position: relative;
  }
`
export const Body2OrderedListHeading = styled.span`
  color: #000;
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 160% */
`
export const Body2OrderedListText = styled.div`
  color: #000;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  margin-left: 24px;
`


export const Body3Container = styled.div`
  display: flex;
  max-width: 80vw;
  gap: 80px;
  width: 100%;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
  }
`
export const Body3ImageContainer = styled.div`
  @media only screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const Body3ImageHolder = styled.img`
  @media only screen and (max-width: 480px) {
    width: 80vw;
  }

  @media only screen and (max-width: 480px) {
    width: 90vw;
    height: 280px;
  }
`
export const Body3Content = styled.div``
export const Body3ImageHeading = styled.div`
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 100% */

  @media only screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 26px;
  }
`
export const Body3ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
`
export const Body3ListItem = styled.div`
  color: #000;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-bottom: 2px solid #00D278;
  padding-bottom: 24px;

  @media only screen and (max-width: 480px) {
    border-bottom: none;
    border-top: 1px solid #00D278;
  }
`
export const Body4Container = styled.div`
  padding: 80px 0px;

  @media only screen and (max-width: 480px) {
    padding: 0;
  }
`
export const Body4TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media only screen and (max-width: 480px) {
    gap: 16px;
  }
`
export const Body4Heading = styled.div`
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 100% */

  @media only screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const Body4SubTitle = styled.div`
  p {
    color: #000;
    text-align: center;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  @media only screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    p {
      font-size: 12px;
      line-height: 16px;
      max-width: 70%;
    }
  }
`
export const Body4ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const Body4Button = styled.button`
  color: #FFF;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border-radius: 100px;
  background: var(--Tap-Auto, #00D277);
  border: none;
  padding: ${props => props.padding || "12px 32px"};
  transition: background 0.5s ease;
  cursor: pointer;

  &:hover {
    background: #069155;
  } 
`
export const Body4ButtonImage = styled.img`
  height: 18px;
  width: 18px;
`
export const Body4ImageContainer = styled.div`
  background-image: ${props => props.mobileView ? `url(${imagesLink.beachBackgroundMobile})` : `url(${imagesLink.beachBackground})`};
  width: 60vw;
  height: 512px;
  margin-top: 8px;
  margin: auto;
  @media only screen and (max-width: 480px) {
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
`;

export const Body4BannerImage = styled.img`
    width: 60vw;
    height: 100%;

    @media only screen and (max-width: 480px) {
      width: 200px;
      height: 180px;
     }
`

export const Body5Container = styled.div`
  // padding: 80px 0px;
`

export const Body5TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
export const Body5Heading = styled.div`
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 100% */
`
export const Body5SubTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`
export const InquiryFormDiv = styled.div`
  width: 100vw;
`
export const AnchorTag = styled.a`
  text-decoration: none;
`
export const ProductListWrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  margin-bottom: 50px;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  gap: 100px;
`

export const ProductListIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  align-items: center;
`

export const ProductListIconContainer = styled.div`
  height: 100px;
  width: 100px;
  border: ${props => props.active ? '5px solid #48B872' : '5px solid #e7e7e7'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 5px solid #4a9667;
  }
`

export const ProductListIcon = styled.img`
  height: 68px;
  width: 68px;
`

export const ProductListTitle = styled.div`
  font-family: Mulish;
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 17px;
  text-align: center;
  width: 70px;
  color: ${(props) => (props.isActive ? "#333333" : "#999999")};
`
export const BottomFormButton = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 12px 20px 12px 20px;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  background: #00D278;
  color: #FFFFFF;
  z-index: 2;
`