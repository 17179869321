import styled from "styled-components";

export const TextInputContainer = styled.div`
  font-family: Mulish;
  width: 100%;

  input {
    border-radius: 8px;
    border: none;
  }
`;

export const TextInputItemContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "160px")};
`;

export const TextInputItem = styled.textarea`
  width: 96%;
  padding: 16px 15px;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  outline: none;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  background: #ffffff;
  border-radius: 16px;
  resize: vertical;
  border: ${props => props.error ? '2px solid #DC0000' : '2px solid #eeeeee'};
  min-height: 100px;

  color: #000000;

  &::placeholder {
    color: #757575;
    font-weight: 500;
  }

  @media screen and (max-width: 1024px) {
      width: 90%;
  }
`;

export const PromptError = styled.div`
  width: 100%;
  margin: 8px 0px;
  display: flex;
  gap: 1%;
`;
export const PromptErrorMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-family: Mulish;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1rem;
  letter-spacing: 0.02em;
  color: #DC0000;
`;