import styled from 'styled-components';

export const AboutUsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;

    * {
        padding: 0;
        margin: 0;
    }

    .content {
        width: 100%;  
        background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        margin-bottom: 50px;
    }

    .hero-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;               
    }

    .hero-section img {
        max-width: 1200px;
    }
    
    .content-inner {
        width: 100%;
        max-width: 636px;   
        margin: 0 auto;            
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        line-height: 32px;
        gap: 40px;        
    }

    @media (max-width: 480px) {
        .content {
            margin-bottom: 24px;
            width: 100vw;
            padding: 16px 0px;
        }

        margin-top: 16px;
        margin-bottom: 16px;
        .content-inner {
            width: 80%;            
            max-width: 100%;        
            margin: 0 auto;         
            padding: 12px;          
            line-height: 26px;
            text-align: justify;
            padding: 16px 0px 0px 0px;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
        }

        .hero-image {
            width: 100%;
            height: 340px;
        }

    }
`;
