import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const MainHeading = styled.h1`
  font-size: 2.5em;
  margin-top: 20px;
  font-family: Mulish;
  font-weight: 800;

  @media only screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }
`;

export const SearchSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-around;
  background-color: #FFF;
  padding: 24px;
  width: 80%;
  border-radius: 16px;
  align-items: center;

  // @media only screen and (max-width: 480px) {
  //   width: 80%;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }
`;

export const Input = styled.input`
  border: 0px solid #ccc;
  border-radius: 5px;
  width: 200px;
  outline: none;
  ::placeholder{
    color: #666;
    font-family: Mullish;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const SearchIcon = styled.img`
  width: 30px;
  height: 30px;
`

export const SearchInputSection = styled.div`
  display: flex;
  align-items: center;
`

export const SearchButtonSection = styled.div``

export const Button = styled.button`
  background-color: ${props => props.background || '#FAF000'};
  color: ${props => props.color || '#000'};
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 48px;
`;

export const Table = styled.table`
  max-width: 1200px;
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  margin: 0 auto;
`;

export const TableHeader = styled.th`
  border-bottom: 2px solid #ffd700;
  padding: 16px 0px;
  text-align: justify;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableCell = styled.td`
  padding: 15px 15px 15px 0;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-weight: 400;
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; 
  
  img {
    cursor: pointer;
  }
`;

export const Footer = styled.footer`
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
  margin-top: 40px;
`;

export const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

`
export const BannerArrowDownIconContainer = styled.div`
  width: 30px;
  height: 120px;
  flex-shrink: 0;
  background-color: #000;
  border-radius: 20px;
  @media screen and (max-width: 1024px) {
    display : none;
  }
`
export const BannerArrowDownIcon = styled.img``
export const BannerImageContainer = styled.div`
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
`
export const BannerImage = styled.img`
  width: 100vw;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

export const WorkshopMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
`

export const WorkshopMobileInnerContainer = styled.div`
  width: 80%;
`

export const WorkshopMobileHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;

  .workshop-name {
    font-size: 14px;
    font-weight: 800;
    max-width: 80%;
    word-break: break-word;
    text-align: left;
  }
`

export const WorkshopMobileType = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 32px;
  border: 1px solid #000;
  padding: 4px 8px;
  border-radius: 100px;
`

export const WorkshopMobileContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  margin-top: 8px;

  img {
    cursor: pointer;
    width: 24px;
    justify-self: end;
  }

  .province-name {
    text-align: left;
  }

`

export const DropdownMobileContainer = styled.div `
  display: flex;
  justify-content: space-around;
  width: 100%;
`

export const WorkshopButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 8px;
  width: 80%;
  gap: 16px;
`

export const WorkshopButton = styled.a `
  font-size: 8px;
  line-height: 12px;
  font-weight: 700;
  text-align: center;
  border: 1px solid #000;
  opacity: 0.5;
  padding: 8px 8px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background-color: #FAF000;
    opacity: 1;
    border: none;
  }
`

export const TableContainer = styled.div`
    padding: 0px 96px;
`

export const ContentInner = styled.div `
  position: relative;
  top: -20px;
  width: 100vw;
  background: rgb(217,217,217);
  background: -moz-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 30%);
  background: -webkit-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 30%);
  background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 30%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9d9d9",endColorstr="#ffffff",GradientType=1);
  padding-top: 48px;

  .table-title {
    font-size: 40px;
    line-height: 24px;
    font-weight: 800;
  }

  .text-normal {
    font-weight: 400;
  }

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #EFEFEF;
    background: -moz-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
    background: -webkit-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
    background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9d9d9",endColorstr="#ffffff",GradientType=1);

    h2 {
      max-width: 80%;
    }
    .table-title {
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
`

export const PaginationButtonLeft = styled.button`
  width: 40px;
  height: 40px;
  background-color: #D9D9D9;
  transition: background-color 0.5s ease;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: #adadad;
  }

  &:disabled {
    background-color: #f0f0f0; 
    cursor: not-allowed; 
    opacity: 0.6; 
  }
`

export const PaginationButtonRight = styled.button`
  width: 40px;
  height: 40px;
  background-color: #D9D9D9;
  transition: background-color 0.5s ease;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #adadad;
  }

  &:disabled {
    background-color: #f0f0f0; 
    cursor: not-allowed; 
    opacity: 0.6; 
  }
`

export const HeadingSubtitle = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  @media only screen and (max-width: 480px) {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
  }
`