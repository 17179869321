import styled from 'styled-components'

export const DetaillTabsContainer = styled.div``

export const Tab = styled.a`
  width: 50%;
  height: 44px;
  justify-content: space-around;
  display: flex;
  align-items: center;
  border: 2px solid #B43CFA;
  border-radius: 12px;
  background: ${props => props.isActive ? '#B43CFA' : '#FFFFFF'};
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    scale: 0.97;
  }

  @media screen and (max-width: 1024px) {
    height: 40px;
  }
`

export const ComponentWrapper = styled.div`
`

export const TabImage = styled.span`
  padding: 16px;
`

export const TabTitle = styled.header`
  font-family: Mulish;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.6rem;

  text-align: center;

  color: #B43CFA;

  ${props => props.isActive && (`
    color: #FFFFFF;
  `)}

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.02em;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  margin: ${props => props.margin || '0px'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  width: ${props => props.width || 'unset'};
  padding: ${props => props.padding || '0px'};
  border-radius: ${props => props.borderRadius || '0px'};
  gap: 1%;
`