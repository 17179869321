import { useState } from "react";
import {
  OtherProductsContainer,
  ProductListIcon,
  ProductListIconWrapper,
  ProductListWrapper,
  ProductListTitle,
  ProductListIconContainer,
  ProductHeaderText,
  ProductDescription,
  ProductButtonContainer,
  ProductButton,
  ProductBtnArrow,
  AnchorTag,
} from "./OtherProductsPage.style";
import Header from "../../components/Header";
import { imagesLink, PRODUCT_TYPE, riplayDocUrl } from "./constants";
import { ImageUrl } from "../../Images";
import { FormattedMessage } from "react-intl";
import Footer from "../../components/Footer";
import InquiryForm from "../userCriteria/inquiryForm";
import ScrollToTop from "../../components/ScrollToTop";

const OtherProductsPage = () => {
  const [activeProduct, setActiveProduct] = useState("liability");

  return (
    <OtherProductsContainer>
      <Header />
      <div className="heading">
        <ProductHeaderText>
          <FormattedMessage
            id="heading"
            defaultMessage="Aman dan Mudah,{br}Asuransi Untuk Semua"
            values={{
              br: <br />,
            }}
          />
        </ProductHeaderText>
        <ProductDescription>
          <FormattedMessage
            id="heading"
            defaultMessage="Mentari pagi bersinar terang, menemani langkah di jalan raya.{br} TAP Insure jaga di setiap langkah, risiko aman, hidup lebih bahagia."
            values={{
              br: <br />,
            }}
          />
        </ProductDescription>
        <div className="section">
          <img src={imagesLink.ProductHeading} width='100%'/>
        </div>
      </div>
      <ProductListWrapper>
        {PRODUCT_TYPE.map((product) => {
          return (
            <ProductListIconWrapper
              onClick={() => {
                setActiveProduct(product.productName);
              }}
            >
              <ProductListIconContainer
                active={activeProduct === product.productName}
              >
                <ProductListIcon src={product.activeIcon} alt={product.key} />
              </ProductListIconContainer>
              <ProductListTitle
                isActive={activeProduct === product.productName}
              >
                {product.label}
              </ProductListTitle>
            </ProductListIconWrapper>
          );
        })}
      </ProductListWrapper>
      {activeProduct === "liability" && (
        <>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mengapa Asuransi Liability dari TAP Insure?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Asuransi Liability Tap Insure memberikan perlindungan yang kamu butuhkan untuk menghadapi berbagai risiko{br} hukum yang dapat mempengaruhi bisnis kamu. Dengan asuransi Liability Tap Insure, kamu akan terlindungi dari{br} tuntutan yang mungkin timbul akibat operasi bisnis kamu, produk yang kamu jual, atau aktivitas di lokasi bisnis."
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.LiabilityPhoto} />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Bisnis Terlindungi, Risiko Hukum Terkendali!"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Asuransi Liability Tap Insure melindungi kamu dari:"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.LiabilityInfo} className="img-width" />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mau Tau Lebih Lanjut?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Cek info lengkap Asuransi Liability Tap Insure sekarang!"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <AnchorTag href={riplayDocUrl.liability} target="_blank">
            <ProductButtonContainer>
              <ProductButton>
                <FormattedMessage
                  id="Unduh"
                  defaultMessage="Unduh Informasi Produk"
                />
              </ProductButton>
              <ProductBtnArrow padding="0px">
                <img src={ImageUrl.DownloadBlack} width="17px" height="17px" />
              </ProductBtnArrow>
            </ProductButtonContainer>
          </AnchorTag>
          <div className="section m-100">
            <img src={imagesLink.LiabilityMoreInfo} className="" />
          </div>
          <InquiryForm product="Asuransi Liability" />
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Saat Hidup Membawa Kejutan,{br}TAP Insure Siap Memberikan Perlindungan."
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Lindungi Bisnismu, Jalani Usaha Tanpa Cemas! Dari tuntutan kecil hingga risiko besar, kami siap{br} jaga tanggung jawabmu. Dengan Tap Insure, hadapi setiap risiko hukum tanpa khawatir."
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.LiabilityBanner} className="" />
          </div>
        </>
      )}
      {activeProduct === "shipping" && (
        <>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mengapa Asuransi Shipping dari TAP Insure?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Asuransi Shipping Tap Insure menawarkan perlindungan untuk mengatasi kerugian atau kerusakan pada harta{br} benda kamu. Kamu dapat merasa lebih aman dan yakin bahwa pengiriman barang berhargamu dilindungi dengan{br} baik selama proses pengangkutan."
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.ShippingPhoto} className="" />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Kiriman Terjaga, Sampai Tanpa Drama!"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Asuransi Shipping Tap Insure melindungi harta benda kamu dari:"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.ShippingInfo} className="" />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mau Tau Lebih Lanjut?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Cek info lengkap Asuransi Shipping Tap Insure sekarang!"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <AnchorTag href={riplayDocUrl.shipping} target="_blank">
            <ProductButtonContainer>
              <ProductButton>
                <FormattedMessage
                  id="Unduh"
                  defaultMessage="Unduh Informasi Produk"
                />
              </ProductButton>
              <ProductBtnArrow padding="0px">
                <img src={ImageUrl.DownloadBlack} width="17px" height="17px" />
              </ProductBtnArrow>
            </ProductButtonContainer>
          </AnchorTag>
          <div className="section m-100">
            <img src={imagesLink.ShippingMoreInfo} className="" />
          </div>
          <InquiryForm product="Asuransi Liability" />
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Saat Hidup Membawa Kejutan,{br}TAP Insure Siap Memberikan Perlindungan."
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Kirim barang berhargamu tanpa khawatir. Dari risiko kerusakan hingga kehilangan, perlindungan Tap Insure{br}  hadir untuk menjaga pengirimanmu. Lindungi barangmu dari risiko besar maupun kecil selama perjalanan."
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.ShippingBanner} className="m-100" />
          </div>
        </>
      )}
      {activeProduct === "equipment" && (
        <>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mengapa Asuransi Heavy Equipment dari TAP Insure?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Asuransi Heavy Equipment Tap Insure adalah solusi perlindungan yang dirancang khusus untuk menjaga alat berat{br} kamu dari risiko kerusakan atau kehilangan selama masa pertanggungan. Dengan perlindungan ini, kamu dapat{br} menjalankan proyek dengan lebih percaya diri dan tanpa rasa kekhawatiran."
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.EquipmentPhoto} className="" />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Alat Berat Terjamin, Kerja Tanpa Khawatir!"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Asuransi Heavy Equipment Tap Insure melindungi alat berat kamu dari:"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.EquipmentInfo} className="" />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Jika Dibutuhkan..."
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Manfaat lainnya dari Asuransi Heavy Equipment Tap Insure:"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.EquipmentProtection} className="" />
          </div>
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Mau Tau Lebih Lanjut?"
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Cek info lengkap Asuransi Heavy Equipment Tap Insure sekarang!"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <AnchorTag href={riplayDocUrl.equipment} target="_blank">
          <ProductButtonContainer>
              <ProductButton>
                <FormattedMessage
                  id="Unduh"
                  defaultMessage="Unduh Informasi Produk"
                />
              </ProductButton>
              <ProductBtnArrow padding="0px">
                <img src={ImageUrl.DownloadBlack} width="17px" height="17px" />
              </ProductBtnArrow>
            </ProductButtonContainer>
          </AnchorTag>
          <div className="section m-100">
            <img src={imagesLink.EquipmentMoreInfo} className="" />
          </div>
          <InquiryForm product="Asuransi Liability" />
          <ProductHeaderText>
            <FormattedMessage
              id="heading"
              defaultMessage="Saat Hidup Membawa Kejutan,{br}TAP Insure Siap Memberikan Perlindungan."
              values={{
                br: <br />,
              }}
            />
          </ProductHeaderText>
          <ProductDescription>
            <FormattedMessage
              id="heading"
              defaultMessage="Asuransi ini dirancang khusus untuk menjaga alat beratmu dari risiko kerusakan dan kehilangan selama masa{br} pertanggungan. Jalani proyek dengan percaya diri, tanpa rasa khawatir. Tap Insure siap mendukung setiap langkahmu"
              values={{
                br: <br />,
              }}
            />
          </ProductDescription>
          <div className="section m-100">
            <img src={imagesLink.EquipmentBanner} className="" />
          </div>
        </>
      )}
      <ScrollToTop /> 
      <Footer />
    </OtherProductsContainer>
  );
};

export default OtherProductsPage;
