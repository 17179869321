import styled from 'styled-components'
import { imagesLink } from './constants'

export const PropertyContainer = styled.div`
  .section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
  }

  .heading-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 56px;
    font-weight: 800;
    font-size: 40px;
  }

  .m-50 {
    margin-bottom: 50px;
  }

  .m-100 {
    margin-bottom: 100px;
  }

  .mid-image{
    width: 50%;
    height: 500px;
    margin: auto;
  }

  .hero-container {
    margin-top: 32px;
    background-image: url(${imagesLink.PropertyBannerHeading});
    background-repeat: no-repeat;
    background-size: contain, cover;
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 700px;
    max-height: 700px;
    background-position: center;
    margin: auto;
  }

  .hero-container h1 {
    font-size: 40px;
    line-height: 56px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 16px;
  }

  .hero-container > p {
    max-width: 645px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .hero-container img {
    margin-bottom: 16px;
  }

  .hero-div {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 200px;
    margin-top: 72px;
  }
  
  .hero-div-inner {
    border: 7px solid #B0B0B0;
    border-radius: 20px;
    padding: 24px;
    text-align: center;
    font-size: 14px;
    background-color: rgba(255,255,255,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .hero-div-items {
    border-bottom: 4px solid #B0B0B0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .price-title {
    margin-top: 0;
    margin-bottom: 0;
  }

  .hero-div-items p:last-of-type {
    font-size: 40px;
    color: #EB85B5;
    text-align: left;
    font-weight: 800;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .hero-div-items .super {
    font-size: 12px;
    color: #b0b0b0;
    vertical-align: super;
    font-weight: 800;
    position: relative;
    top: -8px; 
    margin-right: 16px;
  }

  .hero-div-items:last-child {
      border-bottom: none;
  }
`

export const ProductListWrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  margin-bottom: 50px;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  background: linear-gradient(to bottom, #D9D9D9, #FFFFFF);
  gap: 100px;
`

export const ProductListIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  align-items: center;
`

export const ProductListIconContainer = styled.div`
  height: 100px;
  width: 100px;
  border: ${props => props.active ? '5px solid #EB85B5' : '5px solid #FFFFFF'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
`

export const ProductListIcon = styled.img`
  height: 68px;
  width: 68px;
`

export const ProductListTitle = styled.div`
  font-family: Mulish;
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 17px;
  text-align: center;
  width: 70px;
  color: ${(props) => (props.isActive ? "#333333" : "#999999")};
`
export const ProductButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`
export const ProductButton = styled.button`
  color: #FFFFFF;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  border-radius: 100px;
  background: #EB85B5;
  border: none;
  padding: ${props => props.padding || "12px 32px"};
  cursor: pointer;
  ont-weight: 600;
  transition: background-color 0.5s ease;

  &:hover {
      background-color: #d86ea0;
  }
`

export const ProductBtnArrow = styled.button`
  color: #333333;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border-radius: 50%;
  background: #EB85B5;
  border: none;
  padding: ${props => props.padding || "12px 32px"};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:hover {
      background-color: #d86ea0;
  }
`
export const ProductDescription = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
`
export const ProductHeaderText = styled.div`
  font-family: Mulish;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: center;
  color: ${props => props.textColor ? props.textColor : '#000000'};
  line-height: 56px;
  font-weight: 800;
  font-size: 40px;
`
export const AnchorTag = styled.a`
  text-decoration: none;
  display: block;
  width: fit-content;
  margin: auto;
`