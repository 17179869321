import styled from "styled-components";

export const SelectionWrapper = styled.div``
export const SelectInputCtn = styled.div`
    display: grid;
    grid-template-columns: 93% 7%;
    border-bottom: 2px solid #f5f5f5;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0px;
`
export const SelectInput = styled.input`
    outline: none;
    height: 50px;
    padding: 0px 30px;
    border: none;
    font-size: 14px;
  font-family: Mulish;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
`
export const Countries = styled.div`
    width: 100%;
    height: 40px;
    padding: 5px 30px;
    gap: 8px;
    border-bottom: 2px solid #f5f5f5;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Mulish;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    cursor: pointer;

    &:hover {
        background-color: #FAFA96;
    }

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
    }
`