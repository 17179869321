import styled from "styled-components";
import LargeButton from "../LargeButton";

export const FooterCtn = styled.div`    
    width: 100%;
    background: #ffffff;
    position: sticky;
    bottom: 0px;
`
export const FooterWrapper = styled.div`
    width: 80%;
    margin: auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;

    @media screen and (max-width: 1024px) {
        width: 92%;
    }
`
export const SubmitBtn = styled(LargeButton)`
    min-width: 300px;
    margin: initial;
    color: #FFFFFF;

    @media screen and (max-width: 1024px) {
        min-width: 100px;
    }
`
export const FooterDescription = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
    }
`
export const GreyText = styled.div`
    font-family: Mulish;
    font-size: ${props => props.textSize ? props.textSize : '16px'};
    font-weight: 700;
    line-height: 10px;
    text-align: left;
    color: ${props => props.color ? props.color : '#999999'};

    @media screen and (max-width: 1024px) {
        font-size: 10px;
        line-height: 14px;
        font-size: 10px;
    }
`
export const BlueText = styled.div`
    font-family: Mulish;
    font-size: ${props => props.textSize ? props.textSize : '16px'};
    font-weight: 800;
    line-height: 10px;
    text-align: left;
    color: ${props => props.color ? props.color : '#999999'};

    @media screen and (max-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
    }
`