import styled from 'styled-components';

export const HomePageContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;

    // * {
    //     margin: 0;
    //     padding: 0;
    // }

    // * {
    //     margin: 0;
    //     padding: 0;
    // }

    background-image: linear-gradient(to bottom, #dbdbdb, #ffffff);
    
    .hero-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 32px;

        .banner-asset {
            width: 500px;
        }

        img {
            max-width: 1200px;
            width: 100%;
        }

        h1 {
            font-weight: 400;
            font-size: 40px;
            margin-bottom: 32px;
        }

        .hero-items {
            text-align: left;
            word-break: break-word;
            max-width: 280px;
            position: relative;
        }

        .hero-items h2 {
            font-weight: 800;
        }

        strong {
            font-weight: 800;
        }

        .hero-items:first-of-type {
            bottom: 520px;
            left: 360px;
        }

        .hero-items:nth-of-type(2) {
            bottom: 470px;
            right: 350px;
        }

        .hero-items:last-of-type {
            bottom: 400px;
            left: 350px;
        }

        .hero-subtitle {
            text-align: center;
            margin-top: 0;
            line-height: 24px;
            width: 35%;
        }
    }

    .product {
        margin-top: -250px;
        h1 {
            text-align: center;
            margin-bottom: 24px;
            font-weight: 800;
        }

        @media only screen and (max-width: 576px) {
            margin-top: 0px;
        }
    }

    .banner-footer {
        width: 800px !important;
        margin-top: 32px;
    }

    @media (max-width: 1200px) {
        .hero-section img {
            max-width: 1000px;
            width: 100%;
        }
    }

    @media (max-width: 992px) {
        .hero-section img {
            max-width: 800px;
        }

        .product h1 {
            font-size: 24px;
        }
    }

    @media (max-width: 768px) {
        .hero-section img {
            max-width: 600px;
        }

        .product h1 {
            font-size: 22px;
            margin-bottom: 20px;
        }

        .hero-section {
            padding: 24px;
        }
    }

    @media (max-width: 576px) {
        .hero-section {
            align-items: center;
            padding: 16px;
            
            .hero-items:first-of-type,
            .hero-items:nth-of-type(2),
            .hero-items:last-of-type {
                bottom: 0; 
                left: 0; 
                right: 0; 
                width: 280px;
                align-self: flex-start;
                padding-left: 24px;
            }
            .hero-subtitle {
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                text-align: center;
                color: #333;
                width: 55%;
            }
        }

        .hero-section img {
            max-width: 240px;
        }

        .hero-section .banner-footer {
            max-width: 410px;
            width: 100%;
        }

        .product h1 {
            font-size: 20px;
            margin-bottom: 16px;
        }

        .hero-section h1 {
            font-size: 16px;
            font-weight: 800;
        }

        .hero-section h1 strong {
            font-weight: 800;
            font-size: 16px;
        }

        .hero-section-title-2 {
            max-width: 60%;
            text-align: center;
            line-height: 24px;
        }

        .hero-items h2 {
            font-size: 14px;
            font-weight: 800;
        }

        .hero-items p {
            font-size: 12px;
            line-height: 20px;
        }

        .product {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .product h1 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 800;
            text-align: center;
            max-width: 60%;
        }
    }
`;

export const DownloadButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

export const DownalodIcon = styled.img`
  width: 24.199px;
  height: 29.748px;

  @media only screen and (max-width: 480px) {
    width: 16px;
    height: 19px;
  }
  `

export const DownloadButton = styled.a`
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 20px;
  text-decoration: none;
  color: #000;
  background-color: #FAF000;
  align-items: center;
  display: flex;
  font-weight: 800;
  gap: 14px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  
  img {
    width: 24px !important;
  }

  .download-text {
    font-size: 12px;
    text-align: left;
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
  }

  &:hover {
    background-color: #d9d004;
  }

  @media only screen and (max-width: 480px) {
    width: 112px;
    font-size: 14px;

    .download-text {
        font-size: 8px;
    }
  }
`;
