import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const urlList = [
  "/",
  "/tentang-kami",
  "/visi-misi",
  "/tata-kelola-perusahaan",
  "/hubungi-kami",
  "/whistleblowing",
  "/klaim",
  "/laporan-keuangan",
  "/bengkel-rekanan",
  "/roadside-assistance",
  "/produk-travel-sales-flow/package-details",
  "/produk-travel-sales-flow/insured-details",
  "/produk-travel-sales-flow/review-order",
  "/payment/payment-mode",
  "/payment/payment-status",
];

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const metaViewport = document.querySelector('meta[name="viewport"]');

    if (urlList.includes(location.pathname)) {
      // Allow responsive CSS for specific pages
      metaViewport.setAttribute('content', 'width=device-width, initial-scale=1');
    } else {
      // Restrict viewport to 1024px for other pages
      metaViewport.setAttribute('content', 'width=1024');
    }

  }, [location]);

  return null;
};

export default ScrollToTop;
