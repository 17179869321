import React, { useEffect, useMemo, useState } from "react";
import convertToIDR from "../../../lib/utility/convertToIDR";
import PaymentBreakup from "./paymentBreakup";
import { CardTitle, LargeButtonSpinner, PaymentDetailsCtn, PaymentDetailsWrap, PromoCheckIcon, PromoErrorText, PromoInput, PromoInputCtn, PromoMobileError, PromoWrapper, SumitButton } from "../elements";
import { ImageUrl } from "../../../Images";
import { useSearchParams } from "react-router-dom";
import { useApplyCoupon } from "../../../lib/api";
import { getWindowDimensions } from "../../../constants/travel";

const PaymentDetails = ({draftData = {}, discountCoupData = {}, showDiscount = false, couponApplied = false, oneUserCouponApplied = false, setDisCountCoupData = () => {}, updateDraft = () => {}, setTotalPremium = () => {}}) => {
  const [searchParams, setSearchParams] = useSearchParams();
    
  const [promoInput, setPromoInput] = useState('')
  const [promoErorr, setPromoEror] = useState(false)
  const [isCouponApplied, setISCouponApplied] = useState(false)
  const draftPackageReq = draftData?.payload?.package_request || {}
  const { additional_fee: {admin_fee = 0, stamp_fee = 0} = {}, coupon_data: { total_discount: discount_amount = 0, discounted_premium: discountedPremium = 0 } = {}, package_response: { premium: packagePremium = 0 } = {}, coupon_data = {}, package_response: packageDetails = {} } = draftData?.payload || {}

  useEffect(() => {
    if (draftData?.payload?.coupon_data && Object.keys(draftData?.payload?.coupon_data).length) {
      setPromoInput(draftData?.payload?.coupon_data?.code)
      setISCouponApplied(true)
    } else {
      setPromoInput('')
      setISCouponApplied(false)
    }
  }, [draftData])

  const { validateCoupon, isLoading: validateCouponLoading } = useApplyCoupon({
    inputPromo: promoInput.trim(),
    ref_id: searchParams.get("draft_id"),
    onSuccess: (data) => {
        if (!data?.data?.total_discount) {
            setPromoEror(true)
        } else {
            setISCouponApplied(true)
            setDisCountCoupData({ ...data.data });

            const draftPayload = {
                product_code: draftData?.product_code,
                package_code: draftData?.package_code,
                data: {
                  ...draftData.payload,
                  coupon_data: {
                    code: data?.data?.code,
                  },
                },
              };

            updateDraft(draftPayload);
        }
    },
    onFailure: (e) => {
      setPromoEror(true)
    },
  });

  const clearCouponData = () => {
    const draftPayload = {
      product_code: draftData?.product_code,
      package_code: draftData?.package_code,
      data: {
        ...draftData.payload,
        coupon_data: {},
      },
    };
    updateDraft(draftPayload);
    setDisCountCoupData({});
    setISCouponApplied(false)
  };

  const handleClick = () => {
    setPromoEror(false)
    isCouponApplied ? clearCouponData() : validateCoupon()
  }

    const paymentMap = useMemo(() => {
        setTotalPremium(couponApplied ? discountCoupData?.discounted_premium ? convertToIDR(packageDetails.total_premium + (admin_fee + stamp_fee - discountCoupData.total_discount)) : 0 : showDiscount ?  convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : discountedPremium  ? convertToIDR(packageDetails.total_premium + (admin_fee + stamp_fee - discount_amount)) : packageDetails.total_premium ? convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : 0)
        return {
          basic_premium: packageDetails.total_premium ? convertToIDR(packageDetails.total_premium) : '',
          discount: couponApplied ? discountCoupData?.total_discount ? convertToIDR(discountCoupData?.total_discount) : '' : showDiscount ? '' : discount_amount ? convertToIDR(discount_amount) : '',
          sub_total: couponApplied ? discountCoupData.total_discount ? convertToIDR(packageDetails.total_premium - discountCoupData.total_discount) : '' : showDiscount ? convertToIDR(packageDetails.total_premium) : discountedPremium ? convertToIDR(packageDetails.total_premium - discount_amount) : packageDetails.total_premium ? convertToIDR(packageDetails.total_premium) : '',
          total_premium: couponApplied ? discountCoupData?.discounted_premium ? convertToIDR(packageDetails.total_premium + (admin_fee + stamp_fee - discountCoupData.total_discount)) : '' : showDiscount ?  convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : discountedPremium  ? convertToIDR(packageDetails.total_premium + (admin_fee + stamp_fee - discount_amount)) : packageDetails.total_premium ? convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : '',
          admin_fee: convertToIDR(admin_fee),
          total_stamp_fee: convertToIDR(stamp_fee),
          total_bill: packageDetails.total_premium ? convertToIDR(packageDetails.total_premium + admin_fee + stamp_fee) : '',
          stamp_fee: stamp_fee,
          premium_per_person: packagePremium
        }
    }, [draftData, packageDetails.premium, couponApplied, discountCoupData, admin_fee, stamp_fee, discount_amount, discountedPremium])

    return (
        <PaymentDetailsCtn>
            {getWindowDimensions().width > 1024 && <><CardTitle>Detail Transaksi</CardTitle>
              <PaymentDetailsWrap><PaymentBreakup 
                paymentMap={paymentMap}
                noOfInsuredPersons={draftPackageReq?.no_of_insured_persons}
                isGroupPackage={draftPackageReq?.product_type === 'group'}
                couponDetail={Object.keys(coupon_data).length ? coupon_data : Object.keys(discountCoupData) ? discountCoupData : {}}
                oneUserCouponApplied={oneUserCouponApplied}
            /></PaymentDetailsWrap></>}
            <PromoWrapper>
              {getWindowDimensions().width > 1024 && <CardTitle>Promo</CardTitle>}
              <PromoMobileError>
                <PromoInputCtn isError={promoErorr} isDisabled={isCouponApplied}>
                    <PromoInput placeholder="Kode Promo" value={promoInput} onChange={e => {
                        setPromoInput(e.target.value)
                        setPromoEror(false)
                    }} disabled={isCouponApplied}/>
                    {isCouponApplied && <PromoCheckIcon src={ImageUrl.BlueCheck2} alt='check'/>}
                    {validateCouponLoading ? <div style={{maxHeight: '20px', maxWidth: '20px'}}>
                        <LargeButtonSpinner />
                    </div> : null}
                </PromoInputCtn>
                {promoErorr && <PromoErrorText>Promo tidak ditemukan atau tidak berlaku</PromoErrorText>}
              </PromoMobileError>
              <SumitButton
                  id="promo-input"
                  disabled={!promoInput.length}
                  onClick={() => handleClick()}
                  type={isCouponApplied ? 'blue' : 'secondary'}
              >
                  {isCouponApplied ? 'Batal' : 'Gunakan'}
              </SumitButton>
            </PromoWrapper>
        </PaymentDetailsCtn>
    )
}

export default PaymentDetails