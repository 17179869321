import styled from "styled-components";
import LargeButton from "../../components/LargeButton";

export const CriteriaWrapper = styled.div`
  width: 75%;
  padding: 48px;
  border-radius: 32px;
  border: 2px solid #eeeeee;
  margin: auto !important;
  background: #ffffff;

  @media screen and (max-width: 480px) {
    width: 90%;
    padding: 16px;
    border-radius: 0px;
    border: none;
    padding-bottom: 50px;
  }
`;
export const FormItemWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 32% 32% 33%;
  gap: 1%;
  margin-bottom: 35px;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;
export const FormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  height: 34px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    text-align: left;
  }
`;
export const OptionsWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;

  @media screen and (max-width: 480px) {
    margin-top: 8px;
  }
`;
export const OptionsListWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }
`;
export const OptionsListWrapperperson = styled.div`
  margin-top: 20px;
  width: 100%;
`;
export const PersonInfo = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
`;
export const Bluetext = styled.span`
  // color: #0078FA;
`;
export const SubmitBtn = styled.div`
  width: 200px;
  padding: 16px !important;
  border-radius: 16px;
  background: #faf000;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-top: 15px;
    position: fixed;
    bottom: 0px;
  }

  &:hover {
    transform: scale(0.97);
  }
`;
export const FooterCtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;
export const ProductTypeTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TripInformationTitle = styled.div`
  font-weight: 800;
  color: #b43cfa;
  font-size: 12px;
  font-family: Mulish;
`;
export const TravelTripDetailsPara = styled.div``;
export const TripInformationCtn = styled.div`
  display: grid;
  gap: 8px;
  border-radius: 8px;
  opacity: 0px;
  font-family: Mulish;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  margin: 14px;
  font-weight: 500;
`;
export const ProductListCtn = styled.div`
  padding-bottom: 50px;

  @media screen and (max-width: 480px) {
    padding-bottom: 16px; 
  }
`;
export const InquiryFormWarpper = styled.div`
  width: 75%;
  padding: 48px;
  border-radius: 32px;
  border: 2px solid #eeeeee;
  margin: auto !important;
  background: #ffffff;

  @media screen and (max-width: 480px) {
    width: 90%;
    padding: 16px;
    border-radius: 0px;
    border: none;
  }
`;
export const InquiryFormHeaderText = styled.div`
  font-family: Mulish;
  font-size: 15.89px;
  font-weight: 800;
  line-height: 19.43px;
  text-align: left;
  color: #333333;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    font-weight: 800;
    line-height: 20px;
    text-align: left;
  }
`;
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3%;
  margin-top: 28px;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }
`;
export const CaptchaCtn = styled.div`

  @media screen and (max-width: 480px) {
    margin-bottom: 46px;
    width: 100%;
  }
`;
export const GridContainerV2 = styled.div`
  display: grid;
  grid-template-columns: 31% 66%;
  gap: 3%;
  margin-top: 28px !important;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;
export const InquiryFormSubmitBtnCtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px !important;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;
export const InquiryFormSubmitBtn = styled(LargeButton)`
  width: 200px;
  margin: inherit;

  @media screen and (max-width: 480px) {
    width: 100%;
    position: fixed;
    bottom: 0px;
    height: 40px;
  }
`;
export const DropDownList = styled.ul`
  position: absolute;
  top: 80px;
  left: 0;
  background-color: white;
  list-style: none;
  padding: 10px 0;
  margin: 0;
  z-index: 1000;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  cursor: pointer;
  width: calc(100% - 4px);
  border-bottom: 2px solid #eeeeee;
  border-right: 2px solid #eeeeee;
  border-left: 2px solid #eeeeee;
`;
export const DropDownListElement = styled.li`
  padding: 10px 20px;
  color: #333;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  transition: background-color 0.5s ease;
  font-weight: 700;
  font-family: Mulish;
  font-size: 14px;
  line-height: 20px;
  text-align: left;

  &:hover {
    background-color: #faf000;
  }
`;
export const PorductItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const InquirySendWrapper = styled.div`
  padding: 48px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
export const InquirySendText = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
`;
export const InquirySendOk = styled(LargeButton)`
  width: 200px;
`;
export const CaptchaErrorMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1rem;
  letter-spacing: 0.02em;
  color: #dc0000;
`;
