import React, { useState, useEffect } from 'react';
import { SliderContainer, SliderWrapper, Card, ArrowButton, ArrowIcon, Goto } from './CardSlider.style'; 
import { imagesLink as HomepageImage } from '../../containers/Homepage/constants';
import {imagesLink} from '../../constants/imageLink'

const cardData = [
  { id: 2, icon: HomepageImage.autoLogoHorizontal, description: "Bebas melaju setiap hari, kendaraanmu terlindungi maksimal.", source: '/produk-auto' },
  { id: 1, icon: HomepageImage.travelLogoHorizontal, description: "Teman setia saat berpetualangan, aman dan berkesan.", source: '/produk-travel'},
  { id: 6, icon: HomepageImage.warrantyLogoHorizontal, description: "Garansi panjang, kendaraan tetap tenang, selalu terjaga.", source: '/produk-extended-warranty' },
  { id: 4, icon: HomepageImage.careLogoHorizontal, description: "Hidup tenang, karena semua sudah terlindungi setiap saat.", source: '/produk-safe' },
  { id: 3, icon: HomepageImage.deviceLogoHorizontal, description: "Gadget aman membuat aktivitas keseharianmu bebas dari cemas.", source: '/produk-device' },
  { id: 5, icon: HomepageImage.propertyLogoHorizontal, description: "Rumah aman dan nyaman, bebas rasa khawatir kapanpun.", source: '/produk-property' },
  { id: 7, icon: HomepageImage.lainnyaLogo, description: "Solusi perlindungan untuk semua kebutuhan.", source: '/produk-lainnya' },
];

const CardSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 480);
  const itemsToShow = isMobileView ? 1 : 3;

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex + itemsToShow < cardData.length) {
      setCurrentIndex(currentIndex+1);
    }
  };

    useEffect(() => {
      window.addEventListener("resize", () => {
        setIsMobileView(window.innerWidth <= 480);
      });
    },[isMobileView])

  const visibleCards = cardData.slice(currentIndex, currentIndex + itemsToShow);

  return (
    <SliderContainer>
      <ArrowButton direction="left" onClick={handlePrev} disabled={currentIndex === 0}>
        <ArrowIcon src={imagesLink.arrowLeft} alt="Left Arrow" />
      </ArrowButton>

      <SliderWrapper>
        {visibleCards.map((card) => (
          <Card key={card.id}>
            <img src={card.icon} className='card-icon'/>
            <p>{card.description}</p>
            
            <Goto href={card.source}>
                <img src={imagesLink.arrowRight2}/>
            </Goto>
          </Card>
        ))}
      </SliderWrapper>

      <ArrowButton direction="right" onClick={handleNext} disabled={currentIndex + itemsToShow >= cardData.length}>
        <ArrowIcon src={imagesLink.arrowRight} alt="Right Arrow" />
      </ArrowButton>
    </SliderContainer>
  );
};

export default CardSlider;
