import styled from "styled-components";

export const TimelineWrapper = styled.div`
    background: #ffffff;
    width: 100vw;
    @media screen and (max-width: 1024px) {
        border-bottom: 2px solid #EEEEEE;
    }
`
export const TiemlineCtn = styled.div`
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: 30% 65%;
    gap: 5%;

    @media screen and (max-width: 1024px) {
        width: 90%;
    }
`
export const Header = styled.div`
    font-family: Mulish;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
    }
`
export const TimelineStepsWarpper = styled.div`
    display: flex;
    gap: 8px;
    height: 80px;
    align-items: center;
    justify-content: end;

    @media screen and (max-width: 1024px) {
        height: 50px;
    }
`
export const TimelineStepsCtn = styled.div`
    font-family: Mulish;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${props => {
        if(props.curr) {
            return '#333333'
        } else if (props.inComing) {
            return '#cccccc'
        }
    }};
    color: #ffffff;
    font-size: 10px;
    font-weight: 800;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const TimelineStepsBar = styled.div`
    font-family: Mulish;
    width: 32px;
    height: 2px;
    background: ${props => {
        if(props.curr) {
            return '#333333'
        } else if (props.inComing) {
            return '#cccccc'
        }  else if (props.prevStep) {
            return '#B43CFA'
        }
    }};
    color: #ffffff;
    font-size: 10px;
    font-weight: 800;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;

    @media screen and (max-width: 1024px) {
        width: 8px;
    }
`
export const StepsWrapper = styled.div`
    font-family: Mulish;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
`
export const StepsText = styled.div`
    font-family: Mulish;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: ${props => {
        if(props.curr) {
            return '#333333'
        } else if (props.inComing) {
            return '#cccccc'
        }  else if (props.prevStep) {
            return '#B43CFA'
        }
    }};

    @media screen and (max-width: 1024px) {
        display: none;
    }
`