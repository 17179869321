import styled from "styled-components";


export const InsuredCardDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const InsuredCardDataItem = styled.div`
  flex-basis: ${props => props.span === 1 ? '50%' : '100%'};
  padding-bottom: 16px;
  word-break: break-all;
  ${props => props.span === 1 && `
    &:nth-child(even) {
      text-align: right;
    }
  `}

  @media screen and (max-width: 1024px) {
    flex-basis: 100%;
    padding-bottom: 0px;
  }
`
export const InsuredCardDataItemTitle = styled.span`
  font-family: Mulish;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  color: #999999;
  
  @media screen and (max-width: 1024px) {
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: left;
  }
`

export const InsuredCardDataItemContent = styled.div`
  font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #333333;

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      text-align: left;
    }
`
export const BenefitDetailsCtn = styled.div`
    display: flex;
    gap: 12px;
    margin: 10px 0px;

    @media screen and (max-width: 1024px) {
      margin: 8px 0px;
    }
`
export const FlexColumnCtn = styled.div`
    display: flex;
    flex-direction: column;
`