import React, { useMemo } from "react";
import {
  ContentWrapper,
  PaymentTopImageCtn,
  PaymnetSucessFullWarpper,
  DetailsWrapper,
  PolicyNumberCtn,
  DetailsTabCtn,
  DetailsTab,
  DetailsTabValue,
  DetailsTabHeader,
  BottomText,
  PlayStoreIconCtn,
  PageHeader,
  DetailsText,
  PaymentBoldText,
  DocumentBtn,
  MiddleIconCtn2,
  AnchorTag,
  StatusImage,
  Icons,
} from "../elements";
import { ImageUrl } from "../../../Images";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import convertToIDR from "../../../lib/utility/convertToIDR";
import { getWindowDimensions } from "../../../constants/travel";

const PaymentPending = (props) => {
  const navigate = useNavigate()

  const detailCard = useMemo(() => {
    return [
      {
        icon: ImageUrl.CalendarBlue,
        title: "Tanggal Transaksi",
        value: props.data?.transaction_date || '-',
      },
      {
        icon: ImageUrl.NotesBlue,
        title: "Status Transaksi",
        value: props.data?.payment_status || '-',
      },
      {
        icon: ImageUrl.BagBlue,
        title: "Total Premi",
        value: convertToIDR(props.data?.amount || ''),
      },
      {
        icon: ImageUrl.SheildBlue,
        title: "Periode Perlindungan",
        value: props.data?.coverage_date || '-',
      },
    ]
  }, [props.data]);
  
  const refreshPage = () => {
    navigate(0)
  }

  return (
    <PaymnetSucessFullWarpper>
      <PaymentTopImageCtn banner={getWindowDimensions().width >= 720 ? ImageUrl.PayPending : ImageUrl.PaymentPendingMobile} />
      <ContentWrapper>
        <MiddleIconCtn2>
          <StatusImage
            src={ImageUrl.PaymentPending}
            alt="success-icon"
          />
        </MiddleIconCtn2>
        <DetailsWrapper>
          <PageHeader color="#FA7800">Polis Anda Sedang Kami Proses</PageHeader>
          {props.data?.text && props.data?.text[0] && <DetailsText>
            <FormattedMessage
              id={props.data?.text[0]}
              defaultMessage={props.data?.text[0]}
              values={{
                b: (chunks) => <PaymentBoldText>{chunks}</PaymentBoldText>,
                br: <br />,
              }}
            />
          </DetailsText>}
          <DocumentBtn
            id="send-otp-btn"
            loading={false}
            onClick={() => refreshPage()}
            type="secondary"
          >
            Memuat Ulang Halaman
          </DocumentBtn>
          {props.data?.text && props.data?.text[1] && <DetailsText>
            <FormattedMessage
              id={props.data?.text[1]}
              defaultMessage={props.data?.text[1]}
              values={{
                b: (chunks) => <PaymentBoldText>{chunks}</PaymentBoldText>,
                br: <br />,
              }}
            />
          </DetailsText>}
          <PolicyNumberCtn>Nomor Transaksi : {props.data?.transaction_id}</PolicyNumberCtn>
          <DetailsTabCtn>
            {detailCard.map((card) => {
              return (
                <DetailsTab>
                  <img src={card.icon} width="32px" height="32px" alt="icon" />
                  <DetailsTabHeader>{card.title}</DetailsTabHeader>
                  <DetailsTabValue>{card.value}</DetailsTabValue>
                </DetailsTab>
              );
            })}
          </DetailsTabCtn>
          <BottomText>
            Unduh aplikasi TAP Insure untuk melihat detail polis, cek daftar
            manfaat serta melakukan proses pengajuan klaim yang cepat dan mudah
          </BottomText>
          <PlayStoreIconCtn>
            <AnchorTag href={'https://play.google.com/store/apps/details?id=com.pasarpolis.tapinsurance&pcampaignid=web_share'} target="_blank" rel="noreferrer"><Icons src={ImageUrl.GooglePlay} alt='google-play'/></AnchorTag>
            <AnchorTag href={'https://apps.apple.com/id/app/tap-insure/id1629379936'} target="_blank" rel="noreferrer"><Icons src={ImageUrl.AppStore} alt='app-store'/></AnchorTag>
          </PlayStoreIconCtn>
        </DetailsWrapper>
      </ContentWrapper>
    </PaymnetSucessFullWarpper>
  );
};

export default PaymentPending;
