import React, { useEffect, useMemo, useState } from "react";
import { ImageUrl } from "../../../Images";
import {
  BenefiitTitle,
  Benefitdescription,
  BenefitDetailsCtn,
  BenefitDetailSubmitBtn,
  BenefitDetailWrapper,
  FlexColumnCtn,
  GridBox,
  HolderTitle,
} from "../elements";
import Modal from "../../../components/Modal";
import PDFPreview from "../../../components/PDFPreview";
import { BenefitPackageData } from "../../../lib/travel/benefitDetailsCardData";
import { useGetBenefitDoc } from "../../../lib/api";

const BenefitDetails = (props) => {
  const [showModal, setShowModal] = useState(false);
  const {data: benefitDocData, dataLoading, getBenefitDoc = () => {}} = useGetBenefitDoc()
  const [isMobile, setIsMobileView] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobileView(window.innerWidth <= 1024);
    });
  },[isMobile])

  useEffect(() => {
      const currpackage = localStorage.getItem("currPackage");
      currpackage && getBenefitDoc({product_code: 'aus-travel-insurance', package_code: currpackage, data_type: 'BENEFIT_DOC' })
  }, [])

  const userDetails = useMemo(() => {
    return {
      trip_start_from: {
        title: "Dari",
        description: props?.draftData?.payload?.package_request?.trip_start_from,
        iconUrl: ImageUrl.Rocket,
      },
      destinations: {
        title: "Tujuan",
        description: props?.draftData?.payload?.package_request?.destinations,
        iconUrl: ImageUrl.Location,
      },
      trip_start_date: {
        title: "Mulai Perjalanan",
        description: props?.draftData?.payload?.package_request?.trip_start_date,
        iconUrl: ImageUrl.Date,
      },
      trip_end_date: {
        title: "Akhir Perjalanan",
        description: props?.draftData?.payload?.package_request?.trip_end_date,
        iconUrl: ImageUrl.Date,
      },
      trip_type: {
        title: "Tipe Perjalanan",
        description: props?.draftData?.payload?.package_request?.trip_type,
        iconUrl: ImageUrl.Tag,
      },
      product_type: {
        title: "Jenis Perlindungan",
        description: props?.draftData?.payload?.package_request?.product_type,
        iconUrl: ImageUrl.File,
      },
      no_of_insured_persons: {
        title: "Jumlah Tertanggung",
        description: props?.draftData?.payload?.package_request?.no_of_insured_persons,
        iconUrl: ImageUrl.Profile,
      },
      is_schengen: {
        title: "Pengajuan Visa Schengen",
        description: props?.draftData?.payload?.package_request?.is_schengen ? 'Ya' : 'Tidak',
        iconUrl: ImageUrl.Calendar,
      },
    };
  }, [props?.draftData?.payload?.package_request]);

  return (
    <BenefitDetailWrapper>
      {isMobile && <><HolderTitle>Detail Perjalanan</HolderTitle>
      <GridBox>
        {
          Object.keys(userDetails)?.map(data => {
            return <BenefitDetailsCtn>
            <img width="40px" height="40px" src={userDetails[data]?.iconUrl} />
            <FlexColumnCtn>
              <BenefiitTitle>{userDetails[data]?.title}</BenefiitTitle>
              <Benefitdescription>{ userDetails[data]?.title === 'Tujuan' ?  userDetails[data]?.description?.toString() : userDetails[data]?.description}</Benefitdescription>
            </FlexColumnCtn>
          </BenefitDetailsCtn>
          })
        }
      </GridBox>
      </>}
      <HolderTitle>Detail Paket</HolderTitle>
      <GridBox>
        {BenefitPackageData(props.pkgListData, props.draftData?.payload).packageDeatils && BenefitPackageData(props.pkgListData, props.draftData?.payload).packageDeatils?.map((data) => {
          return (
            <BenefitDetailsCtn>
              <img width="40px" height="40px" src={data.icon} />
              <FlexColumnCtn>
                <BenefiitTitle>{data.title}</BenefiitTitle>
                <Benefitdescription>{data.value}</Benefitdescription>
              </FlexColumnCtn>
            </BenefitDetailsCtn>
          );
        })}
      </GridBox>
      <HolderTitle>Daftar Manfaat</HolderTitle>
      <GridBox>
        {BenefitPackageData(props.pkgListData, props.draftData?.payload).benefitDetails && BenefitPackageData(props.pkgListData, props.draftData?.payload).benefitDetails?.map((data) => {
          return (
            <BenefitDetailsCtn>
              <img width="40px" height="40px" src={data.icon} />
              <FlexColumnCtn>
                <BenefiitTitle>{data.title}</BenefiitTitle>
                <Benefitdescription>{data.value}</Benefitdescription>
              </FlexColumnCtn>
            </BenefitDetailsCtn>
          );
        })}
      </GridBox>
      <BenefitDetailSubmitBtn onClick={() => setShowModal(true)}>
        Lihat Daftar Manfaat
      </BenefitDetailSubmitBtn>
      <Modal
        title={"Daftar Manfaat"}
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <div style={{ padding: "20px" }}>
          <PDFPreview src={benefitDocData?.data?.doc_url} />
        </div>
      </Modal>
    </BenefitDetailWrapper>
  );
};

export default BenefitDetails;
