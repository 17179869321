import React, { useEffect, useState } from "react";
import {
  BenefiitTitle,
  Benefitdescription,
  BenefitDetailsCtn,
  BenefitDetailSubmitBtn,
  CardTitle,
  CollapsibleIconBlue,
  FlexColumnCtn,
  GridContainer,
  ReviewOrderDetailCtn,
  ReviewOrderDetailWrapper,
  Title,
} from "../elements";
import PDFPreview from "../../../components/PDFPreview";
import Modal from "../../../components/Modal";
import { useGetBenefitDoc } from "../../../lib/api";

const TravelDetails = ({ benefitData = [], benefitPackageData = {} }) => {
  const [showModal, setShowModal] = useState(false)
  const [detailOpen, setDetailOpen] = useState(true)
  const [paketOpen, setPaketOpen] = useState(true)
  const [manfaatOpen, setManfaatOpen] = useState(true)
  const {data: benefitDocData, dataLoading, getBenefitDoc = () => {}} = useGetBenefitDoc()

  const [isMobile, setIsMobileView] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobileView(window.innerWidth <= 1024);
    });
  },[isMobile])

  useEffect(() => {
      const currpackage = localStorage.getItem("currPackage");
      currpackage && getBenefitDoc({product_code: 'aus-travel-insurance', package_code: currpackage, data_type: 'BENEFIT_DOC' })
  }, [])

  const getDetailComponent = (icon = "", title = "", value = "") => {
    return (
      <BenefitDetailsCtn>
        <img width="40px" height="40px" src={icon} />
        <FlexColumnCtn>
          <BenefiitTitle>{title}</BenefiitTitle>
          <Benefitdescription>{value}</Benefitdescription>
        </FlexColumnCtn>
      </BenefitDetailsCtn>
    );
  };

  return (
    <ReviewOrderDetailCtn>
      <ReviewOrderDetailWrapper>
        <CardTitle onClick={() => isMobile && setDetailOpen(!detailOpen)} open={detailOpen}>
          <Title>Detail Perjalanan</Title>
          {isMobile && <CollapsibleIconBlue className={detailOpen ? 'open' : 'close'}/>}
        </CardTitle>
        {detailOpen && <GridContainer>
          {benefitData.map((data) => {
            const { icon, title, value } = data;
            return <>{getDetailComponent(icon, title, value)}</>;
          })}
        </GridContainer>}
      </ReviewOrderDetailWrapper>
      <ReviewOrderDetailWrapper>
        <CardTitle onClick={() => isMobile && setPaketOpen(!paketOpen)} open={paketOpen}>
          <Title>Detail Paket</Title>
          {isMobile && <CollapsibleIconBlue className={paketOpen ? 'open' : 'close'}/>}
        </CardTitle>
        {paketOpen && <GridContainer>
          {benefitPackageData?.packageDeatils?.map((data) => {
            const { icon, title, value } = data;
            return <>{getDetailComponent(icon, title, value)}</>;
          })}
        </GridContainer>}
      </ReviewOrderDetailWrapper>
      <ReviewOrderDetailWrapper>
        <CardTitle onClick={() => isMobile && setManfaatOpen(!manfaatOpen)} open={manfaatOpen}>
          <Title>Daftar Manfaat</Title>
          {isMobile && <CollapsibleIconBlue className={manfaatOpen ? 'open' : 'close'}/>}
        </CardTitle>
        {manfaatOpen && <><GridContainer>
          {benefitPackageData?.benefitDetails?.map((data) => {
            const { icon, title, value } = data;
            return <>{getDetailComponent(icon, title, value)}</>;
          })}
        </GridContainer>
        <BenefitDetailSubmitBtn onClick={() => setShowModal(true)}>
          Lihat Daftar Manfaat
        </BenefitDetailSubmitBtn></>}
      </ReviewOrderDetailWrapper>
      <Modal
        title={"Daftar Manfaat"}
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <div style={ isMobile ? {padding: "16px"} : {padding: '20px'} }>
          <PDFPreview src={benefitDocData?.data?.doc_url} />
        </div>
      </Modal>
    </ReviewOrderDetailCtn>
  );
};

export default TravelDetails;
