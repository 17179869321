import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#ffffff")};

  & .filled {
    top: -9px;
    font-size: 10px;
  }
`;
export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
`
export const InputText = styled.input`
  border: none;
  font-size: 14px;
  font-family: Mulish;
  line-height: 15px;
  font-weight: 600;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#ffffff")};
  padding: ${(props) =>
    props.value
      ? props.showLabel
        ? "20px 10px 10px 10px"
        : "15px 10px 15px 10px"
      : "15px 10px 15px 10px"};
  outline: none;
  cursor: ${({ isFieldDisabled, disabled }) => (isFieldDisabled ? 'not-allowed' : disabled ? 'not-allowed' : 'pointer')};

  :disabled {
    ${(props) =>
      props.isEmpty &&
      `
      -webkit-text-fill-color: #f5f5f5f;
    `}
    ${(props) =>
      !props.isEmpty &&
      `
      -webkit-text-fill-color: #f5f5f5;
      opacity: 1;
    `}
  }

  ${(props) =>
    props.isEmpty
      ? `
    -webkit-text-fill-color : #f5f5f5f;
    `
      : ``}

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }
`;
export const TextLabel = styled.label`
  position: absolute;
  pointer-events: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding: ${(props) =>
    props.value ? "16px 10px 16px 10px" : "16px 10px 16px 10px"};
  color: #999999;
  font-size: 13px;
  font-family: Mulish;
  font-weight: 700;
  width: 100%;
  line-height: 1;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
  }
`;
export const FormCtn = styled.div`
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#ffffff")};
`;
export const ImfoCtn = styled.div`
  display: grid;
  grid-template-columns: 8% 91%;
  gap: 1%;
  align-items: center;
  margin-top: 5px;
`;
export const InfoText = styled.div`
  font-size: 12px;
  font-family: Mulish;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #b43cfa;
`;
export const SearchImageContainer = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  align-items; center;
  cursor: pointer;
`;
export const SearchImageContainer2 = styled.div`
  height: 24px;
  width: 28px;
  display: flex;
  align-items; center;
  padding-left: 10px;
  cursor: pointer;
`;
export const TextWrapper = styled.div`
  max-width: 100%;
  padding: 5px !important;
  border-radius: 16px;
  border: ${(props) =>
    props.error ? "2px solid #DC0000" : "2px solid #eeeeee"};
  display: grid;
  grid-template-columns: calc(100% - 40px) 30px;
  align-items: center;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#FFFFFF")};
  min-height: 47px;
`;

export const PromptError = styled.div`
  width: 100%;
  margin: 8px 0px;
  display: flex;
  gap: 1%;
`;
export const PromptErrorMsg = styled.div`
  display: inline;
  margin-left: 4px;
  font-weight: 700;
  font-size: 0.7rem;
  font-family: Mulish;
  line-height: 1rem;
  letter-spacing: 0.02em;
  color: #dc0000;
`;
export const InputTextCtn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer2 = styled.div`
  position: relative;
  display: flex;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#ffffff")};
`;
export const InputText2 = styled.input`
  border: none;
  font-size: 14px;
  font-family: Mulish;
  font-weight: 600;
  line-height: 15px;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#ffffff")};
  padding: 15px 10px 15px 10px;
  outline: none;
  cursor: pointer;
  width: -webkit-fill-available;

  :disabled {
    ${(props) =>
      props.isEmpty &&
      `
      -webkit-text-fill-color: #f5f5f5f;
    `}
    ${(props) =>
      !props.isEmpty &&
      `
      -webkit-text-fill-color: #f5f5f5;
      opacity: 1;
    `}
  }

  ${(props) =>
    props.isEmpty
      ? `
    -webkit-text-fill-color : #f5f5f5f;
    `
      : ``}
`;
export const PrefixCtn = styled.input`
  border: none;
  width: 30px;
  font-size: 14px;
  font-family: Mulish;
  line-height: 1;
  padding: 15px 0px 15px 10px;
  font-weight: 600;
  background: ${(props) => (props.prefixBg ? props.prefixBg : "#ffffff")};
`;
export const PrefixCtn2 = styled.input`
  border: none;
  width: 30px;
  font-size: 14px;
  font-family: Mulish;
  line-height: 1;
  padding: 13px 0px 15px 10px;
  font-weight: 600;
  background: ${(props) => (props.prefixBg ? props.prefixBg : "#ffffff")};
`;
export const TextWrapper2 = styled.div`
  max-width: 100%;
  padding: 5px;
  border-radius: 16px;
  border: ${(props) =>
    props.error ? "2px solid #DC0000" : "2px solid #eeeeee"};
  display: grid;
  grid-template-columns: calc(100% - 45px) 45px;
  align-items: center;
  background: ${(props) => (props.disabled ? "#EEEEEE" : "#FFFFFF")};
  min-height: 47px;
`;
