import styled from 'styled-components'
import { ImageUrl } from '../../Images'

export const CollapsibleContainer = styled.div`
  background-color: #ffffff;
  padding-bottom: 16px;
  border-radius: ${props => props.style.borderRadius ? props.style.borderRadius : '0px'};
  border: ${props => props.style.haveBorder ? '2px solid #EEEEEE' : '0px'};
  display: flex;
  position: relative;
  flex-direction: column;
`

export const CollapsibleHeader = styled.div`
  padding: ${props => props.style.padding ? props.style.padding : '16px 0px 0px 16px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CollapsibleContent = styled.div`
  transition: all .5s ease-in-out;
  overflow: hidden;
`

export const CollapsibleChildren = styled.div`
  padding: ${props => props.style.childPadding ? props.style.childPadding : '0px'};
`

export const CollapsibleTitle = styled.div`
  font-family: 'Mulish';
  font-weight: 700;
  font-size: ${props => props.style.fontSize ? props.style.fontSize : '1.4rem'};
  line-height: 1.3;
  width: 96%;
`

export const CollapsibleIcon = styled.img`
  transition: all 0.3s ease-in-out;
  position: absolute;
  bottom: -12px;
  left: 48%;

  &.close {
    transform: rotate(0deg);
  }

  &.open {
    transform: rotate(-180deg);
  }
`
export const CollapsibleIconBlue = styled.img.attrs({
  src: ImageUrl.UpArrow,
  alt: 'collapsible arrow up',
  width: '16px',
  height: '16px'
})`
  transition: all 0.3s ease-in-out;

  &.close {
    transform: rotate(-180deg);
  }

  &.open {
    transform: rotate(0deg);
  }
`
