import styled from 'styled-components';

// Container for the whole slider
export const SliderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  overflow: hidden;
  margin-bottom: 100px;
  margin-top: 24px;

  @media only screen and (max-width: 480px) {
    width: 100%;
    gap: 8px;
    margin-bottom: 16px;
  }
`;

// Wrapper for the cards
export const SliderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 80%;

  img {
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    width: 85%;
  }
`;

// Individual Card Style
export const Card = styled.div`
  padding: 20px;
  border-left: 2px solid #FAFC00;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;

  p {
    font-size: 16px;
    color: #555;
  }
  
  .card-icon {
    height: 40px;
    align-self: flex-start; 

    @media only screen and (max-width: 480px) {
      height: 32px;
    }
  }
`;

// Arrow Button for navigating between slides
export const ArrowButton = styled.button`
  background-color: #F5F5F5;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  ${({ direction }) => (direction === 'left' ? 'left: 10px;' : 'right: 10px;')}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 2;
  transition: background-color 0.5s ease, transform 0.5s ease;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

&:hover {
    background-color: #E0E0E0;
    transform: scale(1.1); 
  }
`;

export const ArrowIcon = styled.img`
  width: 24px; 
  height: 24px;
`;

export const Goto = styled.div`
    width: 48px;
    height: 48px;
    background-color: #FAFC00;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.5s ease;

    img {
        margin-top: 24px;
    }

    &:hover {
        background-color: #E5E700;
    }
`
