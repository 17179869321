import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  TravelFormsWarpper,
  TravelFormCtn,
  TravelInsuredDetailsCtn,
  SecondHeaderText,
  HeaderText,
  BounceLoaderCtn,
  FormHeader,
  HolderTitle1,
  InsuredFormContainer,
  GroupInsuredFormCtn,
  FooterRightCtn,
} from "./elements";
import TravelHeader from "../../components/TravelHeader";
import Timeline from "../../components/TravelTimeline";
import TravelFooter from "../../components/TravelFooter";
import {
  useEvaluateProduct,
  useGetDraftData,
  useUpdateDraft,
  useValidateDraft,
} from "../../lib/api";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import HolderForm from "./components/HolderForm";
import InsuredForm from "./components/InsuredForm";
import GroupInsuredForm from "./components/GroupInsuredForm";
import BenefitDetails from "./components/BenefitDetails";
import { set } from "lodash";
import extractPhoneNo from "../../lib/utility/extractPhoneNo";
import convertToIDR from "../../lib/utility/convertToIDR";
import cogoToast from "cogo-toast";
import { BounceLoader } from "react-spinners";
import { FormattedMessage } from "react-intl";
import PackageDetailsDropdown from "./components/PackageDetailDropdown";
import { ImageUrl } from "../../Images";
import LargeButton from "../../components/LargeButton";

const INIT_PAYLOAD = {
  holder: {
    state: "0",
  },
  insuredPerson: [],
};

const TravelFormsMobile = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [payload, setPayload] = useState(INIT_PAYLOAD);
  const [packageParams, setPackageParams] = useState(null);
  const [submitForm, setSubmitForm] = useState(false);
  const [holderData, setHolderData] = useState({});
  const [submitArray, setSubmitArray] = useState([]);
  const [hasSpouse, setHasSpouse] = useState(false);
  const [childCounter, setChildCounter] = useState(0);
  const [childArray, setChildArray] = useState([]);
  const [holderGender, setHolderGender] = useState("");
  const [spouseIdx, setSpouseIdx] = useState(null);
  const [currForm, setCurrForm] = useState(0);
  const { data, dataLoading, getTravelDraftData } = useGetDraftData();
  const { data: packageListData = {}, getPackageList = () => {} } =
    useEvaluateProduct();
  const [seletcedPkg, setSelectedPkg] = useState({});
  const numberOfPersons = data?.payload?.package_request?.no_of_insured_persons;
  const [isMobile, setIsMobileView] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobileView(window.innerWidth <= 1024);
    });
  },[isMobile])

  const totalPrem = data?.payload?.total_premium || 0;

  useEffect(() => {
    const array = data?.payload
      ? new Array(numberOfPersons).fill(false)
      : [false];
    setSubmitArray(array);
  }, [data?.payload]);

  useEffect(() => {
    if (data?.payload?.package_request) {
      getPackageList("aus-travel-insurance", {
        ...data?.payload?.package_request,
        request_type: "web",
      });
    }
  }, [data.payload]);

  useEffect(() => {
    if (data?.payload && packageListData?.data) {
      const packageId = data?.payload?.package_request?.package_id;
      const packageDetails = packageListData?.data?.packages.find(
        (pkg) => pkg.package_id === packageId || pkg.package_code === packageId
      );
      setSelectedPkg(packageDetails);
    }
  }, [packageListData, data?.payload]);

  useEffect(() => {
    searchParams.get("draft_id") &&
      getTravelDraftData(searchParams.get("draft_id"));
  }, [searchParams.get("draft_id")]);

  useEffect(() => {
    if (!data?.payload) return;
    setPackageParams(data?.payload?.package_request);
    const memberCount = numberOfPersons - 1;
    let insuredPersonList =
      data?.payload.insured_data || Array.from({ length: memberCount });
    insuredPersonList = insuredPersonList.map((insured) => {
      return {
        ...insured,
      };
    });
    let holderValues = { ...data?.payload };
    delete holderValues["insured_data"];
    setPayload({
      holder: { state: '0', ...holderValues },
      insuredPerson: insuredPersonList,
    });
  }, [data?.payload]);

  const isFamilyPackage = useMemo(() => {
    return packageParams?.product_type === "family-A" ||
      packageParams?.product_type === "family-B"
      ? true
      : false;
  }, [packageParams]);

  const isInternationalTravel = useMemo(() => {
    return packageParams?.trip_type === "international";
  }, [packageParams]);

  const { updateDraft, isLoading: updateDraftLoading } = useUpdateDraft({
    draftId: searchParams.get("draft_id"),
    step: 2,
    onSuccess: () => {
      if (currForm + 1 < numberOfPersons) {
        setCurrForm(prev => prev + 1);
        window.scrollTo(0, 0);
      } else {
        validateDraft();
      }
      getTravelDraftData(searchParams.get("draft_id"));
    },
    onFailure: (e) => {
      console.error(e);
    },
  });

  const { validateDraft, isLoading: validateDraftLoading } = useValidateDraft({
    productCode: "aus-travel-insurance",
    draftId: searchParams.get("draft_id"),
    onSuccess: () => {
      navigate({
        pathname: "/produk-travel-sales-flow/review-order",
        search: createSearchParams({
          draft_id: searchParams.get("draft_id"),
        }).toString(),
      });
    },
    onFailure: (e) => {
      cogoToast.error(
        e?.response?.data?.error_details?.errors_v2?.message.id ||
          e?.response?.data["validation_error"][0] ||
          "Harap validasi formulir",
        { position: "top-right" }
      );
    },
  });

  useEffect(() => {
    setSubmitForm(false);
  }, [...submitArray, submitForm]);


  const onFormSubmit = useCallback((path, values, state) => {
    let newPayload = { ...payload }
    newPayload = set(newPayload, path, { ...values, state })

    if (isInternationalTravel) {
      delete newPayload.holder['ktp']
      newPayload.insuredPerson = newPayload.insuredPerson.map(insuredPerson => {
        delete insuredPerson['ktp']
        return insuredPerson
      })
    } else {
      delete newPayload.holder['passport_no']
      newPayload.insuredPerson = newPayload.insuredPerson.map(insuredPerson => {
        if (insuredPerson.relationship === 'child' && isFamilyPackage) {
          insuredPerson.ktp = ''
          insuredPerson.kitas_kitap = ''
        }
        delete insuredPerson['passport_no']
        return insuredPerson
      })
    }
    const updateDraftPayload = {
      product_code: 'aus-travel-insurance',
      package_code: packageParams?.package_id,
      step: data?.step,
      data: {
        ...newPayload.holder,
        insured_data: newPayload?.insuredPerson,
        package_request: {
          ...data?.payload.package_request,
        },
        package_response: {
          ...seletcedPkg
        },
        premium: data?.payload?.package_response?.premium,
        coupon_data: {},
        phone_no_authenticated: "false",
      }
    }
    if(newPayload.holder.phone_no) {
      updateDraftPayload.data.phone_no = '62-' + extractPhoneNo(newPayload.holder.phone_no)
    }
    if(newPayload.insuredPerson.length) {
      newPayload.insuredPerson.map((person, idx) => {
        if(person.phone_no) {
          updateDraftPayload.data.insured_data[idx].phone_no = '62-' + extractPhoneNo(newPayload.insuredPerson[idx].phone_no)
        }
      })
    }
    setPayload(newPayload)
    updateDraft(updateDraftPayload);
    setSubmitForm(false);
  }, [payload, data, isInternationalTravel])

  const onSubmit = () => {
    setSubmitForm(true);
  };

  const _getFooterRightContent = () => {
    return <FooterRightCtn>
         {currForm ? <img onClick={() => currForm && setCurrForm(prev => prev - 1)} src={ImageUrl.BackBtnPurple} width='32px' height='32px' alt='back'/> : null}
         <LargeButton
            id="validate-call-btn"
            loading={updateDraftLoading || validateDraftLoading || dataLoading}
            onClick={onSubmit}
            type="secondary"
          >
            {currForm + 1 === numberOfPersons ? 'Konfirmasi' : 'Selanjutnya'}
          </LargeButton>
    </FooterRightCtn>
  }

  return (
    <TravelFormsWarpper>
      <TravelHeader />
      <Timeline header="Isi Data" activeStep={3} />
      <PackageDetailsDropdown data={data} packageListData={packageListData} />
      <HeaderText>
        <FormattedMessage
          id="Lengkapi Data & Dokumen Anda"
          defaultMessage="Lengkapi Data & Dokumen Anda"
        />
      </HeaderText>
      <SecondHeaderText>
        <FormattedMessage
          id=" Semua informasi dibawah diperlukan untuk pembuatan polis dan proses pengajuan klaim"
          defaultMessage="Semua informasi dibawah diperlukan untuk pembuatan polis dan proses pengajuan klaim"
        />
      </SecondHeaderText>
      <TravelFormCtn>
        {data.payload ? (
          <TravelInsuredDetailsCtn>
            <div>
              <FormHeader>
                <HolderTitle1>Wisatawan {currForm + 1}</HolderTitle1>
                <HolderTitle1>{currForm + 1} / {numberOfPersons}</HolderTitle1>
              </FormHeader>
              <div>
              {currForm === 0 && <HolderForm
                payload={payload}
                isInternationalTravel={isInternationalTravel}
                isFamilyPackage={isFamilyPackage}
                onFormSubmit={onFormSubmit}
                submitForm={submitForm}
                setHolderData={setHolderData}
                setSubmitArray={setSubmitArray}
                submitArray={submitArray}
                draftPayload={data?.payload ? data?.payload : {}}
                setSubmitForm={setSubmitForm}
                setHolderGender={setHolderGender}
                currIdx={currForm}
                isMobile={isMobile}
              />}
              {payload.insuredPerson.length > 0 &&
                payload.insuredPerson.map((insured, idx) => {
                  return isFamilyPackage ? (
                    <InsuredFormContainer isShow={currForm - 1 === idx}>
                      <InsuredForm
                        insuredIdx={idx}
                        payload={payload}
                        isFamilyPackage={isFamilyPackage}
                        isInternationalTravel={isInternationalTravel}
                        onFormSubmit={onFormSubmit}
                        submitForm={submitForm}
                        setSubmitArray={setSubmitArray}
                        submitArray={submitArray}
                        setSubmitForm={setSubmitForm}
                        setHasSpouse={setHasSpouse}
                        hasSpouse={hasSpouse}
                        holderGender={holderGender}
                        setSpouseIdx={setSpouseIdx}
                        spouseIdx={spouseIdx}
                        setChildCounter={setChildCounter}
                        childCounter={childCounter}
                        setChildArray={setChildArray}
                        childArray={childArray}
                        currForm={currForm}
                        isMobile={isMobile}
                      />
                    </InsuredFormContainer>
                  ) : (
                    <GroupInsuredFormCtn isShow={currForm - 1 === idx}>
                      <GroupInsuredForm
                        insuredIdx={idx}
                        payload={payload}
                        isFamilyPackage={isFamilyPackage}
                        isInternationalTravel={isInternationalTravel}
                        onFormSubmit={onFormSubmit}
                        submitForm={submitForm}
                        setSubmitArray={setSubmitArray}
                        submitArray={submitArray}
                        setSubmitForm={setSubmitForm}
                        currForm={currForm}
                        isMobile={isMobile}
                      />
                    </GroupInsuredFormCtn>
                  );
                })}
              </div>
            </div>
            {!isMobile ? (
              <BenefitDetails draftData={data} pkgListData={packageListData} />
            ) : null}
          </TravelInsuredDetailsCtn>
        ) : (
          <BounceLoaderCtn>
            <BounceLoader color="#b33cfa" size={90} />
          </BounceLoaderCtn>
        )}
      </TravelFormCtn>
      <TravelFooter
        onSubmit={onSubmit}
        btnText="Konfirmasi"
        premium={convertToIDR(totalPrem || 0)}
        rightContent={_getFooterRightContent}
        loading={updateDraftLoading || validateDraftLoading || dataLoading}
        showSubmitBtn={false}
      />
    </TravelFormsWarpper>
  );
};

export default TravelFormsMobile;
