import React, { useEffect, useState } from "react";
import { BounceLoaderCtn, CardBtnCtn, CardDescriptionCtn, CardDetailsCtn, CardImageCtn, CardSelectBtn, CardText, DocumentBtn, IconCtn, PackageDetailBox, PackageDetailsCTN, PkgCardctn, PkgCardTop, PkgDeatilCardCtn, ScrollItem, ScrollContainer, CardImage, ScrollBar, ScrollThumb, DaftarWrapper } from '../elements'
import BounceLoader from 'react-spinners/BounceLoader';
import Modal from "../../../components/Modal";
import PDFPreview from "../../../components/PDFPreview";
import { useGetBenefitDoc } from "../../../lib/api";
import convertToIDR from "../../../lib/utility/convertToIDR";
import { ImageUrl } from "../../../Images";
import CustomSlider from "../../../components/CustomSlider";

const PackageDetailCardsMobile = (props) => {
    const [certificateModal, setCertificateModal] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState({})
    const {data: benefitDocData, dataLoading, getBenefitDoc = () => {}} = useGetBenefitDoc()

    useEffect(() => {
        const apiPkgCode = (props.packageListData && props.packageListData?.data?.packages) ? props.packageListData?.data?.packages[0].package_code : ''
        if (apiPkgCode) {
            localStorage.setItem('currPackage', apiPkgCode);
            apiPkgCode && getBenefitDoc({product_code: 'aus-travel-insurance', package_code: apiPkgCode, data_type: 'BENEFIT_DOC' })
        }
    }, [props.packageListData])

    useEffect(() => {
        setSelectedPackage({})
    }, [props.packageListData])

    const handleLocalStorage = (pack) => {
        pack?.package_code && localStorage.setItem('currPackage', pack.package_code)
    }

    return (
        dataLoading ? <BounceLoaderCtn>
            <BounceLoader color="#b33cfa" size={90} />
            </BounceLoaderCtn> :  <>
        <PackageDetailBox>
        <PackageDetailsCTN>

            <CustomSlider slidesLength={props.packageListData?.data?.packages?.length} centerMode={true} barColor='#B43CFA' barHeight='4px' infinite={false}>
                {
                    props.packageListData && props.packageListData?.data?.packages &&  props.packageListData?.data?.packages.map((pack, idx) => {
                        return <PkgDeatilCardCtn idx={idx}>
                                <PkgCardctn>
                                    <PkgCardTop bgColor={pack.color_code}>
                                        <CardImageCtn><CardImage src={pack.icon}alt='edit-icon'/></CardImageCtn>
                                        <CardText cardStyle={{fontSize:'18px', fontWgt:'600', fonthgt:'22px', color:'#FFFFFF', fontSizeMob: '12px', fontWgtMob: '600', fonthgtMob: '16px'}}>{pack.package_name}</CardText>
                                        <CardText cardStyle={{fontSize:'22px', fontWgt:'800', fonthgt:'22px', color:'#FFFFFF', fontSizeMob: '16px', fontWgtMob: '800', fonthgtMob: '20px'}}>{convertToIDR(pack.total_premium)}</CardText>
                                        <CardText cardStyle={{fontSize:'12px', fontWgt:'600', fonthgt:'18px', color:'#FFFFFF', fontSizeMob: '10px', fontWgtMob: '600', fonthgtMob: '14px'}}>untuk {props.userDetails.no_of_insured_persons.description} wisatawan</CardText>
                                    </PkgCardTop>
                                </PkgCardctn>
                                <CardDetailsCtn>
                                    {
                                        props.packageListData && props.packageListData?.data?.package_benefits && Object.keys(props.packageListData?.data?.package_benefits.filter(id => pack.package_code === Object.keys(id)[0])[0][pack.package_code]).map(card => {
                                            const pkgBenefitData = props.packageListData?.data?.package_benefits.filter(id => pack.package_code === Object.keys(id)[0])[0][pack.package_code][card]
                                            const {icon = '', title = '', values = {}} = pkgBenefitData
                                            return (
                                                <CardDescriptionCtn>
                                                    <IconCtn><img src={icon} width="40px" height="40px" alt='icon'/></IconCtn>
                                                    <CardText cardStyle={{fontSize:'13px', fontWgt:'600', fonthgt:'18px', color:'#999999', fontSizeMob: '10px', fontWgtMob: '600', fonthgtMob: '14px'}}>{title}</CardText>
                                                    <CardText cardStyle={{fontSize:'10px', fontWgt:'600', fonthgt:'14px', color:'#333333', fontSizeMob: '10px', fontWgtMob: '600', fonthgtMob: '14px'}}>{values.title}</CardText>
                                                    <CardText cardStyle={{fontSize:'16px', fontWgt:'700', fonthgt:'20px', color:'#333333', fontSizeMob: '12px', fontWgtMob: '700', fonthgtMob: '16px'}}>{convertToIDR(values.amount)}</CardText>
                                                </CardDescriptionCtn>
                                            )
                                        })
                                    }
                                </CardDetailsCtn>
                                <CardBtnCtn>
                                    <CardSelectBtn
                                        color={pack.color_code}
                                        bgColor={selectedPackage.package_code === pack.package_code ? pack.color_code : '#FFFFFF'}
                                        onClick={() => {
                                            setSelectedPackage(pack)
                                            props.setCurrSelectedPkg(pack)
                                            handleLocalStorage(pack)
                                        }}
                                        selected={selectedPackage.package_code === pack.package_code}
                                    >
                                    {selectedPackage.package_code === pack.package_code ? <img src={ImageUrl.WhiteCheck} width='16px' height='16px' alt='selected' /> : 'Pilih'}
                                </CardSelectBtn>
                                </CardBtnCtn>
                            </PkgDeatilCardCtn>
                    })
                }
                </CustomSlider>
        </PackageDetailsCTN>
        {benefitDocData?.data?.doc_url ? <DocumentBtn onClick={() => setCertificateModal(true)}>Lihat Semua Manfaat</DocumentBtn> : null}
       </PackageDetailBox>
       <Modal
            title={"Daftar Manfaat"}
            show={certificateModal}
            onClose={() => setCertificateModal(false)}
        >
            <DaftarWrapper>
                <PDFPreview src={benefitDocData?.data?.doc_url} />
            </DaftarWrapper>
        </Modal>
       </>
    )
}

export default PackageDetailCardsMobile