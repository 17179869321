import styled from 'styled-components'

export const FinanceReportContainer = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
        padding: 0;
        margin: 0;
    }

    .content {
        max-width: 1400px;
        width: 100%;
        min-height: 70vh;
    }

    h1 {
        font-size: 64px;
        max-width: 448px;
        word-break: break-word;
        margin-left: 60px;
    }

    .content-inner {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;
        margin-top: 64px;
        margin-bottom: 100px;
    }

    .year-selector {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .year-selector p {
        font-size: 56px;
    }

    .year-selector img {
        width: 14px;
    }

    .finance-report-month-container {
        width: 70%;
    }

    .finance-report-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        border-bottom: 2px solid #FAFC00;
    }

    .finance-report-items p {
        font-size: 16px;
        font-weight: 700;
    }

    .btn-download {
        border: none;
        background-color: #FAFC00;
        padding: 16px 24px;
        border-radius: 24px;
        cursor: pointer;
        transition: background-color 0.5s ease;
        font-weight: 700;

        &:hover {
            background-color: #E5E700;
        }
    }

    .flex-btn{
      display: flex;   
    }

    .btn-circle {
        box-shadow: none;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        background-color: #FAFC00;
        transition: background-color 0.5s ease;
        color: #FFF;
        width: 47px;
        height: 47px;

        &:hover {
            background-color: #E5E700;
        }
    }

    @media (max-width: 480px) {

        h1{
            font-size: 24px;
            font-weight: 800;
            line-height: 32px;
            text-align: left;
            margin-left: 0px;
        }
        .content-inner{
            flex-direction: column; 
        }
        .finance-report-month-container {
            width: 100%;
        }
        .flex-btn{
            width: 90%;
            display: flex;
            justify-content: end;
        }
        .finance-report-items {
            padding: 16px;
        }

        .btn-download {
            padding: 10px 20px;
        }

        .content-inner {
            margin-top: 32px;
        }

        .btn-download {
            border-radius: 12px;
        }

        .btn-circle {
            width: 35px;
            height: 35px;
        }

        .finance-report-items p {
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
        }
    }
`