import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  CaptchaCtn,
  CaptchaErrorMsg,
  DropDownList,
  DropDownListElement,
  FormItem,
  GridContainer,
  GridContainerV2,
  InquiryFormHeaderText,
  InquiryFormSubmitBtn,
  InquiryFormSubmitBtnCtn,
  InquiryFormWarpper,
  PorductItem,
  Title,
} from "./elements";
import { FormattedMessage } from "react-intl";
import FormHook from "../../components/FormComponents/FormHook";
import { Formik } from "formik";
import TextInputV3 from "../../components/FormComponents/InputTextV3";
import TextInput from "../../components/FormComponents/InputText";
import InquirySend from "./inquirySend";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "../../components/Modal";
import cogoToast from "cogo-toast";
import { useInquiryApi } from "../../lib/api";
import extractPhoneNo from "../../lib/utility/extractPhoneNo";

const siteKey = process.env.REACT_APP_SITE_KEY;

const INTIIAL_VALUES = {
  name: "",
  phone: "",
  email: "",
  product: "",
  description: "",
};

const FORM_SCHEMA = {
  name: Yup.string()
    .required("Nama tidak boleh kosong")
    .max(100, "Nama maksimal 100 karakter")
    .nullable(),
  phone: Yup.string()
    .required("Nomor Ponsel tidak boleh kosong")
    .min(10, "Nomor minimal 10 karakter")
    .max(20, "Nomor maksimal 20 karakter")
    .nullable(),
  email: Yup.string()
    .required("Email tidak boleh kosong")
    .max(100, "Email maksimal 100 karakter")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Masukkan Email yang valid")
    .nullable(),
  product: Yup.string()
    .required("Pilih jenis produk yang diinginkan")
    .nullable(),
  description: Yup.string().required("Deskripsi tidak boleh kosong").nullable(),
};

const productKey = [
  "Asuransi Auto",
  "Asuransi Travel",
  "Asuransi Extended Warranty",
  "Asuransi Safe",
  "Asuransi Device",
  "Asuransi Property",
  "Asuransi Shipping",
  "Asuransi Liability",
  "Asuransi Heavy Equipment",
];

const InquiryForm = (props) => {
  const [formValues, setFormValues] = useState(INTIIAL_VALUES);
  const [formSchema, setFormSchema] = useState(FORM_SCHEMA);
  const [productDropdown, setProductDropdown] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const formRef = useRef();
  const recaptcha = useRef();

  useEffect(() => {
    setFormValues({
      ...formValues,
      product: props.product,
    });
  }, [props]);

  const handleChange = (values) => {
    setFormValues(values);
  };

  const dummySubmit = (_values, _formikbag) => {
    // dummy handle submit func
  };

  const { inquiryApiCall, isLoading: zohoApiLoading } = useInquiryApi({
    onSuccess: () => {
      setShowModal(true);
      setFormValues({
        ...INTIIAL_VALUES,
        product: props.product ? props.product : '',
      });
      recaptcha.current.forceReset();
    },
    onFailure: () => {
      cogoToast.error("Error", { position: "top-right" });
    },
  });

  const handleSubmit = (formProps) => {
    const captchaValue = recaptcha.current.getValue();
    formProps.submitForm().then(() => {
      Yup.object(formSchema)
        .validate(formProps.values)
        .catch((err) => {
          console.error(err);
        })
        .then((values) => {
          if (!captchaValue) {
            setCaptchaError(true);
          } else {
            if (values) {
              const payload = {
                data: {
                  response_token: captchaValue,
                  form_data: {
                    ...values,
                    phone: extractPhoneNo(values.phone),
                  },
                },
              };
              inquiryApiCall(payload);
            }
          }
        });
    });
  };

  const handleChangeCaptcha = () => {
    const captchaValue = recaptcha.current.getValue();
    if (captchaValue) {
      setCaptchaError(false);
    } else {
      setCaptchaError(true);
    }
  };

  const handProductSelect = (val) => {
    setFormValues({
      ...formValues,
      product: val,
    });
    setProductDropdown(false);
  };

  return (
    <InquiryFormWarpper>
      <InquiryFormHeaderText>
        <FormattedMessage
          id="Untuk mengetahui lebih lanjut tentang produk asuransi TAP Insure, silakan isi form di bawah untuk terhubung dengan tim marketing kami."
          defaultMessage="Untuk mengetahui lebih lanjut tentang produk asuransi TAP Insure, silakan isi form di bawah untuk terhubung dengan tim marketing kami."
        />
      </InquiryFormHeaderText>

      <Formik
        onSubmit={dummySubmit}
        enableReinitialize
        initialValues={formValues}
        validationSchema={Yup.object(formSchema)}
        innerRef={formRef}
      >
        {(formProps) => (
          <>
            <FormHook values={formProps.values} onChange={handleChange} />
            <GridContainer>
              <FormItem>
                <Title>Nama Lengkap</Title>
                <TextInputV3
                  name="name"
                  label="Nama Lengkap"
                  required
                  showIcon={false}
                  readonly={false}
                />
              </FormItem>
              <FormItem>
                <Title>Nomor Ponsel</Title>
                <TextInputV3
                  name="phone"
                  label="87811223344"
                  type="number"
                  required
                  showIcon
                  readonly={false}
                  prefix="+62"
                  icontype="phone"
                />
              </FormItem>
              <FormItem>
                <Title>Email</Title>
                <TextInputV3
                  name="email"
                  type="email"
                  label="Masukkan email"
                  required
                  showIcon
                  readonly={false}
                  icontype="sms"
                />
              </FormItem>
            </GridContainer>
            <GridContainerV2>
              <PorductItem 
                  onClick={() => !props.product && setProductDropdown(!productDropdown)}>
                <Title>Jenis Produk</Title>
                <TextInput
                  name="product"
                  label=""
                  placeholder="Pilih produk asuransi"
                  showLabel={false}
                  inputValue={formProps?.values?.product || ""}
                  icontype="text"
                  isFieldDisabled={props.product && true}
                />
                {productDropdown && (
                  <DropDownList>
                    {productKey.map((productName) => {
                      return (
                        <DropDownListElement
                          onClick={() => handProductSelect(productName)}
                        >
                          {productName}
                        </DropDownListElement>
                      );
                    })}
                  </DropDownList>
                )}
              </PorductItem>
              <FormItem>
                <Title>Deskripsi Kebutuhan</Title>
                <TextInputV3
                  name="description"
                  label="Jelaskan dengan singkat kebutuhan Anda"
                  required
                  showIcon={false}
                  readonly={false}
                />
              </FormItem>
            </GridContainerV2>
            <InquiryFormSubmitBtnCtn>
              <CaptchaCtn>
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={siteKey}
                  onChange={handleChangeCaptcha}
                  hl='id'
                />
                {captchaError && (
                  <CaptchaErrorMsg>
                    <FormattedMessage
                      id="Captcha tidak boleh kosong"
                      defaultMessage="Captcha tidak boleh kosong"
                    />
                  </CaptchaErrorMsg>
                )}
              </CaptchaCtn>
              <InquiryFormSubmitBtn
                onClick={() => handleSubmit(formProps)}
                id="travel-trip-detail-btn"
                loading={zohoApiLoading}
                type="yellow"
              >
                Kirim
              </InquiryFormSubmitBtn>
            </InquiryFormSubmitBtnCtn>
          </>
        )}
      </Formik>
      <Modal
        title={"Form Marketing"}
        show={showModal}
        onClose={() => setShowModal(false)}
        showHeader={false}
        showinMiddle={true}
      >
        <InquirySend setShowModal={setShowModal} />
      </Modal>
    </InquiryFormWarpper>
  );
};

export default InquiryForm;
