import styled from 'styled-components'
import { imageLink } from './constant'


export const AutoContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p, h1{
        margin: 0;
        padding: 0;
    }

    .hero-container {
        margin-top: 32px;
        background-image: url(${imageLink.heroImage});
        background-repeat: no-repeat;
        background-size: cover;
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 840px;
        max-height: 840px;
    }

    .hero-container img {
        margin-bottom: 32px;
    }

    .hero-container h1 {
        font-size: 40px;
        line-height: 56px;
        font-weight: 800;
    }

    .hero-container h1:last-of-type {
        margin-bottom: 32px;
    }

    .hero-container p {
        max-width: 645px;
        font-size: 16px;
        line-height: 24px;
    }

    .hero-div {
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        margin-right: 256px;
        margin-top: 64px;
    }

    .hero-div-inner {
        border: 7px solid #B0B0B0;
        border-radius: 20px;
        padding: 24px;
        text-align: center;
        font-size: 14px;
        background-color: rgba(255,255,255,0.8)
    }

    .hero-div-items {
        border-bottom: 4px solid #B0B0B0;
        padding-bottom: 16px;
        margin-bottom: 16px;
    }  

    .hero-div-items p:nth-of-type(2) {
        margin-bottom: 16px;
    }

    .hero-div-items p:last-of-type {
        font-size: 40px;
        color: #00D278;
        text-align: left;
        font-weight: 800;
        margin-left: 16px;
    }

   
    .hero-div-items .super {
        font-size: 12px;
        color: #b0b0b0;
        vertical-align: super;
        font-weight: 800;
        position: relative;
        top: -8px; 
        margin-right: 16px;
    }

    .hero-div-items .normal {
        font-size: 14px;
        color: #000;
        font-weight: 400;
    }

    .hero-div-items:last-child {
        border-bottom: none;
    }

    .content {
        width: 100%;  
        background: rgb(217,217,217);
        background: -moz-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 30%);
        background: -webkit-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 30%);
        background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 30%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9d9d9",endColorstr="#ffffff",GradientType=1);
    }

    .content-inner {
        width: 100%;
        max-width: 1000px;         
        margin: 0 auto;            
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        line-height: 32px;
        gap: 40px;        
    }

    .tap-auto-sec2 {
        margin-top: 64px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .section-title{
        text-align: center;
        margin-bottom: 16px;
        line-height: 40px;
        font-size: 40px;
        line-height: 56px;
        font-weight: 800;
    }

    .section-subtitle {
        margin-bottom: 24px;
        text-align: center;
    }

    .tap-auto-sec2 img {
        width: 1000px;
    }

    .tap-auto-sec3 {
        margin-top: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tap-auto-sec3 h1:first-of-type{
        text-align: center;
        margin-bottom: 24px;
    }

    .tap-auto-sec3 p {
        max-width: 670px;
        word-break: break-word;
    }

    .tap-auto-sec3 img {
        width: 637px;
        margin-top: 32px;
    }

    .tap-auto-sec4 {
        margin-top: 60px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .table-benefit {
        width: 100%;
        margin-bottom: 24px;
    }

    .table-header {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        border-bottom: 2px solid #FAFC00;
        padding: 16px;
        margin-bottom: 24px;
    }

    .table-header h4 {
        font-size: 16px;
        font-weight: 800;
        text-align: center;
    }

    .benefit {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 14px;
        font-weight: 800;
        line-height: 24px;
    }

    .benefit img {
        width: 72px;
    }

    .claim-amount {
        font-size: 14px;
        font-weight: 800;
        text-align: center;
    }

    .claim-amount h1{
        font-size: 24px;
        font-weight: 800;
    }

    .claim-amount p {
        margin: 0;
        text-align: center;
    }

    .claim-benefit {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 32px;
        margin-top: 48px;
        width: 100%;
    }

    .table-items {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .table-item-container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        text-align: left;
        row-gap: 24px;
    }

    .claim-benefit-items {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-width: 200px;
        text-align: center;
        font-size: 18px;
        font-weight: 800;
        gap: 16px;
        line-height: 24px;
    }

    .claim-benefit-items img{
        width: 89px;
    }

    .tap-auto-sec5 {
        margin-top: 32px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tap-auto-sec5  h2 {
        margin-top: 64px;
    }

    .tap-auto-sec5-inner {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        justify-content: center;
        justify-items: center;
        margin-top: 32px;
        width: 100%;
    }

    .tap-auto-sec5-items {
        max-width: 240px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .tap-auto-sec5-items img {
        width: 96px;
    }

    .tap-auto-sec5-items h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 800;
    }

    .tap-auto-sec5-items:nth-child(4) {
        grid-column: 1 / span 1;
        position: relative;
        right: -150px;
    }

    .tap-auto-sec5-items:nth-child(5) {
        grid-column: 3 / span 1; 
        position: relative;
        left: -150px;
    }

    .tap-auto-sec5-items p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

    .tap-auto-sec5-items img {
        margin-bottom: 24px;
    }

    .tap-auto-sec5-items h3 {
        margin-bottom: 16px;
    }

    .optional {
        font-size: 12px;
        font-weight: 400;
        margin-top: 50px;
    }

    .tap-auto-sec6 {
        margin-top: 48px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tap-auto-sec6 h2 {
        margin-bottom: 32px;
    }

    .tap-auto-sec6 img {
        width: 64px;
    }

    .tap-auto-sec6-inner {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        justify-content: center;
        justify-items: center;
        margin-top: 32px;
        width: 100%;
    }

    .tap-auto-sec6-items {
        max-width: 240px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .tap-auto-sec6-items p {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }

    .tap-auto-sec6-items img {
        margin-bottom: 24px;
        width: 96px; 
    }

    .tap-auto-sec6-items h3 {
        margin-bottom: 16px;
    }

    .tap-auto-sec7 {
        margin-top: 48px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tap-auto-sec7 img {
        width: 899px;
    }

    .btn-auto {
        box-shadow: none;
        border: none;
        padding: 16px 24px;
        border-radius: 32px;
        cursor: pointer;
        font-weight: 600;
        background-color: #00D277;
        transition: background-color 0.5s ease;
        color: #FFF;

        &:hover {
            background-color: #069155;
        }
    }

    .tap-auto-sec8 {
        margin-top: 48px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tap-auto-sec9 {
        margin-top: 48px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tap-auto-sec9 img {
        width: 1000px;
    }

    .sales-form {
        width: 100vw;
    }
    .btn-auto-circle {
        box-shadow: none;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        background-color: #00D277;
        transition: background-color 0.5s ease;
        color: #FFF;
        width: 47px;
        height: 47px;

        &:hover {
            background-color: #069155;
        }
    }
    .flex-ctn{
        display: flex;   
    }
    .flex-ctn img{
        width: 17px;   
    }
`