export const imagesLink = {
    tapInsureLogo : 'https://storage.googleapis.com/pp_img/tap-travel-website/common/TAP-Insure-Logo.svg',
    arrowDown: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Arrow%20Down.svg',
    arrowLeft: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Arrow%20Left.svg',
    arrowRight: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Arrow%20Right.svg',
    arrowRight2: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Arrow%20Right%202.svg',
    AUSLogo: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/AUS.svg',
    MariBerasuransiLogo: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Mari%20Berasuransi.svg',
    facebookLogo: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Facebook.svg',
    instagramLogo: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Instagram.svg',
    xLogo: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/X.svg',
    youtubeLogo: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Youtube.svg',
    linkedinLogo: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Linkedin.svg',
    phone: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Call.svg',
    message: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Message.svg',
    location: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Pin.svg',
    calendar:  'https://storage.googleapis.com/pp_img/tap-travel-website/common/Calendar.svg',
    whatsapp: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/Whatsapp.svg',
    download: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/download.svg',
    autoText: 'https://storage.googleapis.com/pp_img/tap-travel-website/road-assistance/auto_text.svg',
    MenuIcon: `https://storage.googleapis.com/pp_img/tap-travel-website/common/menu-icon.svg`,
    crossIcon: 'https://storage.googleapis.com/pp_img/tap-travel-website/common/cross-icon.png',
    Home: `https://storage.googleapis.com/pp_img/tap-travel-website/common/home-icon-black.svg`,
    ProductShield: `https://storage.googleapis.com/pp_img/tap-travel-website/common/check-shield.svg`,
    PeopleIcon: `https://storage.googleapis.com/pp_img/tap-travel-website/common/people-icon.svg`,
    PhoneIcon: `https://storage.googleapis.com/pp_img/tap-travel-website/common/phone-icon.svg`,
    DocIcon: `https://storage.googleapis.com/pp_img/tap-travel-website/common/doc-icon.svg`
}