import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  CriteriaWrapper,
  FormItem,
  Title,
  OptionsWrapper,
  Bluetext,
  FooterCtn,
  SubmitBtn,
  ProductTypeTitle,
  FormItemWrapper,
  PersonInfo,
  TooltipCtn,
  FormItemWrapper2
} from "./elements";
import TextInput from "../../../../components/FormComponents/InputText";
import Options from "../../../../components/FormComponents/OptionsList";
import Modal from "../../../../components/Modal";
import { Formik } from "formik";
import SelectCountry from "../../../../components/FormComponents/SelectCountry";
import SelectMultipleCountry from "../../../../components/FormComponents/SelectMultipleCountry";
import CounterInput from "../../../../components/CounterInput";
import {
  useCategorizedCountryList,
  useDomesticOriginsList,
  useDomesticDestinationsList
} from "../../../../lib/api";
import SelectDomesticDestination from "../../../../components/FormComponents/SelectDomesticDestination";
import TravelPeriod from "../../../../components/TravelPeriod/TravelPeriod";
import { addDays, addYears, format, isAfter, parse, subDays } from "date-fns";
import { OptionsListWrapper, TravelTripDetailsPara, TripInformationCtn, TripInformationTitle } from "../../../userCriteria/elements";
import Tooltip from "../../../../components/ToolTip";
import { PRODUCT_INFORMATION } from "../../../../constants/data";
import { ImageUrl } from "../../../../Images";

const INTIIAL_VALUES = {
  trip_type: "",
  trip_start_from: "",
  destinations: "",
  trip_start_date: "",
  trip_end_date: "",
  product_type: "",
  no_of_insured_persons: 1,
  is_schengen: "",
};

const SCHEMA = {
  trip_type: Yup.string().required("Jenis Penerbangan tidak boleh kosong"),
  trip_start_from: Yup.string().required("Negara Asal tidak boleh kosong"),
  destinations: Yup.string().required("Tujuan tidak boleh kosong"),
  trip_start_date: Yup.string().required('Tanggal Mulai Perjalanan tidak boleh kosong')
    .test(
      'is-after-today',
      () => 'Tanggal Mulai Perjalanan harus di hari ini atau setelahnya',
      (value) => {
        const prevDay = subDays(new Date(), 1)
        const dateVal = parse(value, 'yyyy-MM-dd', new Date())
        // return true
        return isAfter(dateVal, prevDay)
      }),
  trip_end_date: Yup.string().required('Tanggal Akhir Perjalanan tidak boleh kosong')
    .test(
      'is-after-start',
      () => 'Tanggal Akhir Perjalanan harus setelah tanggal mulai perjalanan',
      (value, context) => {
        const startDate = context.parent.trip_start_date
        const dateVal = parse(value, 'yyyy-MM-dd', new Date())
        if (startDate) {
          const startDateObj = parse(startDate, 'yyyy-MM-dd', new Date())
          return isAfter(dateVal, startDateObj)
        }
        return true
      // return true
      }),
  product_type: Yup.string().required("Jenis Produk tidak boleh kosong"),
};

const NO_OF_INSURED_SCHEMA = Yup.number().required(
  "Jumlah Penumpang harus diisi"
);

const EditPackageDetails = (props) => {
  const navigate = useNavigate();
  const [formSchema, setFormSchema] = useState(SCHEMA);
  const [tripType, setTripType] = useState("");
  const [formInitialValues, setFormInitialValues] = useState(INTIIAL_VALUES);
  const [isInternationalTravel, setIsInternationalTravel] = useState(false);
  const [showSchengen, setShowSchengen] = useState(false);
  const [memberFieldEnabled, setMemberFieldEnabled] = useState(false);
  const [showSingleCountry, setShowSingleCountry] = useState(false);
  const [showMultipleCountry, setShowMultipleCountry] = useState(false);
  const [seletcedCountriesMap, setSelectedCountriesMap] = useState({});
  const [seletcedCountriesList, setSelectedCountriesList] = useState([]);
  const [dateModal, setDateModal] = useState(false)
  const [showSchedule, setShowSchedule] = useState("startDate")
  const { countryList = [], getCountryList = () => {} } =
  useCategorizedCountryList();
  const { domesticOriginListOptions = [], getDestinations = () => {} } =
  useDomesticOriginsList();
  const { domesticDestinationList = [], getDomesticDestinations = () => {} } =
  useDomesticDestinationsList();

useEffect(() => {
    setFormInitialValues({
        trip_type: props.data.trip_type.description || '',
        trip_start_from: props.data.trip_start_from.description || '',
        destinations: props.data.destinations.description || '' ,
        trip_start_date: props.data.trip_start_date.description || '',
        trip_end_date: props.data.trip_end_date.description || '',
        product_type: props.data.product_type.description || 'individual',
        no_of_insured_persons: props.data.no_of_insured_persons.description ? Number(props.data.no_of_insured_persons.description) : 1,
        is_schengen: props.data.is_schengen.description || 'false',
    })
    if (props.data?.trip_type.description === "international") {
        const selectedMap = {}
        const selectedList = [];
        const schengenCountries = [];
        const countrySelecedArray = props.data.destinations.description.split(',')
        setIsInternationalTravel(true)

        countrySelecedArray.map((curr) => {
            return selectedMap[curr] = true
        })
        countryList.length && countryList?.forEach(item => {
            if(countrySelecedArray.includes(item.country_name)) {
                selectedList.push(item)
            }
        }, [])

        setSelectedCountriesMap(selectedMap)
        setSelectedCountriesList(selectedList)
        for (let i=0; i<countryList.length; i++){
          for (let j=0; j<selectedList.length; j++) {
            if(countryList[i].schengen && countryList[i].country_name === selectedList[j].country_name) {
              schengenCountries.push(countryList[i])
            }
          }
        }
        if (schengenCountries.length) {
          setShowSchengen(true)
        } else {
          setShowSchengen(false)
        }
    }
    if (['group', 'family'].includes(props.data?.product_type.description)) {
        setMemberFieldEnabled(true)
    }
    if (props.data.is_schengen.description === 'true') {
        setShowSchengen(true)
    }
    props.data.product_type.description && setTripType(props.data.product_type.description)
}, [props.data, countryList])


  useEffect(() => {
    getDestinations();
    getCountryList();
    getDomesticDestinations();
  }, []);

  useEffect(() => {
    const currSchema = { ...formSchema };
    if (memberFieldEnabled) {
      currSchema.no_of_insured_persons = NO_OF_INSURED_SCHEMA;
    }
    setFormSchema(currSchema);
  }, [memberFieldEnabled]);

  const dummySubmit = (_values, _formikbag) => {
    // dummy handle submit func
  };

  const handleTripTypeChange = (
    formValues,
    tripType,
    setFormValues,
  ) => {
    if (tripType === "international") {
      setIsInternationalTravel(true);
      setFormValues({
        ...formValues,
        trip_type: tripType,
        trip_start_from: "Indonesia",
        destinations: "",
      });
    } else {
      setShowSchengen(false);
      setIsInternationalTravel(false);
      setFormValues({
        ...formValues,
        trip_type: tripType,
        trip_start_from: "",
        destinations: "",
      });
    }
  };

  const handleProductTypeChange = (formValues, productType, setFormValues) => {
    if (productType === "individual") {
      setTripType("individual");
      setMemberFieldEnabled(false);
      setFormValues({
        ...formValues,
        product_type: productType,
        no_of_insured_persons: 1,
      });
    } else if (productType === "group") {
      setMemberFieldEnabled(true);
      setTripType("group");
      setFormValues({
        ...formValues,
        product_type: productType,
        no_of_insured_persons: 2,
      });
    } else {
      setMemberFieldEnabled(true);
      setTripType("family");
      setFormValues({
        ...formValues,
        product_type: productType,
        no_of_insured_persons: 1,
      });
    }
  };

  const handleSchengen = (formValues, val, setFormValues) => {
    setFormValues({
      ...formValues,
      is_schengen: val,
    });
  };

  const handleSubmit = (formProps) => {
    formProps.submitForm().then(() => {
      Yup.object(formSchema)
        .validate(formProps.values)
        .catch((err) => {
          console.error(err);
        })
        .then((values) => {
          if (values) {
            props.setShowModal(false);
            navigate({
              pathname: "",
              search: createSearchParams({
                ...values,
              }).toString(),
            });
          }
        });
    });
  };

  const handleSelectSingleCountry = (formValues, val, setFormValues) => {
    setFormValues({
      ...formValues,
      trip_start_from: val,
    });
    setShowSingleCountry(false);
    setShowMultipleCountry(true);
  };

  const handleSelectMultipleCountry = (formValues, val, setFormValues) => {
    showSchengen ? setFormValues({
      ...formValues,
      destinations: val,
    }) : setFormValues({
        ...formValues,
        destinations: val,
        is_schengen:'false'
      });
    setShowMultipleCountry(false);
  };

  const handleEditFromCountry = () => {
    setShowSingleCountry(true);
    setShowMultipleCountry(false);
  };


  const getProductInformation = useCallback(() => {
    return (
      <TripInformationCtn>
        {tripType && Object.keys(PRODUCT_INFORMATION[tripType]).map((item) => {
          if (item === "title") {
            return (
              <TripInformationTitle>
                {PRODUCT_INFORMATION[tripType][item]}
              </TripInformationTitle>
            );
          } else if (item.includes("para")) {
            return (
              <TravelTripDetailsPara>
                {PRODUCT_INFORMATION[tripType][item]}
              </TravelTripDetailsPara>
            );
          }
        })}
      </TripInformationCtn>
    );
  }, [tripType]);

  return (
    <CriteriaWrapper>
      <Formik
        onSubmit={dummySubmit}
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={Yup.object(formSchema)}
      >
        {(formProps) => (
          <>
          <FormItemWrapper>
            <FormItem>
              <Title>Tipe Perjalanan</Title>
              <OptionsWrapper>
                <Options
                  name="trip_type"
                  optionsList={[
                    { id: "Domestik", title: "domestic" },
                    { id: "Internasional", title: "international" },
                  ]}
                  onChange={(val) =>
                    handleTripTypeChange(
                      formProps.values,
                      val,
                      formProps.setValues,
                      formProps
                    )
                  }
                  formValues={formInitialValues}
                  getter={(val) => val.title}
                  renderer={(val) => val.id}
                />
              </OptionsWrapper>
            </FormItem>
            <FormItem>
              <Title>Destinasi Perjalanan</Title>
              <OptionsListWrapper>
                <TextInput
                  name="trip_start_from"
                  label="Dari"
                  inputValue={formProps.values.trip_start_from}
                  onClick={() => setShowSingleCountry(true)}
                  icontype="text"
                  disabled={isInternationalTravel}
                />
                <TextInput
                  name="destinations"
                  label="Tujuan"
                  inputValue={
                    formProps.values.destinations
                      ? formProps.values.destinations.length > 13
                        ? formProps.values.destinations.slice(0, 13) + "..."
                        : formProps.values.destinations
                      : ""
                  }
                  onClick={() => setShowMultipleCountry(true)}
                  icontype="text"
                />
              </OptionsListWrapper>
            </FormItem>
            <FormItem>
              <Title>Periode Perjalanan</Title>
              <OptionsListWrapper>
                <TextInput
                  name="trip_start_date"
                  label="Mulai Perjalanan"
                  inputValue={formProps.values.trip_start_date ? format(formProps.values.trip_start_date, 'dd/MM/yyyy') : ''}
                  icontype="date"
                  onClick={() => {
                      setDateModal(true)
                      setShowSchedule('startDate')
                  }}
                />
                <TextInput
                  name="trip_end_date"
                  label="Akhir Perjalanan"
                  inputValue={formProps.values.trip_end_date ? format(formProps.values.trip_end_date, 'dd/MM/yyyy') : ''}
                  icontype="date"
                  onClick={() => {
                      setDateModal(true)
                      setShowSchedule('endDate')
                  }}
                />
              </OptionsListWrapper>
            </FormItem>
          </FormItemWrapper>
          <FormItemWrapper2>
            <FormItem>
              <ProductTypeTitle>
                <Title>Jenis Perlindungan</Title>
                <TooltipCtn>
                    <Tooltip
                        text={getProductInformation()}
                        position="top"
                        background="#FFFFFF"
                      >
                        <img
                          src={ImageUrl.EditBlack}
                          width="20px"
                          height="20px"
                          alt="edit-icon"
                        />
                    </Tooltip>
                  </TooltipCtn>
              </ProductTypeTitle>
              <OptionsWrapper>
                <Options
                  name="product_type"
                  optionsList={[
                    { id: "Individu", title: "individual" },
                    { id: "Grup", title: "group" },
                    { id: "Keluarga", title: "family" },
                  ]}
                  getter={(val) => val.title}
                  renderer={(val) => val.id}
                  onChange={(val) =>
                    handleProductTypeChange(
                      formProps.values,
                      val,
                      formProps.setValues
                    )
                  }
                />
              </OptionsWrapper>
            </FormItem>
            {memberFieldEnabled && (
              <FormItem>
                <Title>
                  Jumlah Wisatawan &nbsp;<Bluetext>*</Bluetext>
                </Title>
                <CounterInput
                  label="Jumlah Tertanggung"
                  name="no_of_insured_persons"
                  required
                  minVal={2}
                  maxVal={formProps.values.product_type === "family" ? 5 : 10}
                />
              </FormItem>
            )}
            {showSchengen && (
              <FormItem>
                <Title>Pengajuan Pembuatan Visa Schengen</Title>
                <OptionsWrapper>
                  <Options
                    name="is_schengen"
                    optionsList={[
                        {
                            display_name: "Ya",
                            value: 'true',
                          },
                          {
                            display_name: "Tidak",
                            value: 'false',
                          }
                    ]}
                    getter={(val) => val.value}
                    renderer={(val) => val.display_name}
                    onChange={(val) =>
                      handleSchengen(formProps.values, val, formProps.setValues)
                    }
                    formValues={formInitialValues}
                  />
                </OptionsWrapper>
              </FormItem>
            )}
          </FormItemWrapper2>
            <Modal
              title={"Destinasi Perjalanan"}
              show={showSingleCountry || showMultipleCountry}
              onClose={() => {
                setShowSingleCountry(false);
                setShowMultipleCountry(false);
              }}
              maxWidth="500px"
            >
              {showSingleCountry ? (
                isInternationalTravel ? (
                  <SelectCountry
                    name="trip_start_from"
                    label="Dari"
                    onSelect={(val) =>
                      handleSelectSingleCountry(
                        formProps.values,
                        val,
                        formProps.setValues
                      )
                    }
                    countryListOptions={countryList.length && countryList}
                    getter={(x) => x.country_name}
                    renderer={(x) => x.country_name}
                    placeholder="Dari"
                  />
                ) : (
                  <SelectCountry
                    name="trip_start_from"
                    label="Dari"
                    onSelect={(val) =>
                      handleSelectSingleCountry(
                        formProps.values,
                        val,
                        formProps.setValues
                      )
                    }
                    countryListOptions={domesticOriginListOptions.length && domesticOriginListOptions}
                    getter={(x) => x.city_name}
                    renderer={(x) => x.city_name}
                    placeholder="Dari"
                  />
                )
              ) : showMultipleCountry ? (
                isInternationalTravel ? (
                  <SelectMultipleCountry
                    name="trip_start_from"
                    label="Dari"
                    onSubmit={(val) =>
                      handleSelectMultipleCountry(
                        formProps.values,
                        val,
                        formProps.setValues
                      )
                    }
                    countryListOptions={countryList}
                    getter={(x) => x.country_name}
                    renderer={(x) => x.country_name}
                    placeholder="Tujuan"
                    selectCountry={formProps.values.trip_start_from}
                    handleEditFromCountry={handleEditFromCountry}
                    seletcedCountriesMap={seletcedCountriesMap}
                    setSelectedCountriesMap={setSelectedCountriesMap}
                    seletcedCountriesList={seletcedCountriesList}
                    setSelectedCountriesList={setSelectedCountriesList}
                    setShowSchengen={setShowSchengen}
                    isInternationalTravel={isInternationalTravel}
                  />
                ) : (
                  <SelectDomesticDestination
                    name="trip_start_from"
                    label="Dari"
                    onSubmit={(val) =>
                      handleSelectMultipleCountry(
                        formProps.values,
                        val,
                        formProps.setValues
                      )
                    }
                    countryListOptions={domesticDestinationList.length && domesticDestinationList}
                    getter={(x) => x.city_name}
                    renderer={(x) => x.city_name}
                    placeholder="Tujuan"
                    handleEditFromCountry={handleEditFromCountry}
                    selectCountry={formProps.values.trip_start_from}
                    setShowSchengen={setShowSchengen}
                    hideOption={formProps.values.trip_start_from}
                    isInternationalTravel={isInternationalTravel}
                  />
                )
              ) : null}
            </Modal>
            <Modal
              title={"Periode Perjalanan"}
              show={dateModal}
              onClose={() => setDateModal(false)}
              maxWidth='500px'
            >
                <TravelPeriod
                    name='trip_start_date'
                    label="Mulai Perjalanan"
                    setFormValues={formProps.setValues}
                    formValues={formProps.values}
                    setDateModal={setDateModal}
                    setSchedule={showSchedule}
                    setShowSchedule={setShowSchedule}
                    startDate={formProps.values.trip_start_date}
                    startMinDate={new Date()}
                    startMaxDate={addYears(new Date(), 2)}
                    endMinDate={formProps?.values?.trip_start_date ? addDays(parse(formProps.values.trip_start_date, 'yyyy-MM-dd', new Date()), 1) : new Date()}
                    endMaxDate={formProps?.values?.trip_start_date ? addDays(parse(formProps.values.trip_start_date, 'yyyy-MM-dd', new Date()), 30) : addDays(new Date(), 30)}
                />
            </Modal>
            <FooterCtn>
              <PersonInfo>
                <Bluetext>* </Bluetext>
                Dewasa : min 2 orang, usia 17 tahun keatas. Anak : maks. 4
                orang, usia 17 tahun kebawah.
              </PersonInfo>
              <SubmitBtn
                onClick={() => {
                  handleSubmit(formProps);
                }}
                id="travel-trip-detail-btn"
                bgColor='#FAF000'
                fontColor='#333333'
              >
                Cek Harga Premi
              </SubmitBtn>
            </FooterCtn>
          </>
        )}
      </Formik>
    </CriteriaWrapper>
  );
};

export default EditPackageDetails;
