import styled from 'styled-components'

export const VisiMisiContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    *{
        padding: 0;
        margin: 0;
    }

    .hero-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 32px;
        padding-bottom: 32px;
        width: 100%;               
    }

    .hero-title {
        text-align: center;
        font-size: 40px;
        max-width: 976px;
        word-break: break-word;
        margin-bottom: 24px;
    }

    .content {
        width: 100%;  
        background: rgb(217,217,217);
        background: -moz-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        background: -webkit-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9d9d9",endColorstr="#ffffff",GradientType=1);
    }

    .content-inner {
        width: 100%;
        max-width: 70%;         
        margin: 0 auto;            
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;
        line-height: 32px;
        gap: 40px;        
    }

    .content-inner > img {
        width: 1000px;
    }

    .visi-misi {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    .visi-misi-title h1 {
        font-size: 40px;
        width: 253px;
        word-break: break-word;
        line-height: 56px;
    }

    .visi-misi-items-container {
        width: 65%;
        justify-content: space-between;
    }

    .visi-misi-items {
        display: flex;
        gap: 32px;
        border-top: 2px solid #FAFC00;
        padding: 16px;
    }

    .visi-misi-section-container {
        display: flex;
        width: 100%;
        justify-content: center;
        position: relative;
        margin-bottom: 8em;
    }

    .visi-misi-section-title {
        padding: 4px 12px;
        background-color: #FFF500;
        width: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        border-radius: 32px;
        font-size: 12px;
    }
        
    .visi-misi-section-para {
        font-size: 20px;
        line-height: 24px;
    }

    .visi {
        border-top: 2px solid #FFF500;
        border-right: 2px solid #FFF500;
        border-bottom: 2px solid #FFF500;
        padding: 16px;
        max-width: 90%;
        border-top-right-radius: 200px;
        border-bottom-right-radius: 200px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        min-width: 73%;
    }

    .visi p {
        margin-top: 16px;
        font-weight: 800;
    }

    .misi {
        border: 2px solid #FFF500;
        padding: 16px;
        width: 360px;
        height: 360px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        left: -50px;
        box-sizing: border-box;
    }

    .misi p {
        margin-top: 16px;
        text-align: center;
        font-weight: 800;
    }

    .hero-image {
        width: 40%;
        height: 550px;
    }

    @media (max-width: 480px) {
        .visi-misi {
            flex-direction: column;
        }

        .hero-image {
            width: 90%;
            height: 340px;
            transform: scale(0.9);
        }

        .hero-title {
            font-size: 16px;
            font-weight: 800;
            line-height: 24px;
            text-align: center;
            margin-top: 16px;
            width: 70%;
        }
        .visi-misi-title h1 {
            font-size: 18px;
            font-weight: 800;
            line-height: 26px;
            text-align: center;
            margin: 32px auto;
            width: 100%;
        }
        .content-inner {
            padding: 0px;
            max-width: 90%;
        }
        .visi-misi-items-inner {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        .visi-misi-items-inner h3 {
            font-size: 14px;
            font-weight: 800;
            line-height: 18px;
            text-align: left;
        }
        .visi-misi-items-inner p {
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
        }
        .visi-misi-section-container-mobile {
            display: flex;
            flex-direction: column;
            gap: 48px;
            width: 90%;
            margin: auto;
            margin-top: 32px;
            margin-bottom: 32px;
        }
        .visi-mobile {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
        .visi-misi-section-title-mobile {
            padding: 8px 20px 8px 20px;
            border-radius: 100px;
            background: #FFF500;
            color: #333333;
            font-size: 12px;
            font-weight: 800;
            line-height: 16px;
            text-align: center;
            width: fit-content;
        }
        .visi-misi-section-para-mobile {
            font-size: 12px;
            font-weight: 800;
            line-height: 20px;
            text-align: left;
        }
        .visi-misi-title {
            width: 100%;
        }
    }

`