import React from 'react'
import { TermsConditionContainer } from './TermsCondition.style'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { imagesLink } from './constant'

const TermsCondition = () => {
  return (
        <><Header />
   <TermsConditionContainer>
        <div className='hero-section'>
            <h1 className='hero-title'>Syarat dan Ketentuan yang <br />Perlu Kamu Ketahui</h1>
            <div className='image-ctn'><img src={imagesLink.heroImage} className='hero-image'/></div>
        </div>
        <div className='content'>
          <div className='content-inner'>
            <h2>Syarat dan Ketentuan Umum</h2>
            <p>Syarat dan Ketentuan ini adalah perjanjian antara Anda sebagai pengguna dan PT Asuransi Untuk Semua (<strong>“kami”, “TAP Insure”</strong>), suatu perseroan terbatas yang didirikan dan beroperasi secara sah berdasarkan hukum negara Republik Indonesia.</p>
            <p>Syarat dan Ketentuan ini mengatur akses dan penggunaan Anda atas situs web (tap-insure.com) (<strong>“Situs”</strong>), termasuk konten/fitur yang tersedia di dalamnya dan setiap produk/layanan Kami (<strong>“Layanan”</strong>).</p>
            <p>Syarat dan Ketentuan dapat kami ubah, modifikasi, tambah, hapus atau koreksi setiap saat dan setiap perubahan itu berlaku sejak saat kami nyatakan berlaku atau pada waktu lain yang ditetapkan oleh kami; Anda Kami anjurkan untuk mengunjungi Situs secara berkala agar dapat mengetahui adanya perubahan tersebut bilamana ada.</p>
            <p>Dengan mengakses dan menggunakan Layanan, Anda menyatakan telah membaca, memahami, menyetujui dan menyatakan tunduk pada Syarat dan Ketentuan ini. Jika Anda tidak dapat menyetujui Syarat dan Ketentuan ini, baik secara keseluruhan ataupun sebagian, maka Anda selalu dapat memilih untuk tidak mengakses, menggunakan dan/atau melanjutkan akses atas Layanan.</p>
            <h2>Layanan</h2>
            <p>Secara rinci, Layanan yang kami berikan pada Situs antara lain mencakup:</p>
            <ol>
              <li>Penyediaan dan penyelenggaraan sistem dan fasilitas pemesanan online secara terpadu yang dapat melayani pemesanan berbagai macam produk asuransi TAP Insure yang memungkinkan Anda untuk mencari informasi atas produk asuransi yang Anda inginkan dan menyediakan informasi, sumber daya, dan peralatan untuk membantu nasabah belajar, memahami, membandingkan, dan membuat keputusan cerdas atas pilihan mereka akan produk asuransi.</li>
              <li>Penyediaan dan penyelenggaraan sistem dan fasilitas untuk melakukan pembayaran secara online dan aman melalui berbagai sistem dan fasilitas pembayaran yang tersedia atas produk asuransi yang disediakan dalam Situs.</li>
              <li>
                Penyediaan dan penyelenggaraan sistem dan fasilitas penerimaan keluhan dan pengaduan serta klaim manfaat atas produk asuransi secara online dan aman melalui berbagai teknologi yang terdapat di dalam Situs.
              </li>
              <li>Memfasilitasi dan menyediakan sistem serta fasilitas untuk mengakses detail polis dan melakukan klaim atas manfaat yang menjadi bagian dari produk asuransi yang Anda beli secara online dan aman melalui berbagai teknologi yang terdapat di dalam Situs.</li>
            </ol>
            <p>Kami mencadangkan hak untuk melakukan perbaikan, peningkatan atau pemeliharaan pada Situs yang menyediakan Layanan pada saat kapanpun dan setiap saat tanpa ada pemberitahuan sebelumnya.</p>
            <p>Anda tidak diperkenankan untuk menggunakan, menyalin, mengawasi, menampilkan, mengunduh, mengunggah atau mereproduksi konten atau informasi, piranti lunak, atau Layanan apa pun yang tersedia di Situs untuk kegiatan atau tujuan komersial apapun, tanpa persetujuan tertulis dari Kami sebelumnya.</p>
            <p>Anda setuju untuk mengakses atau menggunakan Layanan hanya untuk tujuan sebagaimana ditentukan dalam Syarat dan Ketentuan ini dan tidak menyalahgunakan atau menggunakan Layanan untuk tujuan penipuan, menyebabkan ketidaknyamanan kepada orang lain, melakukan pemesanan palsu atau tindakan-tindakan lain yang dapat atau dianggap dapat menimbulkan kerugian dalam bentuk apapun terhadap orang lain.</p>
            <h2>Penggunaan</h2>
            <p>Akses dan penggunaan Situs tunduk pada Syarat dan Ketentuan ini.</p>
            <p>Layanan yang tersedia di dalam Situs dapat digunakan oleh Anda untuk penggunaan pribadi secara non-komersial dan setiap saat tunduk pada Syarat dan Ketentuan serta hukum yang berlaku.</p>
            <p>Situs dan produk-produk, teknologi dan proses yang terdapat atau terkandung di dalamnya, dimiliki oleh kami atau pihak ketiga yang memberi hak kepada Kami. Kecuali untuk penggunaan yang secara tegas diijinkan dan diperbolehkan dalam Syarat dan Ketentuan Penggunaan ini, Anda tidak memiliki ataupun menerima dan kami tidak memberikan hak lain apapun ke Anda atas Situs ini, berikut dengan segala data, informasi dan konten di dalamnya.</p>
            <p>Dengan menggunakan Situs dan/atau Layanan, Anda setuju untuk tidak melakukan hal - hal di bawah ini:</p>
            <ol>
              <li>mencemarkan nama baik, menguntit, menggertak, menyalahgunakan, mengancam, melecehkan, menyalahgunakan, mengintimidasi, melukai orang lain atau terlibat dalam perilaku predator lainnya, termasuk mengirim komunikasi yang tidak diinginkan kepada orang lain atau terlibat dalam perilaku predator lainnya, atau menghasut orang lain untuk melakukan tindakan kekerasan</li>
              <li>menggunakan identitas palsu</li>
              <li>menggandakan, melisensikan, mensublisensikan, mempublikasikan, menyiarkan, mentransmisikan, mendistribusikan, melakukan, menampilkan, menjual, mengubah nama, atau mentransfer informasi yang ditemukan dalam Layanan, kecuali sebagaimana diizinkan dalam Syarat dan Ketentuan ini, atau sebagaimana yang secara tegas diperbolehkan atau disetujui oleh kami</li>
              <li>merekayasa balik, mendekompilasi, membongkar, menguraikan, atau berupaya mendapatkan kode sumber (source code) untuk hak kekayaan intelektual apa pun yang digunakan untuk menyediakan Layanan, atau bagian daripadanya</li>
              <li>menggunakan atau menyalin informasi, konten, atau data apa pun yang Anda lihat atau dapatkan dari Layanan untuk memberikan layanan apa pun yang berkompetisi, yang menurut kami, dengan Layanan</li>
              <li>menyesuaikan, memodifikasi atau membuat pekerjaan turunan berdasarkan Layanan atau teknologi yang mendasari Layanan, secara keseluruhan atau sebagian</li>
              <li>menyewakan, meminjamkan, memperdagangkan, menjual / menjual kembali informasi dalam Layanan, secara keseluruhan atau sebagian</li>
              <li>menjual, mensponsori, atau memonetisasi layanan atau fungsi apa pun dalam Layanan, tanpa persetujuan tertulis dari kami</li>
              <li>menghapus, menutupi, atau mengaburkan segala bentuk iklan yang termasuk dalam Layanan</li>
              <li>mengumpulkan, memanen, menggunakan, menyalin, atau mentransfer informasi apapun, termasuk, tetapi tidak terbatas pada, Data Pribadi yang diperoleh dari Layanan (tidak termasuk Data Pribadi Anda, dan kecuali jika pemilik informasi tersebut secara tegas mengizinkan hal yang sama)</li>
              <li>berbagi informasi pengguna atau pihak ketiga lainnya tanpa persetujuan tertulis dari mereka</li>
              <li>melanggar atau menggunakan merek, logo, atau merek dagang kami dalam nama bisnis, email, atau URL apa pun kecuali diizinkan secara tegas oleh kami</li>
              <li>menggunakan atau meluncurkan sistem atau perangkat lunak manual atau otomatis, perangkat, robot skrip, cara atau proses lain untuk mengakses, "scrape," "crawl", "cache", "spider" atau halaman web atau layanan lain apa pun yang terkandung dalam Layanan kami, atau untuk mengakses Layanan dengan cara yang mengirimkan lebih banyak pesan permintaan ke server kami dalam periode waktu tertentu daripada yang dapat diproduksi oleh manusia pada periode yang sama dengan menggunakan browser web online konvensional</li>
              <li>menggunakan bot atau metode otomatis lainnya untuk mengakses Layanan, menambah atau mengunduh kontak, mengirim atau mengalihkan pesan, atau melakukan kegiatan serupa lainnya melalui Layanan</li>
              <li>mengakses, melalui cara atau proses otomatis atau manual, Layanan untuk tujuan memantau ketersediaan, kinerja, atau fungsionalitasnya untuk tujuan kompetitif bisnis apa pun</li>
              <li>terlibat dalam "framing," "mirroring," atau mensimulasikan penampilan atau fungsi Situs</li>
              <li>berupaya untuk atau benar-benar mengakses Layanan dengan cara apa pun selain melalui antarmuka yang disediakan oleh kami. Larangan ini mencakup mengakses atau mencoba mengakses Layanan menggunakan layanan pihak ketiga mana pun, termasuk platform perangkat lunak sebagai layanan yang menggabungkan akses ke beberapa layanan</li>
              <li>terlibat dalam tindakan apapun yang secara langsung atau tidak langsung mengganggu kerja yang tepat atau menempatkan beban yang tidak masuk akal pada infrastruktur Layanan, termasuk, tetapi tidak terbatas pada, mengirimkan komunikasi yang tidak diminta ke pengguna lain atau personel kami, berusaha untuk mendapatkan akses tidak sah ke Layanan, atau mentransmisikan atau mengaktifkan virus komputer, atau program perangkat lunak berbahaya atau merusak lainnya melalui atau pada Layanan; dan / atau</li>
              <li>mengganggu atau memainkan Layanan, termasuk, tetapi tidak terbatas pada server atau jaringan yang terhubung ke Layanan, atau perangkat lunak yang mendasarinya.</li>
            </ol>
            <h2>Pembelian Produk Asuransi</h2>
            <ol>
              <li>Anda mulai dengan menyediakan informasi mengenai kebutuhan Anda atau diri Anda yang relevan dengan mengembangkan rekomendasi produk asuransi. Anda akan disajikan dengan informasi mengenai produk asuransi.</li>
              <li>Dalam memutuskan untuk melakukan underwriting atas risiko, untuk menyediakan perlindungan atau untuk menyetujui aplikasi Anda untuk suatu polis, kami memerlukan Anda untuk menyediakan Informasi terkait diri Anda, dan kami akan mengandalkan akurasi dan kelengkapan dari informasi yang Anda sediakan. Dengan memastikan pengajuan aplikasi pembelian produk asuransi, Anda menyatakan dan memastikan bahwa Anda telah menyediakan semua informasi terkait eligibilitas Anda untuk memenuhi kualifikasi dari suatu produk asuransi.</li>
              <li>Untuk mengajukan pembelian polis asuransi melalui Situs, sistem kami akan terlebih dahulu menghasilkan kode verifikasi secara otomatis dan mengirim kode verifikasi tersebut melalui pesan singkat ke nomor telepon genggam yang Anda berikan. Anda perlu memasukan kode verifikasi tersebut pada halaman pembelian di Situs. <br/><strong>MOHON JANGAN PERNAH MEMBERITAHUKAN KODE VERIFIKASI (ONE TIME PASSWORD) ANDA KEPADA SIAPAPUN BAHKAN KEPADA KAMI ATAU PIHAK LAIN YANG MENGAKU SEBAGAI PERWAKILAN KAMI. SEMUA KERUGIAN DAN RISIKO YANG ADA AKIBAT KELALAIAN ANDA MENJAGA KEAMANAN DAN KERAHASIAAN KODE VERIFIKASI INI DITANGGUNG OLEH ANDA SENDIRI.</strong></li>
              <li>Setelah Anda memastikan permohonan Anda dan memasukan kode verifikasi tersebut, Anda akan diminta untuk melanjutkan ke pembayaran. Kami memerlukan Anda untuk melakukan pembayaran premi untuk mendapatkan perlindungan dari produk asuransi yang tunduk pada spesifikasi dan ketentuan produk yang Anda beli. Untuk beberapa produk tertentu termasuk namun tidak terbatas pada produk asuransi kendaraan bermotor dan properti non-residensial, pembayaran bukanlah suatu atau secara otomatis menghasilkan persetujuan dari perusahaan asuransi untuk melaksanakan underwriting atas risiko, menyediakan perlindungan, atau menerbitkan polis. Karena perusahaan asuransi punya hak untuk menerima atau menolak underwriting atas risiko dan/atau menyediakan perlindungan, kami tidak bisa menjamin bahwa perusahaan asuransi akan setuju untuk menyediakan perlindungan.</li>
              <li>Pembayaran sebelum polis telah disetujui atau telah berlaku atau selama atau sebelum proses underwriting telah selesai, bukanlah suatu persetujuan untuk melaksanakan underwriting atas resiko atau untuk menerbitkan polis. Apabila polis ditolak, pembayaran akan dikembalikan kepada Anda. Harap dicatat bahwa bisa terdapat biaya-biaya yang dikenakan oleh pihak ketiga seperti bank atau perusahaan penerbit kartu kredit Anda, termasuk namun tidak terbatas pada charges di kartu kredit Anda. Apabila Anda melakukan pembayaran dan polis akhirnya disetujui, pembayaran yang demikian akan dianggap telah diterima dan proses pembelian telah selesai.</li>
              <li>Anda bertanggung jawab penuh atas pilihan produk asuransi yang dipilih dan kerugian Anda yang ditimbulkan (baik sengaja maupun tidak sengaja) akibat kesalahan atau ketidakcocokan dalam memberikan data dan informasi pada saat transaksi pembelian produk asuransi yang mengakibatkan produk asuransi menjadi tidak valid. Anda diwajibkan membaca dan memahami seluruh informasi yang diberikan sebelum memproses apapun. Apabila Anda ragu terhadap sebuah produk asuransi atau ketentuan dari produk asuransi tersebut, Anda dapat menghubungi kami untuk berkonsultasi sebelum memilih sebuah produk asuransi.</li>
              <li>Anda harus merujuk pada informasi produk asuransi terkait dan mempertimbangkan apakah suatu produk asuransi sesuai dengan Anda sebelum melakukan pembelian apapun. Informasi yang kami sediakan sehubungan dengan risiko, manfaat, kewajiban dan biaya-biaya terkait dengan produk asuransi yang ditawarkan adalah benar dan tidak menyesatkan.</li>
            </ol>
            <h2>Pembayaran dan Pengiriman Polis</h2>
            <ol>
              <li>Anda hanya diizinkan menggunakan saluran pembayaran (payment channels) yang disediakan oleh kami untuk membuat dan menyelesaikan pembayaran Anda. Kami tidak menerima pembayaran melalui saluran pembayaran selain yang telah ditentukan</li>
              <li>Anda bertanggung jawab untuk memastikan bahwa Anda menyediakan informasi yang tepat dan akurat dalam mengisi informasi pembayaran dan apabila terjadi kegagalan dalam hal tersebut, kami tidak bertanggung jawab atas kegagalan proses pembayaran atas pembelian produk asuransi yang Anda lakukan.</li>
              <li>Terhadap segala pembelian yang telah kami konfirmasi, kami akan mengirimkan surat konfirmasi via email yang berisikan uraian produk yang Anda pesan serta konfirmasi pembayaran atau melalui cara lain yang dapat kami tentukan dari waktu ke waktu. Dalam hal kami mengirimkan suatu surat konfirmasi, Anda bertanggung jawab untuk menyimpan dan menjaga surat konfirmasi yang kami kirim, surat konfirmasi tersebut merupakan dokumen penting bagi Anda untuk membuktikan Anda telah secara sah melakukan pembelian produk asuransi dan pembayaran premi.</li>
              <li>Dalam hal kami menyetujui pengajuan Anda untuk suatu produk asuransi, kami akan menerbitkan dan mengirimkan buku polis dalam format softcopy ke pemegang polis. Anda menyatakan bahwa Anda telah atau akan memperoleh kuasa pemegang polis untuk menerima pengiriman buku polis dalam format softcopy. Kami mungkin menyediakan pilihan untuk mengirimkan buku polis dalam format hardcopy dengan atau tanpa biaya tambahan. Apabila buku polis dalam format hardcopy membutuhkan biaya tambahan, biaya ini akan dimasukkan dalam pembayaran awal. Pemegang polis harus memberitahukan kepada kami sesegera mungkin apabila buku polis belum diterima dalam 5 hari kalender sejak pembayaran premi telah dilakukan.</li>
            </ol>
            <h2>Kekayaan Intelektual</h2>
            <ol>
              <li>Situs dan Layanan, termasuk namun tidak terbatas pada, nama, logo, kode program, desain, merek dagang, teknologi, basis data, proses dan model bisnis, dilindungi oleh hak cipta, merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum Republik Indonesia yang terdaftar baik atas nama kami. Kami (dan pemberi lisensi kami) memiliki seluruh hak dan kepentingan atas Situs dan Layanan, termasuk seluruh hak kekayaan intelektual terkait dengan seluruh fitur yang terdapat didalamnya dan hak kekayaan intelektual terkait.</li>
              <li>Seluruh hak kekayaan intelektual yang berupa merek dagang, logo, merek layanan dapat merupakan merek dagang terdaftar atau belum terdaftar dari kami atau TAP Insure atau pihak ketiga. Kecuali dinyatakan dalam Syarat dan Ketentuan ini, Anda tidak diperbolehkan menggunakan hak kekayaan intelektual apapun tanpa izin tertulis dari kami.</li>
              <li>Setiap penggandaan, distribusi, pembuatan karya turunan, penjualan atau penawaran untuk menjual, penampilan baik sebagian atau seluruhnya, serta penggunaan Situs dan/atau Layanan, baik secara digital atau lainnya atau pada perangkat selain telepon genggam atau tablet, termasuk namun tidak terbatas pada penggunaan aplikasi tambahan seperti aplikasi modifikasi, emulator, dan lain-lain, yang menyimpang dari Syarat dan Ketentuan ini, maupun tujuan penggunaan yang ditentukan oleh kami, merupakan pelanggaran terhadap hak kekayaan intelektual kami.</li>
            </ol>
            <h2>Tautan ke Layanan Digital Pihak Ketiga</h2>
            <ol>
              <li>Layanan kami mungkin menyediakan tautan ke situs web pihak ketiga. Tautan ini bukanlah indikasi atas persetujuan atau dukungan kami atas situs web pihak ketiga manapun. Kami</li>
              <li>tidak bertanggung jawab untuk konten apapun di situs web pihak ketiga termasuk ketepatan dan keutuhan dari materi mereka. Segala persetujuan dan transaksi antara Anda dan pihak ketiga yang tautannya muncul di Layanan kami (termasuk pengiklan) adalah antara Anda dan pihak ketiga tersebut. Kami tidak bertanggung jawab untuk kerugian atau klaim apapun yang mungkin disebabkan oleh persetujuan atau transaksi antara Anda dan pihak ketiga tersebut.</li>
              <li>Dengan menggunakan Layanan, Anda secara tegas membebaskan kami (dan pemilik, karyawan, agen, afiliasi, dan / atau pemberi lisensi kami) dari setiap dan semua kewajiban yang timbul dari penggunaan Anda atas situs web pihak ketiga, informasi, materi, produk, atau layanan. Karenanya, kami mendorong Anda untuk mengetahui kapan Anda telah meninggalkan Layanan dan membaca syarat dan ketentuan serta kebijakan privasi masing-masing situs web lain yang Anda kunjungi.</li>
            </ol>
            <h2>Tanggung Jawab Anda</h2>
            <ol>
              <li>Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau mengakses Situs dan Layanan.</li>
              <li>Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Situs dan Layanan, dengan cara yang bertentangan dengan Syarat dan Ketentuan ini, atau peraturan perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk tujuan anti pencucian uang, anti pendanaan terorisme, aktivitas kriminal, penipuan dalam bentuk apapun (termasuk namun tidak terbatas pada kegiatan phishing dan/atau social engineering), pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang merugikan publik dan/atau pihak lain manapun atau yang dapat atau dianggap dapat merusak reputasi kami.</li>
            </ol>
            <h2>Batasan Tanggung Jawab</h2>
            <ol>
              <li>Penggunaan Situs dan Layanan oleh Anda dan pembelian dan penggunaan produk asuransi atau Layanan apapun oleh Anda seluruhnya adalah risiko Anda sendiri. Layanan ini disediakan dan produk asuransi dijual dengan dasar “SEBAGAIMANA ADANYA” dan “SEBAGAIMANA TERSEDIA”. Sepanjang diperbolehkan oleh hukum, kami secara tegas menyangkal semua jaminan dan syarat-syarat, baik tersurat maupun tersirat, termasuk namun tidak terbatas pada jaminan tersirat dan syarat penjualan, kecocokan untuk tujuan khusus dan tanpa pelanggaran.</li>
              <li>Kami tidak memberikan jaminan bahwa (i) Layanan akan memenuhi seluruh persyaratan Anda; (ii) Layanan tidak akan terganggu; atau (iii) semua kesalahan (error) di dalam perangkat lunak atau Layanan akan diperbaiki. Anda bertanggung jawab untuk menerapkan prosedur dan titik pemeriksaan yang cukup untuk memenuhi persyaratan Anda terkait dengan ketepatan input dan output data dan untuk mempertahankan sistem atau metode yang terpisah (selain melalui kami) untuk merekonstruksi data manapun yang hilang.</li>
              <li>Kami tidak menerima tanggungjawab atau risiko apapun atas penggunaan Anda atas Layanan kami, dan kami tidak menerima tanggung jawab apapun untuk produk atau layanan apapun, atau tautan ke pihak ketiga, atau pelanggaran keamanan sehubungan dengan penggunaan Anda atas Layanan kami, atau pengiriman Anda akan informasi sensitif melalui Layanan kami atau tautan lainnya. Karena kami tidak menyediakan saran perpajakan, hukum dan akunting, Anda diingatkan untuk mendapatkan saran independen dalam semua hal.</li>
              <li>Segala materi yang diunduh atau hal lainnya yang diperoleh melalui penggunaan Layanan dilakukan dengan kebijakan dan risiko Anda sendiri dan Anda bertanggung jawab atas setiap kerusakan komputer Anda atau perangkat lain atau kehilangan data yang dihasilkan dari unduhan atau penggunaan materi tersebut</li>
              <li>Tidak ada saran atau informasi, baik lisan maupun tertulis, yang diperoleh oleh Anda dari kami atau melalui atau berasal dari Layanan akan menciptakan jaminan apapun yang tidak secara tegas tersurat dalam Syarat dan Ketentuan ini.</li>
              <li>Anda setuju untuk melepaskan dan membebaskan TAP Insure, afiliasi dan semua direkturnya, pejabat, personel, agen, partner, karyawan dan para penerusnya bebas dari klaim, tuntutan, biaya, kerusakan, tindakan, kerugian, kewajiban, denda, pinalti, kewajiban dan tindakan yang muncul dari, termasuk biaya pengacara yang wajar, yang timbul karena atau sehubungan dengan informasi, konten, komentar, atau pendapat apapun yang Anda ajukan, sajikan, atau kirimkan kepada kami, penggunaan Layanan, pelanggaran Anda atas Syarat dan Ketentuan ini, atau pelanggaran Anda atas hak, kepemilikan atau kepentingan dari orang atau badan lain sebagai akibat dari penggunaan dan akses Anda atas Layanan kami.</li>
              <li>Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda atas Situs. Namun, kami tetap melakukan pengawasan untuk tujuan memastikan kelancaran penggunaan Situs dan untuk memastikan kepatuhan terhadap Syarat dan Ketentuan ini, peraturan perundang-undangan yang berlaku, putusan pengadilan, dan/atau ketentuan lembaga administratif atau badan pemerintahan lainnya.</li>
            </ol>
            <h2>Pengakuan dan Pernyataan</h2>
            <ol>
              <li>Anda menyatakan dan menjamin bahwa Anda telah berusia minimal 18 (delapan belas) tahun atau sudah menikah dan tidak berada di bawah perwalian atau pengampuan dan Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Syarat dan Ketentuan ini. Jika Anda tidak memenuhi ketentuan tersebut namun tetap mengakses atau menggunakan Situs atau Layanan, Anda menyatakan dan menjamin bahwa tindakan Anda membuka, mengakses atau melakukan aktivitas lain dalam Situs telah disetujui oleh orang tua, wali atau pengampu Anda. Anda secara tegas mengesampingkan setiap hak berdasarkan hukum untuk membatalkan atau mencabut setiap dan seluruh persetujuan yang Anda berikan berdasarkan Syarat dan Ketentuan ini pada waktu Anda dianggap telah dewasa secara hukum.</li>
              <li>Anda memahami dan menyetujui bahwa seluruh resiko yang timbul dari penggunaan Situs atau Layanan sepenuhnya menjadi tanggung jawab Anda dan Anda dengan ini setuju untuk melepaskan kami dari segala tuntutan apapun sehubungan dengan kerusakan, gangguan, atau bentuk lain dari gangguan sistem yang disebabkan oleh akses tidak resmi oleh pihak lain.</li>
              <li>Anda secara tegas membebaskan kami, termasuk namun tidak terbatas pada, pejabat, direktur, komisaris, karyawan dan agen kami, dari setiap dan semua kewajiban, konsekuensi, kerugian baik materiil atau immateriil, tuntutan, biaya-biaya (termasuk biaya advokat) atau tanggung jawab hukum lainnya yang timbul atau mungkin timbul akibat pelanggaran Anda terhadap Syarat dan Ketentuan ini. Dikecualikan dari ketentuan ini, jika terdapat kerugian langsung akibat pelanggaran kami terhadap Syarat dan Ketentuan ini, maka Anda setuju dan secara tegas membatasi jumlah tuntutan Anda sebesar jumlah keseluruhan yang senyatanya ditimbulkan atau telah dibayarkan sehubungan dengan peristiwa tersebut.</li>
            </ol>
            <h2>Lain-Lain</h2>
            <ol>
              <li>Versi asli berbahasa Indonesia dari Syarat dan Ketentuan ini dimungkinkan untuk diterjemahkan ke bahasa-bahasa lain. Versi terjemahan dibuat hanya untuk memberi kemudahan saja, dan tidak bisa dianggap sebagai terjemahan resmi. Jika ditemukan adanya perbedaan antara versi bahasa Indonesia dan versi bahasa lainnya dari syarat dan ketentuan ini, maka yang berlaku dan mengikat adalah versi bahasa Indonesia.</li>
              <li>Apabila Anda mengalami gangguan sistem, atau apabila perangkat telepon genggam atau tablet pribadi Anda hilang, dicuri, diretas atau terkena virus, segera laporkan kepada kami sehingga kami dapat segera mengambil tindakan yang diperlukan untuk menghindari penggunaan, penyalahgunaan, atau kerugian yang timbul atau mungkin timbul lebih lanjut.</li>
              <li>Situs dapat diinterupsi oleh kejadian di luar kewenangan atau kontrol kami (“Keadaan Kahar”), termasuk namun tidak terbatas pada bencana alam, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah, dan lain-lain. Anda setuju untuk membebaskan kami dari setiap tuntutan dan tanggung jawab, jika kami tidak dapat memfasilitasi Layanan, termasuk memenuhi instruksi yang Anda berikan melalui Situs, baik sebagian maupun seluruhnya, karena suatu Keadaan Kahar.</li>
              <li>Anda mengerti dan setuju bahwa Syarat dan Ketentuan ini merupakan perjanjian dalam bentuk elektronik dan tindakan Anda mengakses dan menggunakan Situs atau Layanan merupakan persetujuan aktif dan eksplisit Anda untuk mengikatkan diri dalam perjanjian dengan kami sehingga keberlakuan Syarat dan Ketentuan ini adalah sah dan mengikat secara hukum dan terus berlaku sepanjang penggunaan Situs dan Layanan oleh Anda.</li>
              <li>Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Syarat dan Ketentuan yang dibuat dalam bentuk elektronik.</li>
              <li>Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara, pembekuan permanen, penghapusan Situs atau setelah berakhirnya perjanjian ini antara Anda dan kami.</li>
              <li>Jika salah satu dari ketentuan dalam Syarat dan Ketentuan ini tidak dapat diberlakukan, hal tersebut tidak akan memengaruhi ketentuan lainnya.</li>
              <li>Situs ini dibuat dan dikontrol oleh TAP Insure di Indonesia dan oleh dengan demikian tunduk dan diatur atas dasar hukum Indonesia. Sehubungan dengan Syarat dan Ketentuan ini dan segala konsekuensinya, Anda dan Kami memilih domisili hukum yang umum dan tetap di Pengadilan Negeri Jakarta Selatan.</li>
            </ol>
            <h2>Cara Menghubungi dan Mengajukan Pertanyaan Kepada Kami</h2>
            <p>Jika Anda memiliki pertanyaan sekitar Syarat dan Ketentuan ini, Anda dapat menghubungi kami melalui:</p>
            <ol>
              <li>Surel (email) kepada Layanan Pelanggan kami di <a href='mailto:cs@tap-insure.com'>cs@tap-insure.com</a></li>
              <li>Menghubungi Layanan Pelanggan kami di nomor Whatsapp 0818-889-019</li>
            </ol>
          </div>
        </div>
        <Footer />
   </TermsConditionContainer>
   </>
  )
}

export default TermsCondition
