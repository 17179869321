import styled from "styled-components";
import LargeButton from "../../components/LargeButton";

export const PackageDetailsWrapper = styled.div`
    position: relative;
    background: url('https://storage.googleapis.com/pp_img/tap-travel-website/common/Bottom-Vector.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #f5f5f5;
    background-position: bottom;
`

export const UserDetailsctn = styled.div`
    width: 100%;
    background: #ffffff;
    padding: 10px 0px 0px 0px;
`
export const UserDetailsWrapper = styled.div`
    width: 80%;
    margin: auto;
    background: #ffffff;

    @media screen and (max-width: 1024px) {
        padding: 0px 0px 24px 0px;
        width: 90%;
    }
`
export const UserDetailCtn = styled.div`
    display: grid;
    grid-template-columns: 91% 8%;
    gap: 1%;
    align-items: center;
    margin-top: 10px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 100%;
        margin-top: 0px;
    }
`
export const DetailsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 24%);
    gap: 1%;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 48%);
    }
`
export const DetailsCtn = styled.div`
    display: grid;
    grid-template-columns: 10% 88%;
    gap: 8%;
    align-items: center;
    margin-bottom: 16px;

    @media screen and (max-width: 1024px) {
        display: flex;
        gap: 16px;
        margin-bottom: 8px;
    }
`
export const DetailsDescription = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 78%;
`
export const Description = styled.div`
    font-family: Mulish;
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #333333;
    word-wrap: break-word;
`
export const EditBtn = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 12px;
    border: 2px solid #B43CFA;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        display: none;
    }
`
export const EditBtnMobile = styled.div`
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
    }

`
export const PackageDetailsCTN = styled.div`
    width: 100%;
    margin: auto;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 3%;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        display: flex;
        margin-top: 16px;
        gap: 8px;
        
        ::-webkit-scrollbar {
            display: none;
        }
        margin-bottom: 32px;
    }
`
export const PkgDeatilCardCtn = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 450px;
    min-width: 300px;

    @media screen and (max-width: 1024px) {
        max-width: 95%;
        min-width: 95%;
    }
`
export const PkgCardctn = styled.div`
    border-radius: 32px;
    width: 100%;
`
export const PkgCardTop = styled.div`
    width: 100%;
    padding: 16px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: ${props => props.bgColor ? props.bgColor : '#fce4cc'};
    border-top-right-radius: 32px; 
    border-top-left-radius: 32px; 
`
export const CardText = styled.div`
    font-family: Mulish;
    font-size: ${props => props.cardStyle.fontSize ? props.cardStyle.fontSize : '18px'};
    font-weight:  ${props => props.cardStyle.fontWgt ? props.cardStyle.fontWgt : '600'};
    line-height: ${props => props.cardStyle.fonthgt ? props.cardStyle.fonthgt : '22px'};
    text-align: center;
    color: ${props => props.cardStyle.color ? props.cardStyle.color : '#333333'};
    margin-bottom: 5px;
    max-width: 85%;

    @media screen and (max-width: 1024px) {
        font-size: ${props => props.cardStyle.fontSizeMob ? props.cardStyle.fontSizeMob : '18px'};
        font-weight:  ${props => props.cardStyle.fontWgtMob ? props.cardStyle.fontWgtMob : '600'};
        line-height: ${props => props.cardStyle.fonthgtMob ? props.cardStyle.fonthgtMob : '22px'};
    }
`
export const CardImageCtn = styled.div`
    margin: 10px 0px;
    @media screen and (max-width: 1024px) {
        margin: 0px;
    }
`
export const CardDetailsCtn = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 30px 0px;
    background: #ffffff;
    width: 100%;

    @media screen and (max-width: 1024px) {
        padding: 16px 0px;
        gap: 8px 0px;
    }
`
export const CardDescriptionCtn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 0px;
    }
`
export const CardBtnCtn = styled.div`
    background: #ffffff;
    width: 100%;
    border-bottom-right-radius: 32px; 
    border-bottom-left-radius: 32px;
    padding: 0px 0px 40px 0px;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        padding: 0px 0px 16px 0px;
    }
`
export const CardSelectBtn = styled.div`
    width: 70%;
    padding: 14px 16px 14px 16px;
    border-radius: 12px;
    border: 3px solid ${props => props.color ? props.color : '#F07800'};
    font-family: Mulish;
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    text-align: center;
    color: ${props => props.color ? props.color : '#F07800'};
    background: ${props => props.bgColor ? props.bgColor : '#FFFFFF'};
    margin: auto;

    @media screen and (max-width: 1024px) {
        padding: 8px 16px 8px 16px;
        font-size: 12px;
        font-weight: 800;
        line-height: 16px;
        display: flex;
        justify-content: center;
    }
`
export const DocumentBtn = styled.div`
    width: 14%;
    min-width: 250px;
    padding: 14px 16px 14px 16px;
    border-radius: 12px;
    border: 3px solid #B43CFA;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #B43CFA;
    margin: 40px auto;
    background: #ffffff;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        padding: 8px 16px 8px 16px;
        border: 2.5px solid #B43CFA;
        width: 25%;
        font-size: 12px;
        font-weight: 800;
        line-height: 16px;
        min-width: 300px;
        margin: 16px auto;
    }
`
export const IconCtn = styled.div`
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 12px;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;

    @media screen and (max-width: 1024px) {
        margin-bottom: 4px;
        padding: 0px;
    }
`
export const GreyText = styled.div`
    font-family: Mulish;
    font-size: ${props => props.textSize ? props.textSize : '11px'};
    font-weight: 600;
    line-height: 10px;
    text-align: left;
    color: ${props => props.color ? props.color : '#999999'};
`
export const HeaderText = styled.p`
  font-family: Mulish;
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  margin-top: 32px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
    padding: 0px 40px;
    margin-top: 16px;
  }
`;
export const SecondHeaderText = styled.p`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    line-height: 16px;
    padding: 0px 40px;
  }
`;
export const EditPackageDetailsCtn = styled.div`
    padding: 20px 20px 10px 20px;

    @media screen and (max-width: 1024px) {
        padding: 10px 8px 0px 8px;
    }
`
export const PackageDetailBox = styled.div`
    width: 80%;
    justify-content: center;
    align-items: center;
    min-height: 55vh;
    margin: auto;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`
export const BounceLoaderCtn = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SubmitBtn = styled(LargeButton)`
    width: 100%;
    margin-top: 15px;
`
export const ScrollContainer = styled.div`
  display: flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  flex-direction: row;

  @media screen and (max-width: 1024px) {
        width: auto;
        justify-content: ${props => props.length <= 1 && 'center'};
    }
`;
export const ScrollItem = styled.div`
  flex-shrink: 0;
  width: 300px; 
  margin: 0px 0px 0px 16px;
  scroll-snap-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  border-radius: 10px;
`;
export const ScrollBar = styled.div`
  position: relative;
  height: 4px;
  background: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 25%;
  margin: auto;
`;
export const ScrollThumb = styled.div`
  position: absolute;
  height: 100%;
  background-color: #B43CFA;
  border-radius: 5px;
  cursor: pointer;
  transition: left 0.1s ease, width 0.1s ease;
`;
export const CardImage = styled.img`
    width: 56px;
    height: 80px;

    @media screen and (max-width: 1024px) {
        height: 48px;
        width: 48px;
    }
`
export const UserDetailsIcons = styled.img`
    width: 40px;
    height: 40px;

    @media screen and (max-width: 1024px) {
        width: 36px;
        height: 36px;
    }
`
export const DaftarWrapper = styled.div`
    padding: 32px;

    @media screen and (max-width: 1024px) {
        padding: 16px;
    }
`