import styled from 'styled-components'

export const OTPInputContainer = styled.div`
  display: flex;
  max-width: 360px;
  justify-content: space-between;
  margin: auto;
`

export const OTPInputItem = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 1px solid black;
  text-align: center;
  border-radius: 8px;
  border: solid 1px #eeeeee;
  width: 45px;
  height: 48px;
  transition: all 0.2s linear;

  font-size: 1.6rem;
  font-family: Mulish;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;
  color: #333333;

  ${(props) =>
    props.isError &&
    `
    border-color: #DC0000;
    border-width: 2px;
  `}

   @media screen and (max-width: 1024px) {
    width: 37px;
    height: 42px;
    font-size: 1.2rem;
    line-height: 2.0rem;
  }
`
