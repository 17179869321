import styled from 'styled-components'

export const HeaderContainer = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;
    padding: 10px 0;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1000;

    .header-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
    }

    .logo {
        cursor: pointer;
        width: 170px;
    }

    .header-item {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
        right: 50px;
    }

    .header-item li {
        position: relative;
        text-decoration: none;
        list-style: none;
        cursor: pointer;
        color: #333;
        padding: 16px;
        transition: color 0.3s ease;
        font-size: 14px;
        white-space: normal; 
        word-wrap: break-word; 
        overflow-wrap: break-word; 
        font-weight: 700;
        text-align: left;
    }

    .header-dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        img {
            margin-left: 5px;
            width: 12px;
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 40px;
        left: 0;
        background-color: white;
        list-style: none;
        padding: 10px 0;
        margin: 0;
        z-index: 1000;
        border-radius: 16px;
        cursor: pointer;
    }

    .header-width {
        width: 250px;
    }

    .publication-head {
        width: 170px;
    }

    .dropdown-menu li {
        padding: 10px 20px;
        color: #333;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
        transition: background-color 0.5s ease;
        font-weight: 700;
        cursor: pointer;

        &:hover {
            background-color: #FAF000;
        }
    }

    .header-dropdown:hover .dropdown-menu {
        display: block;
        transition: display 0.5s ease;
    }

    .dropdown-menu {
        display: none;
    }

    .mobile-menu-button {
        visibility: hidden;
        background: none;
        border: none;
    }

    .close-button {
        background: none;
        border: none;
        display: none;
        background: none;
        border: none;
        cursor: pointer;

        img {
        width: 20px;
        height: 20px;
        }
    }

    .mobile-menu {
        position: fixed;
        top: 0;
        right: -120%;
        height: 100%;
        width: 100%;
        background-color: white;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
        transition: right 0.3s ease;
        z-index: 1001;
        display: flex;
        flex-direction: column;
    }

    .mobile-menu-header {
        padding: 20px 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #F5F5F5;
    }

    .mobile-menu.open {
        right: 0;
    }

    .mobile-menu-items {
        list-style: none;
        padding: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .mobile-menu-items li {
        padding: 15px;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: #f0f0f0;
        }
    }

    @media only screen and (max-width: 480px) {
        .header-item {
            visibility: hidden;
        }

        .mobile-menu-button {
            visibility: visible;
            cursor: pointer;
        }

        .close-button {
            display: block;
        }

        .header-dropdown {
            display: block;
            width: 100%;
        }

        .mobile-menu-items li {
            padding: 15px 0;
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            cursor: pointer;
            text-align: left;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: #FFF;
            }

        }

        .mobile-menu-items {
            padding: 0 40px 0 20px;
            .dropdown-menu li{
                font-weight: 500;
                padding-left: 15%;
            }
        }

        .header-dropdown-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                width: 24px;
            }
        }

        .dropdown-menu {
            position: relative;
            display: block;
            width: 100%;
            background: none;
            top: 0;
        }

        .img-active {
            transform: rotate(180deg);
        }

        .header-dropdown-title-text {
            display: flex;
            align-items: center;
            gap: 16px;

            img {
                width: 40px;
            }
        }
    }
`;

