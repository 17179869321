import styled from 'styled-components'

export const PrivacyPolicyContainer = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;  
    }

    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
    font-family: "Mulish", sans-serif;

    .hero-section {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 32px 32px 0px 32px;
        width: 100%;               
    }

    .image-ctn{
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin-top: 40px;
        justify-content: center;
    }

    .hero-image {
        width: 50%;
        height: 550px;
        transform: scale(1.3);
    }
    .hero-title {
        text-align: center;
        font-size: 56px;
        max-width: 858px;
        word-break: break-word;
    }

    .content {
        width: 100%;  
        background: rgb(217,217,217);
        background: -moz-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        background: -webkit-linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        background: linear-gradient(180deg, rgba(217,217,217,1) 0%, rgba(255,255,255,1) 80%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d9d9d9",endColorstr="#ffffff",GradientType=1);
    }
    
    .content-inner {
        width: 100%;
        max-width: 1000px;         
        margin: 0 auto;            
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 72px 32px 32px 32px;
        line-height: 32px;
        gap: 40px;        
    }

    .content-inner h1{
        font-size: 40px;
        line-height: 48px;
        font-weight: 800;
    }
    .content-inner h2{
        font-size: 24px;
        line-height: 32px;
        font-weight: 800;
    }

    .content-inner h1, .content-inner h2, .content-inner ol {
        width: 100%;
        text-align: left;
    }

    
    .default-ol {
        counter-reset: item;
        padding-left: 0;          
        list-style: none;         
    }

    .default-ol > li {
        counter-increment: item;
        position: relative;       
        font-size: 1em;          
        margin-bottom: 16px;
        padding-left: 30px;
    }

    .default-ol > li::before {
        content: counter(item) ". ";
        position: absolute;         
        left: 0;                    
        font-size: 1.5em;             
        font-weight: bold;           
    }

   
    .alpha-list {
        list-style-type: lower-alpha;
        padding-left: 40px; 
        margin-top: 10px;
    }

    
    .disc-list {
        list-style-type: disc;     
        padding-left: 30px;
        margin-bottom: 24px;
    }

    ol p {
        margin-bottom: 24px;
    }

    @media (max-width: 480px) {
        margin-top: 40px;
        margin-bottom: 16px;

        .hero-title {
            font-size: 16px;
            font-weight: 800;
            line-height: 24px;
            text-align: center;
        }

        .content-inner {
            width: 90%;            
            max-width: 100%;        
            margin: 0 auto;         
            padding: 12px;          
            line-height: 26px;
            text-align: justify;
            gap: 24px;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
        }

        .content-inner h1 {
            font-size: 16px;
            font-weight: 800;
            line-height: 24px;
            text-align: center;
        }

        .content-inner h2 {
            font-size: 16px; 
            line-height: 24px;
        }

        .default-ol > li {
            font-size: 0.85rem;
            padding-left: 15px;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;  
        }

        .default-ol > li::before {
            font-size: 1.2em;
        }

        .alpha-list {
            padding-left: 25px;
        }

        .disc-list {
            padding-left: 18px;
        }
        .hero-image {
            width: 100%;
            height: 340px;
            transform: scale(1.3);
            margin-top: 32px;
        }
        .hero-section {
            padding: 0px 0px 0px 0px;
        }
    }
`;
