import React, { useEffect, useState } from 'react'
import { VisiMisiContainer } from './VisiMisi.style'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { imagesLink } from './constant'
import ScrollToTop from '../../components/ScrollToTop'

const VisiMisi = () => {
  const [isMobile, setIsMobileView] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobileView(window.innerWidth <= 1024);
    });
  },[isMobile])

  return (
    <>
      <Header />
      <VisiMisiContainer>
          <div className='hero-section'>
              <h1 className='hero-title'>Menghubungkan melalui Visi, Meraih dengan Misi, Dipandu oleh Nilai-Nilai</h1>
              <img src={imagesLink.heroImage} className='hero-image'/>
          </div>
          <div className='content'>
              {!isMobile && <div className='visi-misi-section-container'>
                <div className='visi'>
                  <div className='visi-inner'>
                    <div className='visi-misi-section-title'>
                      V I S I
                    </div>
                    <p className='visi-misi-section-para'>Menjadi salah satu perusahaan asuransi untuk semua lapisan<br/> masyarakat yang bertumpu pada inovasi teknologi, inovasi<br/> produk yang berfokus pada hal sederhana dan mudah dan<br/> transparan serta dapat bersaing dengan perusahaan<br/> asuransi lainnya dan menjadi yang terbaik di Indonesia</p>
                  </div>
                  </div>
                <div className='misi'>
                  <div className='visi-misi-section-title'>
                    M I S I
                  </div>
                  <p className='visi-misi-section-para'>Mendemokratisasi <br/>asuransi yang simpel,<br/> terjangkau, dan<br/> menyenangkan melalui<br/> inovasi dan teknologi</p>
                </div>
              </div>}

            {isMobile && <div className='visi-misi-section-container-mobile'>
                  <div className='visi-mobile'>
                      <div className='visi-misi-section-title-mobile'>
                        V I S I
                      </div>
                      <p className='visi-misi-section-para-mobile'>Menjadi salah satu perusahaan asuransi untuk semua lapisan masyarakat yang bertumpu pada inovasi teknologi, inovasi produk yang berfokus pada hal sederhana dan mudah dan transparan serta dapat bersaing dengan perusahaan asuransi lainnya dan menjadi yang terbaik di Indonesia</p>
                  </div>
                  <div className='visi-mobile'>
                      <div className='visi-misi-section-title-mobile'>
                        M I S I
                      </div>
                      <p className='visi-misi-section-para-mobile'>Mendemokratisasi asuransi yang simpel, terjangkau, dan menyenangkan melalui inovasi dan teknologi</p>
                  </div>
              </div>}
            <div className='content-inner'>
              <div className='visi-misi'>
                <div className='visi-misi-title'>
                  {!isMobile && <><h1>Nilai Inti</h1>
                  <h1>Tap Insure</h1></>}
                  {
                    isMobile && <h1>Nilai Inti Tap Insure</h1>
                  }
                </div>
                <div className='visi-misi-container'>
                  <div className='visi-misi-items'>
                      <h6>01</h6>
                      <div className='visi-misi-items-inner'>
                        <h3>
                          Jangan Pernah Menyerah
                        </h3>
                        <p>Musuh terbesar kita bukanlah kegagalan. Ini adalah godaan untuk menjadi biasa.</p>
                      </div>
                  </div>
                  <div className='visi-misi-items'>
                      <h6>02</h6>
                      <div className='visi-misi-items-inner'>
                        <h3>
                          Selalu Hari Pertama
                        </h3>
                        <p>Setiap hari adalah hari pertama. Bersikaplah rendah hati. Selalu haus akan ilmu.</p>
                      </div>
                  </div>
                  <div className='visi-misi-items'>
                      <h6>03</h6>
                      <div className='visi-misi-items-inner'>
                        <h3>
                          Berjiwa Wirausaha
                        </h3>
                        <p>Kami mendorong insan TAP Insure untuk bertindak seperti pemilik usaha: memotivasi diri sendiri, berkesadaran tinggi, dan tidak menunggu untuk diberitahu apa yang harus dilakukan.</p>
                      </div>
                  </div>
                  <div className='visi-misi-items'>
                      <h6>04</h6>
                      <div className='visi-misi-items-inner'>
                        <h3>
                          Kita adalah Satu Tim
                        </h3>
                        <p>Tidak ada seorang pun yang bisa melakukan sesuatu sendirian. Ketika kita menunjukkan kinerja kuat secara konsisten, maka orang lain dapat mengandalkan kita. Kita saling bantu untuk menjadi yang terbaik.</p>
                      </div>
                  </div>
                  <div className='visi-misi-items'>
                      <h6>05</h6>
                      <div className='visi-misi-items-inner'>
                        <h3>
                          Merangkul Perubahan
                        </h3>
                        <p>Perubahan tidak bisa dihindari. Beradaptasi, berevolusi, dan tetap berpikiran terbuka.</p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ScrollToTop />
          < Footer />
      </VisiMisiContainer>
    </>
  )
}

export default VisiMisi
