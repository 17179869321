import React, { useCallback, useEffect, useMemo } from "react";
import TravelHeader from "../../components/TravelHeader";
import TravelFooter from "../../components/TravelFooter";
import PaymentSucessFull from "./components/paymentSucessfull";
import PaymentPending from "./components/paymentPending";
import PaymentExpired from "./components/paymentExpired";
import { BounceLoaderCtn, FooterTextCtn, FooterTextWrap, InfoIcon, PaymentBoldText, TermsA } from "./elements";
import { ImageUrl } from "../../Images";
import { FormattedMessage } from "react-intl";
import { usePaymentStatus } from "../../lib/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import PaymentUnpaid from "./components/paymentUnpaid";

const mailTo = (email) => {
  window.open(`mailto:${email}`)
}

const waTo = (phoneNo) => {
  window.open(`https://wa.me/${phoneNo}`)
}


const PaymentStatus = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: paymentStatusData,
    dataLoading,
    getPaymentStatus,
  } = usePaymentStatus();

  useEffect(() => {
    searchParams.get("draft_id") && getPaymentStatus(searchParams.get("draft_id"));
  }, []);

  const _getFooter = () => {
    return (
      <FooterTextCtn>
        <InfoIcon src={ImageUrl.BlueInfo} alt="info" />
        <FooterTextWrap>
          <FormattedMessage
            id="Butuh bantuan? Silakan menghubungi WhatsApp 0818-889-019 atau Email cs@tap-insure.com"
            defaultMessage="Butuh bantuan? Silakan menghubungi <ppcsphone>WhatsApp 0818-889-019</ppcsphone> atau <ppcsmail>Email cs@tap-insure.com</ppcsmail>"
            values={{
              b: (chunks) => <PaymentBoldText>{chunks}</PaymentBoldText>,
              ppcsmail: chunks => <TermsA id='pp-cs-mail' onClick={() => mailTo('cs@tap-insure.com')}>{chunks}</TermsA>,
              ppcsphone: chunks => <TermsA id='pp-cs-phone' onClick={() => waTo('62818889019')}>{chunks}</TermsA>,
            }}
          />
        </FooterTextWrap>
      </FooterTextCtn>
    );
  };

  const paymentPage = useCallback(() => {
    if (paymentStatusData?.data?.status === "completed") {
      if (paymentStatusData?.data?.app_number) {
        return <PaymentSucessFull data={paymentStatusData?.data} />;
      } else {
        return <PaymentPending data={paymentStatusData?.data} />
      }
    } else if (paymentStatusData?.data?.status === "rejected") {
      return  <PaymentExpired data={paymentStatusData?.data} />;
    } else {
      return <PaymentUnpaid data={paymentStatusData?.data} />;
    }
  }, [paymentStatusData]);

  const goHome = () => {
    localStorage.setItem('prevUrl', 'payment-page');
    window.history.replaceState({ page: 'home' }, '', '/');
    navigate('/');
  };

  return !dataLoading && paymentStatusData && paymentStatusData?.data ? (
    <div>
      <TravelHeader />
      {paymentPage()}
      <TravelFooter
        leftContent={_getFooter}
        showPremium={false}
        btnText="Ke Beranda"
        onSubmit={goHome}
      />
    </div>
  ) : (
    <BounceLoaderCtn>
      <BounceLoader color="#b33cfa" size={90} />
    </BounceLoaderCtn>
  );
};

export default PaymentStatus;
