import React from 'react'
import Header from '../../components/Header'
import { PrivacyPolicyContainer } from './PrivacyPolicy.style'
import { imagesLink } from './constant'
import Footer from '../../components/Footer'

const PrivacyPolicy = () => {
  return (
      <><Header />
    <PrivacyPolicyContainer>
      <div className='hero-section'>
        <h1 className='hero-title'>TAP Insure Peduli Privasi Kamu</h1>
        <div className='image-ctn'><img src={imagesLink.heroImage} className='hero-image'/></div>
      </div>
      <div className='content'>
        <div className='content-inner'>
            <p>Kami PT Asuransi Untuk Semua (“TapInsure”) memastikan bahwa Data Pribadi atau informasi yang Kami kumpulkan dari Anda ketika Anda menggunakan atau mengakses situs web, aplikasi dan produk/layanan Kami akan diproses sesuai dengan peraturan yang berlaku, yaitu meliputi pemerolehan dan pengumpulan, pengolahan dan penganalisisan, penyimpanan, perbaikan dan pembaruan, penampilan, pengumuman, transfer, penyebarluasan, atau pengungkapan dan/atau penghapusan atau pemusnahan.</p>
            <p>Kebijakan Pelindungan Data Pribadi ini (“Kebijakan”) dibuat sesuai dengan hukum Republik Indonesia. Dengan mengakses atau menggunakan website, aplikasi dan produk/layanan Kami, Anda telah menyetujui secara sah dan eksplisit Kebijakan Pelindungan Data Pribadi ini. Kami memiliki hak penuh untuk mengubah, memodifikasi, dan menambahkan atau menghapus ketentuan-ketentuan pada Kebijakan ini. Kami menyarankan Anda untuk selalu membaca kembali dan meninjau Kebijakan ini dari waktu ke waktu selama Anda menggunakan website, aplikasi dan produk/layanan Kami. Selama Anda masih melanjutkan untuk mengakses atau menggunakan website, aplikasi dan produk/layanan Kami maka Anda akan dianggap telah menyetujui dan menerima seluruh perubahan pada Kebijakan ini.</p>
            <h1>Pemrosesan Data Pribadi</h1>
            <ol className='default-ol'>
                <li><h2>Pengumpulan Data Pribadi</h2></li>
                <p>Kami akan melakukan pengumpulan Data Pribadi secara terbatas dan spesifik, sah secara hukum dan transparan. Kami dapat mengumpulkan Data Pribadi Anda, baik yang Anda berikan secara langsung (sebagai contoh, saat Anda mengunjungi halaman “Produk” di situs web Kami), maupun yang terkumpul secara otomatis ketika Anda menggunakan situs web atau aplikasi Kami.</p>
                <p>Selain itu, Kami dapat menggunakan informasi terkait lokasi yang kami dapatkan dari perangkat Anda untuk meningkatkan kenyamanan penggunaan Anda atas mengakses situs web, aplikasi dan produk/layanan Kami, yaitu dengan memberikan konten yang relevan dan iklan yang sesuai konteks.</p>
                <p>Anda dapat mengubah pengaturan privasi pada perangkat Anda sewaktu-waktu. Mohon diperhatikan bahwa menonaktifkan berbagi lokasi mungkin (i) memberikan dampak pada fitur tertentu dari situs web, aplikasi dan produk/layanan Kami dan (ii) walaupun kami dapat mengidentifikasi lokasi geografis Anda, namun kami tidak mendapatkan informasi Data Pribadi Anda. Kami mengidentifikasi lokasi Anda hanya untuk memberikan pengalaman yang lebih sesuai bagi Anda.</p>
                <li><h2>Pemrosesan Data Pribadi sesuai Tujuan</h2></li>
                <p>Data Pribadi Anda yang terkumpul akan Kami proses sesuai dengan tujuannya, yaitu</p>
                <ol className='alpha-list'>
                    <li>Nama, alamat, tanggal lahir, pekerjaan, jenis kelamin, nomor telepon/ponsel, alamat email, dan informasi lain sehubungan dengan produk asuransi yang Anda beli.</li>
                    <p>Informasi ini diperlukan ketika Anda akan melakukan pembelian Polis Asuransi pada situs web dan/atau aplikasi TapInsure. Selain itu, informasi ini juga akan Kami gunakan untuk menghubungi Anda terkait dengan proses penutupan Polis Asuransi, layanan klaim, korespondensi nasabah maupun calon nasabah dan kebijakan-kebijakan yang berlaku.</p>
                    <li>Bank dan Nomor Rekening</li>
                    <p>Kami mengumpulkan informasi mengenai nama bank dan nomor rekening Anda untuk melakukan pembayaran klaim Anda dan proses pembayaran lainnya.</p>
                    <li>Aplikasi dan statistik penggunaan perangkat Anda</li>
                    <p>Kami dapat mengoleksi data dari ponsel Anda. Kami akan mengumpulkan dan membuatnya menjadi pola statistik yang anonim untuk membantu Kami dalam memberikan tarif dan cakupan yang lebih akurat, serta meningkatkan performa dan pengalaman secara keseluruhan bagi Anda dan seluruh pengguna Kami.</p>
                    <li>Data Informasi Kesehatan</li>
                    <p>Kami akan menyimpan data-data Anda seperti aktivitas/rutinitas dan data kesehatan seperti diagnosa, resep dokter, hasil laboratorium, tagihan, dan hal lainnya untuk menyediakan berbagai layanan atas aktivitas-aktivitas Anda yang tersimpan di sistem kami tersebut, antara lain terkait pengajuan klaim Anda.</p>
                </ol>
                <p>Anda dapat menarik kembali persetujuan pemrosesan Data Pribadi dengan menyampaikan permintaan kepada kami pada email atau nomor WhatsApp yang dituliskan pada bagian akhir Kebijakan ini. Kami akan menghentikan pemrosesan Data Pribadi Anda paling lambat 3 x 24 (tiga kali dua puluh empat) jam terhitung sejak Kami menerima permintaan tersebut dari Anda.</p>
                <li><h2>Hak Subjek Data Pribadi</h2></li>
                <p>Anda selaku Subjek Data Pribadi memiliki hak yaitu melengkapi, memperbarui, dan/atau memperbaiki kesalahan dan/atau ketidakakuratan Data Pribadi, mendapatkan akses dan memperoleh salinan Data Pribadi tentang dirinya, mengakhiri pemrosesan, menghapus, dan/ atau memusnahkan Data Pribadi, menarik kembali persetujuan pemrosesan Data Pribadi, serta hak-hak lainnya yang diatur dalam Undang-Undang Pelindungan Data Pribadi.</p>
                <p>Kami akan memperbarui dan/ atau memperbaiki kesalahan dan/atau ketidakakuratan Data Pribadi paling lambat 3 x 24 (tiga kali dua puluh empat) jam terhitung sejak Kami menerima permintaan pembaruan dan/atau perbaikan Data Pribadi dari Anda.</p>
                <li><h2>Keamanan Data Pribadi</h2></li>
                <p>Kami akan melindungi dan memastikan keamanan Data Pribadi yang Kami proses. Data Pribadi Anda akan terus disimpan, diolah, dan digunakan selama Anda masih menggunakan produk/layanan Kami dan sampai dengan 5 (lima) tahun setelah Anda tidak lagi menggunakan produk/layanan Kami (“Masa Retensi”).</p>
                <p>Apabila Anda ingin Informasi Pribadi Anda segera dihapus, Anda dapat mengirimkan permohonan kepada kami melalui email atau nomor WhatsApp yang akan kami sebutkan pada bagian akhir Kebijakan ini.</p>
                <p>Kami dapat membagi Data Pribadi dan informasi Anda kepada pihak ketiga lainnya yang bekerjasama dengan Kami untuk dapat memberikan layanan-layanan kepada Anda, misalnya kepada pihak ketiga yang memfasilitasi atau memberikan bantuan terkait pengembangan situs web, aplikasi dan layanan terkait produk asuransi, untuk dan/atau atas nama Kami, di antaranya untuk:</p>
                <ul className='disc-list'>
                    <li>Melaksanakan layanan-layanan yang terkait dengan situs web (termasuk namun tidak terbatas pada pemeliharaan, pengelolaan database, analisis situs web dan menyempurnakan fitur layanan)</li>
                    <li>Membantu penasihat profesional, auditor eksternal dan pihak ketiga yang bekerjasama dengan Kami untuk memberikan layanan terbaik kepada Anda, termasuk namun tidak terbatas pada penasihat hukum, penasihat keuangan, surveyor/investigator dan konsultan-konsultan. Para pihak ketiga ini hanya memiliki akses atas Data Pribadi Anda untuk melakukan tugas-tugas tersebut untuk dan/atau atas nama Kami, dan secara kontraktual terikat untuk tidak mengungkapkan atau menggunakan Data Pribadi tersebut untuk tujuan lain apa pun.</li>
                    <li>Menyediakan manfaat lainnya yang dianggap cocok, berdasarkan Data Pribadi Anda yang disimpan oleh Kami.</li>
                </ul>
                <p>Kami dapat membuka atau mengungkapkan Data Pribadi Anda sepanjang diwajibkan secara hukum, atau diperlukan untuk tunduk pada ketentuan peraturan perundang-undangan, instansi pemerintah, atau dalam hal terjadi sengketa, atau segala bentuk proses hukum antara Anda dan Kami, atau terkait penggunaan dan akses situs web dan aplikasi serta dalam rangka pemberian layanan terkait asuransi, atau dalam keadaan darurat.</p>
                <p>Dalam hal terjadi kegagalan pelindungan Data Pribadi, Kami akan menyampaikan pemberitahuan tertulis paling lambat 3 x 24 jam kepada Anda dan lembaga yang berwenang sesuai Undang Undang Pelindungan Data Pribadi.</p>
                <li><h2>Pemusnahan dan Penghapusan</h2></li>
                <p>Data Pribadi dimusnahkan dan/atau dihapus setelah Masa Retensi berakhir atau berdasarkan permintaan Anda, kecuali ditentukan lain oleh peraturan perundangundangan.</p>
                <p>Apabila ada pertanyaan, permintaan perubahan Data Pribadi atau Anda memerlukan informasi lebih lanjut perihal Kebijakan ini, silakan menghubungi kami melalui nomor <strong>Whatsapp 0818889019 atau email di cs@tap-insure.com.</strong></p>
            </ol>
        </div>
      </div>
      <Footer />
    </PrivacyPolicyContainer>
    </>
  )
}

export default PrivacyPolicy
