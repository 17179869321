import styled from 'styled-components';

export const ModalWrapper = styled.div`
  h5 {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
  .modal {
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
    }

    &__main {
      background: white;
      font-family: Mulish;
      border-radius: 24px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      position: relative;
      max-width: ${props => props.maxWidth ? props.maxWidth : '1024px'};
      width: 100%;
      max-height: 80vh;
      overflow: auto;
    }
    &__header {
      display: flex;
      font-family: Mulish;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: #F5F5F5;
      border-radius: 24px 24px 0px 0px;
      padding: 24px;
      font-size: 16px;
      font-weight: 800;
      line-height: 20px;
      text-align: center;
    }

    &__footer {
      position: sticky;
      bottom: 0px;
    }

    &__close {
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
    }

    &__content {
      padding: 0px;
    }

    @media screen and (max-width: 1024px) {
      &__overlay {
        align-items: ${props => props.showinMiddle ? 'center' : 'end'};
      }
      &__main {
        border-bottom-left-radius: ${props => !props.showinMiddle && '0px'};
        border-bottom-right-radius: ${props => !props.showinMiddle && '0px'};
        max-height: 90vh;
        max-width: ${props => props.showinMiddle ? props.maxWidth ? props.maxWidth : '100%' : '100%'};
      }
      &__header {
        padding: 16px;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
      }
      &__title{
        width: 100%;
        text-align: center;
      }
    }
  }
`;
